
import 'instantsearch.css/themes/satellite.css';
import './App.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';

import classes from './customAppStyle.module.css';
import { useEffect, useState } from 'react';
import Topbar from "./components/topbar";
import {
  Routes,
  Route,
} from "react-router-dom";
import Customers from "./routes/customers/customers";
import Home from "./routes/home";
import Order from "./routes/orders/order";

import Robo from "./routes/robo/robo";


import { collection, onSnapshot } from "firebase/firestore";


import { AppConfigContext, ConfigContext, MyUserDataContext, GlobalDataContext } from "./contexts";

import {
  getAuth,
  OAuthProvider,
  signInWithRedirect,
  User,
} from "firebase/auth";
import Orders from "./routes/orders/orders";
import AddOrder from './routes/add_order';



import { InteractionRequiredAuthError, PublicClientApplication, SsoSilentRequest } from '@azure/msal-browser';
import { Configuration } from "@azure/msal-browser";
import { httpsCallable } from "firebase/functions";
import { AppShell, MantineProvider, Loader, Center, Stack, ColorSchemeScript, Divider, ThemeIcon, } from '@mantine/core';
import { MainLinks } from './components/mainlinks';
import { Spotlight, SpotlightActionData } from '@mantine/spotlight';

import { ModalsProvider } from '@mantine/modals';

import {
  Paper,
  Title,
  Text,
  Button,
  Container,
  Group,
  Image
} from '@mantine/core';
import { IconBrandWindows, IconBulb, IconMessages, IconMoneybag, IconReport, IconSettings, IconSpeakerphone, IconTool, IconUserCog, IconUsers } from '@tabler/icons-react';
import { IconHome, IconDashboard, IconFileText, IconSearch } from '@tabler/icons-react';
import { Notifications } from '@mantine/notifications';
import { AppConfigType, ClaimType, ConfigDataType, CustomClaims, CustomClaimsRequest, CustomClaimsResult, Fund, GlobalDataType, UserDataType } from './types';
import { Client } from '@microsoft/microsoft-graph-client';
import TransactionForm from './routes/transactions/transaction_form';
import FlowEditor from './routes/settings/flow_editor';
import TransactionsList from './routes/transactions/TransactionsList';
import TransactionDetails from './routes/transactions/transaction_details';
import Audit from './routes/audit/audit';
import FundDetails from './routes/dashboard/fund_details';
import Settings from './routes/settings/settings';
import CustomerMain from './routes/customers/customer';
import KfhRMSettings from './routes/settings/SettingsKfhRM';
import { EmailLogin } from './components/email_login';
import { VersionChecker } from './components/version_checker';
import Reports from './routes/reports/reports';
import { app, auth, firestore, functions } from './helpers/firebaseContext';
import FirebaseProvider from './helpers/firebaseProvider';
// import CommunicationForm from './routes/communication/CommunicationForm';
import Services from './routes/services/services';
import AddService from './routes/services/add_service';
import ServicePage from './routes/services/service';
import CreateCampaign from './routes/campaigns/create_campaign';
import Campaigns from './routes/campaigns/campaigns';
import CreateFeedback from './routes/feedbacks/create_feedback';
import AddFundReport from './pages/fundReports/add_fund_report';
import FundReportsList from './pages/fundReports/fund_reports_list';
import RMsListPage from './routes/settings/RMsListPage';

//set environment type
// type DevEnvironment = 'test' | 'production' | 'local';


export interface RouteType {
  route: JSX.Element,
  to: string,
  showfor?: ClaimType[]
  icon?: JSX.Element
  label?: string,
}


export const routes: RouteType[] = [
  {
    to: "/",
    icon: <ThemeIcon color="blue" variant="light"><IconHome size={16} /></ThemeIcon>,
    label: 'Home',
    route: <Route key="root" path="/" element={<Home />} />
  },
  // {
  //   to: "/tempz",
  //   // icon: <ThemeIcon color="darkgreen" variant="light"><IconHome size={16} /></ThemeIcon>,
  //   label: 'Replace PDF',
  //   route: <Route key="root" path="/tempz" element={<ReplacePagePDF />} />
  // },
  {
    to: "/customers",
    icon: <ThemeIcon color="teal" variant="light"><IconUsers size={16} /></ThemeIcon>,
    label: 'Customers',
    // showfor: ["rm", "oper", "cr", "risk", "admin", "kfh_rm", "kfh_oper", "digital"],
    showfor: ["rm", "oper", "cr", "risk", "admin", "kfh_rm", "kfh_oper", "digital"],
    route: <Route key="customers_root" path="/customers" >
      <Route key="customers" index element={<Customers />} />
      <Route key="customers/id" path=":id" element={<CustomerMain />} />
    </Route>,

  },
  {
    to: "/orders", route: <Route key="orders_root" path="/orders" >
      <Route key="orders" index element={<Orders />} />
      <Route key="orders/id" path=":id" element={<Order />} />
    </Route>,
    icon: <ThemeIcon color="violet" variant="light"><IconMessages size={16} /></ThemeIcon>,
    label: 'Orders',

  },

  {
    showfor: ["admin", "cr", "digital"],
    to: "/services",
    route: <Route key="services_root" path="/services" >
      <Route key="services" index element={<Services />} />
      <Route key="services/id" path=":id" element={<ServicePage />} />
    </Route>,

    label: 'Services',

  },
  {
    to: "/settings", showfor: ["oper", "cr", "risk", "admin", "sys", "digital"],
    icon: <ThemeIcon color="grape" variant="light"><IconSettings size={16} /></ThemeIcon>,
    label: 'Settings',
    route: <Route key="settings" path="/settings" element={<Settings />} />,
  },

  {
    icon: <ThemeIcon color="red" variant="light"><IconUserCog size={16} /></ThemeIcon>,
    label: 'KFH RM',
    to: "/kfh-rms",
    showfor: ["kfh_oper", "admin", "digital"],
    route: <Route key="kfh-rms" path="/kfh-rms" element={<RMsListPage />} />,
  },

  // {
  //   icon: <ThemeIcon color="black" variant="light"><IconMail size={16} /></ThemeIcon>,

  //   to: "/communication",
  //   label: "Communication",
  //   showfor: ["admin",],
  //   route: <Route key="communication" path="/communication" element={<CommunicationForm />} />,
  // },
  {
    to: "/campaigns", showfor: ["admin", "cr", "digital"], route: <Route key="campaigns_root" path="/campaigns"  >
      <Route key="campaigns" index element={<Campaigns />} />
      <Route key="campaigns/create" path="create/" element={<CreateCampaign />} />
      <Route key="campaigns/edit/:campaignId" path="edit/:campaignId" element={<CreateCampaign />} />

      {/* <Route path="add" element={<TransactionsPanel />} /> */}
    </Route>,
    icon: <ThemeIcon color="blue" variant="light"><IconSpeakerphone size={16} /></ThemeIcon>,
    label: 'Campaigns',

  },
  {
    icon: <ThemeIcon color="blue" variant="light"><IconBrandWindows size={16} /></ThemeIcon>,
    label: 'Fund Reports',
    to: "/funds-reports",
    showfor: ["cr", "admin", "digital"],
    route:

      <Route path="/funds-reports">
        <Route key="funds-reports" index element={<FundReportsList />} />
        <Route key="funds-reports-add" path="create" element={<AddFundReport />} />
      </Route>
    ,
  },

  {
    icon: <ThemeIcon color="green" variant="light"><IconTool size={16} /></ThemeIcon>,

    to: "/services",
    label: "Services",
    showfor: ["admin", "cr", "digital"],
    route: <Route key="services" path="/services" element={<Services />} />,
  },
  {
    to: "/flow_editor", showfor: ["admin", "digital"], route: <Route key="flow_editor" path="/flow_editor" element={<FlowEditor />} />,
  },
  // {
  //   icon: <ThemeIcon color="yellow" variant="light"><IconSettings size={16} /></ThemeIcon>, label: 'RoboKFH (Experiment)',
  //   to: "/robo", showfor: ["admin", "digital"], route: <Route key="robo" path="/robo" element={<Robo />} />,
  // },
  {
    to: "/add_order", route: <Route key="add_order_root" path="/add_order"  >
      <Route key="add_order" index element={<AddOrder />} />
      <Route key="add_order/id" path=":civilIDParam" element={<AddOrder />} />
    </Route>,
  },
  {
    to: "/add_service", route: <Route key="add_service_root" path="/add_service"  >
      <Route key="add_service" index element={<AddService />} />
      <Route key="add_service/id" path=":civilIDParam" element={<AddService />} />
    </Route>,
  },

  {
    to: "/funds", showfor: ["admin", "digital"], route: <Route key="view_fund_details" path="/funds"  >
      <Route key="funds" index element={<FundDetails />} />
      <Route key="funds/fundType/id" path=":fundType/:fundID" element={<FundDetails />} />
      <Route key="funds/id" path=":fundID" element={<FundDetails />} />
    </Route>,
  },
  // {
  //   to: "/transactions", showfor: "admin", hidefor: "kfh_rm", route: <Route path="/transactions"  >
  //     <Route index element={<TransactionsList />} />
  //     <Route path="add" element={<TransactionsPanel />} />
  //   </Route>
  // },

  {
    to: "/transactions", showfor: ["cr", "admin", "oper", "digital"], route: <Route key="transactions_root" path="/transactions"  >
      <Route key="transactions" index element={<TransactionsList />} />
      <Route key="transactions/add" path="add/" element={<TransactionForm />} />
      <Route key="transactions/add/id" path="add/:customerID" element={<TransactionForm />} />
      <Route key="transaction/id" path=":transactionID" element={<TransactionDetails />} />

      {/* <Route path="add" element={<TransactionsPanel />} /> */}
    </Route>,
    icon: <ThemeIcon color="gray" variant="light"><IconMoneybag size={16} /></ThemeIcon>,
    label: 'Transactions',

  },

  // {
  //   icon: <ThemeIcon color="green" variant="light"><IconMagnet size={16} /></ThemeIcon>, label: 'Audit',
  //   to: "/audit", showfor: ["admin", "risk", "oper", "digital"], route: <Route key="audit" path="/audit" element={<Audit />} />,
  // },


  {
    icon: <ThemeIcon color="blue" variant="light"><IconReport size={16} /></ThemeIcon>, label: 'Reports',
    to: "/reports", showfor: ["admin", "digital"], route: <Route key="reports" path="/reports" element={<Reports />} />,

  },
  {
    icon: <ThemeIcon color="orange" variant="light"><IconBulb size={16} /></ThemeIcon>,
    label: 'Fekra',
    to: "/feedback",
    showfor: ["kfh_oper", "kfh_rm", "admin", "digital"],
    route: <Route key="feedback" path="/feedback" element={<CreateFeedback />} />,
  },



];

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: "675f257f-4d63-41b6-ab23-befdb8a79ced",
    authority: "https://login.microsoftonline.com/6085abaf-4706-4376-b921-7b94faf53758",

    //IMPORTANT NOTE!  This freakin' line down here is REALLY important to get right.  Microsoft authentication will NOT work if you change domains.  You'll need to add it to the redirects in Azure AD and add THAT here, even if it's a bullshit redirect.
    redirectUri: process.env.NODE_ENV !== 'production' ? "http://localhost:3000/__/auth/handlerold" : (process.env.REACT_APP_ENV !== 'production') ? "https://kfh-capital-crm-test.firebaseapp.com/__/auth/handlerold" : "https://portal.kfhcapital.com.kw/__/auth/handlerold",
    // postLogoutRedirectUri: "/"
  },
  system: {
    windowHashTimeout: 9000, // Applies just to popup calls - In milliseconds
    iframeHashTimeout: 9000, // Applies just to silent calls - In milliseconds
    loadFrameTimeout: 9000 // Applies to both silent and popup calls - In milliseconds
  }

};

console.log("redirect is", msalConfig.auth.redirectUri);


// // Add here scopes for id token to be used at MS Identity Platform endpoints.
// export const loginRequest: PopupRequest = {
//   scopes: ["User.Read"]
// };

// // Add here the endpoints for MS Graph API services you would like to use.
// export const graphConfig = {
//   graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
// };



export let msalInstance = new PublicClientApplication(msalConfig);
let isMsalInitialized = false;
// const getMsalInstance = async () => {

//   const msalClient = new PublicClientApplication(msalConfig);
//   return await msalClient.initialize();
// }

// export const msalInstance = getMsalInstance();





// let allowedGroups = ["07adab0d-322f-4bf0-907a-5911cf746d99"];




function Login() {


  let provider = new OAuthProvider('microsoft.com');
  provider.setCustomParameters({
    prompt: "consent",
    // tenant: "6085abaf-4706-4376-b921-7b94faf53758",
  })

  // provider.addScope('user.read.all');



  // const app = initializeApp(getFirebaseConf());
  // const auth = getAuth(app);


  //this is if we want to log in using Microsoft Azure AD
  function showSignIn() {

    signInWithRedirect(auth, provider)
      .then((result) => {
        console.log("signin result was", result);

        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.

        // Get the OAuth access token and ID Token
        const credential = OAuthProvider.credentialFromResult(result);
        if (credential) {
          const accessToken = credential.accessToken;
          const idToken = credential.idToken;

          console.log(accessToken, idToken);
        }
      })
      .catch((error) => {
        console.log("Error was", error)
        // Handle error.
      });
  }




  return (

    <Container size={460} my={30}>
      <Image src="/logo.svg" alt="logo" />
      <Title ta="center">
        Hello!
      </Title>
      <Text c="dimmed" size="sm" ta="center">
        Let's sign in
      </Text>

      <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
        <Divider label="For KFH Capital users" labelPosition="center" my="lg" />

        <Group grow className={classes.controls}>
          <Button onClick={showSignIn} color="#AA9F8E" leftSection={<IconBrandWindows />} className={classes.control}>Log in with Microsoft (For KFH Capital)</Button>
        </Group>
        <Divider label="For KFH Users" labelPosition="center" my="lg" />
        <EmailLogin />

      </Paper>
    </Container>

  )
}



function makeMicrosoftClient(accessToken: string): Client {
  return Client.init({
    defaultVersion: "v1.0",
    debugLogging: true,
    authProvider: (done) => {
      done(null, accessToken);
    },
  });
}

function App() {


  // process.env.REACT_APP_TYPESENSE_ORDERS_COLLECTION = "orders"

  useEffect(() => {
    document.title = "KFHC Portal";
    if (process.env.NODE_ENV !== 'production') document.title = "KFHC Portal (LOCAL)";
    else if (process.env.REACT_APP_ENV === 'development') document.title = "KFHC Portal (TEST)";
  }, []);


  const [userData, setUserData] = useState<UserDataType>({
    pdf: null, user: null, microsoftClient: null, getAzureADToken: async (userData: UserDataType): Promise<string | undefined> => {
      //The reason this is here because sometimes the Azure AD token expires and becomes invalid for any subsequent calls to the API.
      //I'm just checking this here and refreshing when we need to.
      //Also, the reason I'm passing in userData is because SOMEHOW the userData in state keeps spitting out null values.  
      //I thought userData up top was a reference, but apparently it's not.

      //ok let's check if msal is there in the first place.  If not, return null


      if (!userData.msal) {
        console.log("Somehow we don't have msal in userData.", userData);
        return;
      }

      //ok let's check if we have a token already.  
      let token: string = userData.msal.accessToken;
      if (token) {
        //check if it's expired
        if (userData.msal.expiresOn!.getTime() > new Date().getTime()) {
          // return token;  //no returns, we need token for setUserData
        }
        else if (userData.user) {
          //let's refresh it, somehow.
          let msal = await microsoftAuth(userData.user);
          token = msal!.accessToken;


        }



        setUserData({ ...userData, microsoftClient: makeMicrosoftClient(token) });

        return token;
      }

      return;
    },
  });
  const [configData, setConfigData] = useState<ConfigDataType>({});
  const [globalData, setGlobalData] = useState<GlobalDataType>({});


  functions.region = "europe-west1";



  //firebase functions onRequest stuff
  let apiUrl;

  if (process.env.NODE_ENV !== 'production') {
    apiUrl = `https://${functions.region}-kfh-capital-crm-test.cloudfunctions.net`;
    const OVERRIDE_FIREBASE = (process.env.REACT_APP_OVERRIDE_FIREBASE_CONFIG === "true" || process.env.REACT_APP_OVERRIDE_FIREBASE_CONFIG === "True")
    if (
      OVERRIDE_FIREBASE
    )
      apiUrl = `http://localhost:5001/kfh-capital-crm-test/${functions.region}`


  }
  else if (process.env.REACT_APP_ENV !== 'production') {
    apiUrl = `https://${functions.region}-kfh-capital-crm-test.cloudfunctions.net`;
  }
  else if (process.env.REACT_APP_ENV === 'production') {
    apiUrl = `https://${functions.region}-kfh-capital-crm.cloudfunctions.net`;
  }



  const appConfig: AppConfigType = { apiUrl }



  const auth = getAuth(app);

  let provider = new OAuthProvider('microsoft.com');
  provider.setCustomParameters({
    prompt: "consent",
    tenant: "6085abaf-4706-4376-b921-7b94faf53758",
  })

  const [needsLogin, setNeedsLogin] = useState<boolean>(false);
  const [needsTokenRefresh, setNeedsTokenRefresh] = useState<boolean>(true);
  const db = firestore;

  const customClaimsCall = httpsCallable<CustomClaimsRequest, CustomClaimsResult>(functions, 'customClaims');


  //listen to config
  useEffect(() => {
    if (!userData.user?.email) {
      return;
    }

    const configCollection = collection(db, "config");

    return onSnapshot(configCollection, (snapShot) => {
      let configData: any = {};
      snapShot.docs.forEach(doc => {
        configData[doc.id] = doc.data();
      });
      setConfigData(configData);
    })
    /*
    useFirestoreCollection(configCollection)
    const { status, data: config } = useFirestoreCollectionData(configCollection, {
      idField: 'id', // this field will be added to the object created from each document
    });
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.user?.email]);

  //listen to funds
  useEffect(() => {
    if (!userData.user?.email) {
      return;
    }

    const fundsCollection = collection(db, "funds");

    return onSnapshot(fundsCollection, (snapShot) => {
      let globalData: GlobalDataType = {};
      let fundsMap: GlobalDataType["fundsMap"] = {} as GlobalDataType["fundsMap"];
      const fundData = snapShot.docs.map((doc) => {
        const row = doc.data() as Fund;
        row.code = doc.id;
        fundsMap![doc.id] = row;
        return row;
      });

      console.log("fundsMap  ORIGINAL=======>>", fundsMap);


      globalData.funds = fundData;
      globalData.fundsMap = fundsMap;
      globalData.privateFunds = fundData?.filter((fundObj) => fundObj.private);
      globalData.publicFunds = fundData?.filter((fundObj) => !fundObj.private);
      setGlobalData(globalData);
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.user?.email]);




  let tokenRefresher: NodeJS.Timeout | null = null;


  let getCustomClaims = async (user: User, microsoftToken?: string) => {
    //send auth data to firebase custom claims function at customClaims

    //this sets the claims for the user based on the user's microsoft account (group, id, whatever).  we retrieve it later in firebaseTokenResult.claims.customClaims
    //It also retrieves the searchKey for Algolia

    try {

      const claimsResult = await customClaimsCall({ token: microsoftToken });

      console.log(claimsResult);

      // setLoadingMsg("Authorizing...");

      let firebaseTokenResult = await user.getIdTokenResult(true);
      setUserData(prevState => ({ ...prevState, firebaseTokenResult: firebaseTokenResult, customSearchKey: claimsResult.data.customSearchKey, rmsCollectionSearchKey: claimsResult.data.rmsCollectionSearchKey, customerCollectionSearchKey: claimsResult.data.customerCollectionSearchKey }));

      console.log("I think we refreshed our token", firebaseTokenResult.claims);
      setNeedsTokenRefresh(false);

      let customClaims = firebaseTokenResult.claims.customClaims;


      if (!customClaims || Object.keys(customClaims).length === 0) {
        setLoadingMsg("You're not authorized to use this app.");
      }
      else {
        //We're good to go!
        setLoadingMsg("");
      }
    }
    catch (e: any) {
      setLoadingMsg(`Auth failure: ${e.message}`);

      console.log("Custom claims error", e);

    }

  }



  let microsoftAuth = async (user: User) => {
    //get a token to access microsoft's graph api
    //micron3al auth
    let requestScope: SsoSilentRequest = {
      scopes: ["User.Read", "User.Read.All"],
      loginHint: user.email || "",
      prompt: "none",
      // domainHint: "kfhcapital.com.kw"
      // redirectUri: "http://localhost:3000/blank.html"

    };

    console.log(requestScope);
    console.log("Workin!");

    try {
      // setLoadingMsg("Getting token...");
      if (!isMsalInitialized) {

        await msalInstance.initialize();
        isMsalInitialized = true;
      }
      let msalTokenResponse = await msalInstance.ssoSilent(requestScope);
      // Do something with the tokenResponse
      console.log("msal!", msalTokenResponse)

      setUserData(prevState => ({ ...prevState, msal: msalTokenResponse, microsoftClient: makeMicrosoftClient(msalTokenResponse.accessToken) }));


      if (needsTokenRefresh) {
        await getCustomClaims(user, msalTokenResponse.accessToken);
      }


      //activate token refresher
      if (tokenRefresher) {
        clearTimeout(tokenRefresher);
      }
      let refreshTimer = msalTokenResponse!.expiresOn!.getTime() - Date.now() - 1000 * 60 * 5;
      console.log("refreshing in", refreshTimer);

      tokenRefresher = setTimeout(() => {
        microsoftAuth(user);
      }, refreshTimer);


      return msalTokenResponse;

    } catch (error: any) {
      console.log(error);
      const accounts = msalInstance.getAllAccounts();
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        console.log("trying to redirect 1", accounts);
        setLoadingMsg("Redirecting to Microsoft login... " + error);
        if (accounts.length === 0) {
          console.log("trying to redirect", accounts);
          return msalInstance.acquireTokenRedirect({ ...requestScope, prompt: "select_account", redirectUri: msalConfig.auth.redirectUri } as any);
        }
        else {
          setLoadingMsg("unknown error occurred when redirecting to microsoft login " + error);
          return;
        }
      }
      else {
        console.log("Not an instance of InteractionRequiredblabla", error)
        setLoadingMsg("Error getting token.  " + error.message);
        if (accounts.length === 0) {
          console.log("trying to redirect", accounts);
          msalInstance.clearCache();
          return msalInstance.acquireTokenRedirect({ ...requestScope, prompt: "select_account", redirectUri: msalConfig.auth.redirectUri } as any);
        }
        else {
          msalInstance.clearCache();
          setLoadingMsg("unknown error occurred when redirecting to microsoft login " + error?.message);
          return msalInstance.acquireTokenRedirect({ ...requestScope, prompt: "select_account", redirectUri: msalConfig.auth.redirectUri } as any);
        }
      }
    }
  }


  useEffect(() => {
    console.log("Rerendering");
    let unsubscribe = auth.onAuthStateChanged(user => {
      console.log("Authstate changed!", user);
      setNeedsLogin(!user);
      // console.log(user);
      setUserData(prevState => ({ ...prevState, user }));

      // console.log("user is", user);

      if (user?.providerData[0].providerId === "microsoft.com") {
        microsoftAuth(user);
      }
      else if (user) {
        getCustomClaims(user);
      }
    })
    // console.log(unsubscribe);
    return () => {
      console.log("unsubbed from authstate changes");
      unsubscribe()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const [loadingMsg, setLoadingMsg] = useState<string>("Logging in...");








  //Spotlight
  const actions: SpotlightActionData[] = [
    {
      id: "0",
      title: 'Not',
      description: 'Search through customers',
      onClick: () => console.log('Home'),
      leftSection: <IconHome size={18} />,
    },
    {
      id: "1",

      title: 'Done',
      description: 'Search through orders or funds',
      onClick: () => console.log('Dashboard'),
      leftSection: <IconDashboard size={18} />,
    },
    {
      id: "2",
      title: 'Yet',
      description: 'Other search requirements?',
      onClick: () => console.log('Documentation'),
      leftSection: <IconFileText size={18} />,
    },
  ];





  return (
    <>
      <ColorSchemeScript defaultColorScheme="light" />

      <MantineProvider defaultColorScheme="light">

        <Spotlight
          actions={actions}

          searchProps={{ placeholder: "Search...", leftSection: <IconSearch size={18} /> }}
          nothingFound="Nothing found..."
          shortcut="/"


        />

        <Notifications />

        <FirebaseProvider>
          {/* <Container fluid> */}
          <MyUserDataContext.Provider value={userData}>
            <ConfigContext.Provider value={configData}>
              <AppConfigContext.Provider value={appConfig}>
                <GlobalDataContext.Provider value={globalData}>
                  {/* <ModalsProvider>  changed to be put inside the context wrappers so i can use the context inside modals*/}
                  <ModalsProvider>
                    {needsLogin ? <Login /> : loadingMsg !== "" ?


                      <Center style={{ height: 500 }}>

                        <Stack gap="xl" ta="center">
                          <Image style={{ width: 500 }} src="/logo.svg" alt="logo" />
                          <div
                            style={{ width: "100%", display: "flex", justifyContent: "center" }}
                          >

                            <Loader


                              size='xl' color='#AA9F8E' type='bars' />

                          </div>
                          <Text c="dimmed" size="xl" ta="center">
                            {loadingMsg}

                          </Text>
                        </Stack>

                      </Center>

                      :



                      // <LoadingOverlay visible={true} overlayBlur={2}>

                      <AppShell
                        p="md"


                        header={{ height: 100, }}

                        navbar={{
                          width: "12.5rem",
                          breakpoint: 'sm',
                          collapsed: { desktop: false }
                        }}
                      >
                        <AppShell.Header
                          h={"100"}
                          w={"100vw"}
                          p={"xs"}


                        >
                          <Group
                            w={"100vw"}
                            wrap={"nowrap"}


                          >

                            <Topbar />
                          </Group>
                        </AppShell.Header>
                        <AppShell.Navbar


                          p={"md"}
                        >

                          {/* <Navbar.Section > */}

                          <MainLinks />
                          <VersionChecker />
                          {/* </Navbar.Section> */}

                        </AppShell.Navbar>




                        <AppShell.Main
                          style={{ background: "rgb(248, 249, 250)" }}

                        >
                          <Routes>


                            {routes.map((route, index) => {
                              let customClaims = userData.firebaseTokenResult?.claims.customClaims as CustomClaims || {};


                              if (route.route === null) return null;

                              if (route.showfor) {
                                let exists = false;

                                for (const claimString in customClaims) {
                                  let claim = claimString as ClaimType;

                                  if (customClaims[claim] === true && route.showfor.includes(claim)) {
                                    exists = true;
                                    break;
                                  }
                                }

                                if (!exists) return null;
                              }

                              return route.route;
                            })}

                            <Route
                              path="*"
                              element={
                                <main style={{ padding: "1rem" }}>
                                  <p>Hi.  There's nothing here.</p>
                                </main>
                              }
                            />
                          </Routes>
                        </AppShell.Main>
                      </AppShell>
                    }
                  </ModalsProvider>
                </GlobalDataContext.Provider>
              </AppConfigContext.Provider>
            </ConfigContext.Provider>
          </MyUserDataContext.Provider>

          {/* </Container> */}
        </FirebaseProvider>


      </MantineProvider>
    </>

  );
}

export default App;