
import { useEffect, useState } from 'react'
import { civilValid, generateIBANFromKFHAccountNo, getKFHAccountNo } from '../utils';
import { httpsCallable } from 'firebase/functions';
import { isValidIBAN } from 'ibantools';
import { FirebaseCustomerBankAccount, CustomAutocomplete, AddOrderRequest, Customer, UpdateOrderRequest, NotificationMSG, BaseResult, AddAccountRequest } from '../types';
import { DocumentData, Unsubscribe, collection, onSnapshot, query, } from 'firebase/firestore';
import { UseFormReturnType, useForm } from '@mantine/form';
import { Alert, Button, Grid, Group, Modal, Select } from '@mantine/core';
import BankAccountInfo from '../routes/BankAccountInfo';
import { IconAlertCircle } from '@tabler/icons-react';
import { useFirestore, useFunctions } from '../helpers/firebaseContext';

interface CustomerBankAccountsSelectType {
  form: UseFormReturnType<AddOrderRequest>
  customerObj?: Customer,
  disabled?: boolean

}

const CustomerBankAccountsSelect = (props: CustomerBankAccountsSelectType) => {
  const { form, customerObj, disabled } = props;
  const [customerAccounts, setCustomerAcounts] = useState([] as any);
  const [bankAccountModal, setBankAccountModalOpened] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [msg, setMsg] = useState<NotificationMSG | null>(null);

  const functions = useFunctions();

  const db = useFirestore();


  const bankAccountForm = useForm<UpdateOrderRequest>({

    initialValues: {
      iban: "",
      account: "",
    },
  });

  const addCustomerAccount = async (newIban: string) => {


    const values: AddAccountRequest = {
      id: customerObj?.id ?? '',
      iban: newIban,
    }
    const addAccountToClient = httpsCallable<AddAccountRequest, BaseResult>(functions, "addAccountToClient");
    try {

      const res = await addAccountToClient(values);
      await setMsg({ type: res.data.status, message: res.data.message ?? '' });
    }
    catch (error: any) {

      await setMsg({ type: "error", message: 'error' });
      console.log(error);

    }


  }



  const getCustomerAccounts = (customerObj: Customer) => {
    let accountList = [] as (FirebaseCustomerBankAccount & CustomAutocomplete)[];

    const customerRef = collection(
      db,
      "customers",
      customerObj.id!,
      "accounts"
    )
    const accountsQuery = query(customerRef);
    return onSnapshot(accountsQuery, (snapshot) => {

      accountList = [];
      snapshot.docs.forEach((doc: DocumentData) => {
        var data = doc.data() as (FirebaseCustomerBankAccount & CustomAutocomplete);
        data["id"] = doc.id;
        data["label"] = data.iban
        data["value"] = data.iban!
        accountList.push(data)
      });
      setCustomerAcounts(accountList)
    })


  }
  useEffect(() => {

    let unsubscribe: Unsubscribe | undefined;
    if (customerObj?.id) {
      unsubscribe = getCustomerAccounts(customerObj as Customer)
    }
    else {
      setCustomerAcounts([])
    }

    if (unsubscribe !== undefined) {
      // Cleanup function to unsubscribe when the component unmounts or dependencies change
      return () => {
        unsubscribe!();
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerObj,])

  const checkIbanValue = (value: string | null, doNotCreate: boolean = false) => {
    bankAccountForm.clearErrors()
    if (value && isValidIBAN(value)) {
      bankAccountForm.setFieldValue("account", getKFHAccountNo(value));
      bankAccountForm.setFieldValue("iban", value);
      return true
    }

    else {
      form.setFieldValue("order.acct", "");
      form.setFieldValue("order.iban", "");
      bankAccountForm.setFieldError('iban', 'Invalid Iban');
      return false
    }
  }

  const handleSubmit = async (values: UpdateOrderRequest) => {


    console.log("VALUEs====>>>", values);
    await setMsg(null)

    await setLoading(true)
    const isValidIban = checkIbanValue(values.iban || "")


    if (isValidIban) {

      await addCustomerAccount(values.iban!)
      // await getCustomerAccounts(customerObj)

      await form.setFieldValue("order.acct", getKFHAccountNo(values.iban!));
      await form.setFieldValue("order.iban", values.iban!);


      setTimeout(() => {
        setBankAccountModalOpened(false)
        setMsg(null)
        bankAccountForm.reset()

      }

        , 500);

    }
    else {
      setMsg({ type: "error", message: "error" });

    }
    await setLoading(false)
  }





  return (
    <>
      <Grid justify='center' align='center'>

        <Grid.Col span={{ xs: 10, }} >


          <Select

            label="Account Iban"
            placeholder='Select Iban'
            {...form.getInputProps("order.iban")}
            data={customerAccounts}
            disabled={disabled}
            nothingFoundMessage="Nothing found..."
            onChange={(value: string | null) => {
              if (value && isValidIBAN(value)) {
                form.setFieldValue("order.acct", getKFHAccountNo(value));
                form.setFieldValue("order.iban", value);

              }
            }}

          />



        </Grid.Col>
        <Grid.Col span={{ xs: 2 }} >
          <label style={{ opacity: 0 }} >"label here"</label>
          <Button

            disabled={disabled}
            onClick={() => setBankAccountModalOpened(true)}
          >
            Add Account
          </Button>
        </Grid.Col>
        {/* <Grid.Col>

          <TextInput
            {...form.getInputProps("order.acct")}

            disabled={disabled}


            label="Account Number"



          />

        </Grid.Col> */}
      </Grid>

      <Modal
        size={"xl"}
        opened={bankAccountModal}

        onClose={() => { setBankAccountModalOpened(false); }}
        title="Add Iban"
      >

        <form onSubmit={bankAccountForm.onSubmit(async (values) => {





        })}>




          <BankAccountInfo
            hideUploadSlip={true}

            ibanProps={

              {
                ...bankAccountForm.getInputProps("iban"),

                value: bankAccountForm.values.iban,
                // onChange: (e) => checkIbanValue(e.target.value),
                disabled: loading
              }




            }
            transferDateProps={
              {

                hide: true

              }
            }
            acctNumberProps={
              {
                ...bankAccountForm.getInputProps("account"),

                disabled: loading

              }
            }

            onBlurIban={
              () => {
                console.log("Blurring!");
                if (bankAccountForm.values?.iban && bankAccountForm.values.iban.length === 30) {
                  let acct = getKFHAccountNo(bankAccountForm.values.iban);
                  if (acct) {
                    bankAccountForm.setFieldValue("account", acct);
                  }
                }
              }
            }

            onBlurAccountNumber={
              () => {
                if (bankAccountForm.values.account && bankAccountForm.values.account.length === 12) {
                  bankAccountForm.setFieldValue("iban", generateIBANFromKFHAccountNo(bankAccountForm.values.account) || "");
                }
              }

            }

          />



          {msg && (
            <Alert
              icon={<IconAlertCircle size={16} />}
              color={msg.type === "success" ? "green" : "red"}
              title={msg.type === "success" ? "Success" : "Failure"}
            >
              {msg.message}
            </Alert>
          )}
          <Group justify="center" mt="md">
            <Button
              disabled={loading}
              // type='submit'
              loading={loading}
              onClick={() => handleSubmit(bankAccountForm.values)}

            >
              Add Iban
            </Button>
            <Button
              color="red" type="button"
              disabled={loading}


              onClick={() => {

                setBankAccountModalOpened(false)
              }}
            >
              Cancel
            </Button>
          </Group>
        </form>
      </Modal>

    </>
  )
}

export default CustomerBankAccountsSelect