/* eslint-disable eqeqeq */

import { useContext, useState } from "react";
import { useForm } from "@mantine/form";
import axios from "axios";
import { encode } from "base64-arraybuffer";

import {
  Alert,
  Checkbox,
  Grid,
  Group,
  Modal,
  Space,
} from "@mantine/core";

import { Button } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { DocumentData, Query, collection, collectionGroup, doc, getDoc, getDocs, getFirestore, limit, query, where } from "firebase/firestore";

import _ from "underscore";
import { getLatestFilesBinaries } from "../helpers/getLatestFiles";
import { getAddressDetailsEnglish } from "../helpers/addressHelpers";
import { Approval, BaseResult, Customer, CustomerPDFData, DocType, EmployeeSignature, NotificationMSG, OrdersPath, PDFDocumentsDataTypes, UserFiles } from "../types";
import { formatNums } from "../helpers/common";
import { httpsCallable } from "firebase/functions";
import { useFunctions } from "../helpers/firebaseContext";
import { getStorage, ref } from "firebase/storage";
import { getDownloadURL } from "firebase/storage";
import { ConfigContext } from "../contexts";


declare const createPDF: (data: string) => Promise<{
  data: string,
  status: string
}>;
export interface GeneratePDFModalProps {
  rows: any[];
  opened: boolean;
  fromServer?: boolean;
  setOpened: (value: boolean) => void;
  documentsToFetch?: PDFDocumentsDataTypes[];
  dataCollectionName?: string;
  dataAccess?: string;
  getCustomerDataKey?: string;  //this is the customer object embedded in the order or service
  // generatedType?: string;
  metaTypes?: DocType[];
  objID?: string;
  includeKYC?: boolean;
  customPath?: string,
  customFileName?: string,
  setPending?: (value: boolean) => void;
  onGenerateComplete?: () => void
  manual?: boolean;
  withFilledKYC?: boolean;
  manualKYCId?: string;
  manualEmptyFiles?: OrdersPath[];
  // documentActions?: Record<string, Array<Record<string, any>>>
  /* documentActions is in rows.  is basically something like this:  It shows who took an action on which document (really just kyc and order for now.)

  {
    "kyc": [
      {
        "date": {
          "seconds": 1711357400,
          "nanoseconds": 60000000
        },
        "level": "normal",
        "name": "Abdullah AlAttal",
        "id": "zhcHWIwp5SZlmw9Tjp89Zcc3JbT2",
        "type": "review",
        "info": {
          "identities": {
            "microsoft.com": [
              "c7627aa9-28d9-4f6d-bd45-015ab2a38221"
            ],
            "email": [
              "aalattal@kfhcapital.com.kw"
            ]
          },
          "sign_in_provider": "microsoft.com"
        }
      }

    ],
    "order": [
      {
        "date": {
          "seconds": 1710757929,
          "nanoseconds": 698000000
        },
        "level": "normal",
        "name": "Abdullah AlAttal",
        "id": "zhcHWIwp5SZlmw9Tjp89Zcc3JbT2",
        "type": "review",
        "info": {
          "identities": {
            "microsoft.com": [
              "c7627aa9-28d9-4f6d-bd45-015ab2a38221"
            ],
            "email": [
              "aalattal@kfhcapital.com.kw"
            ]
          },
          "sign_in_provider": "microsoft.com"
        }
      },
      {
        "date": {
          "seconds": 1710758623,
          "nanoseconds": 313000000
        },
        "level": "normal",
        "name": "Hamad AlAjmi",
        "id": "yw8PGSnbtHdGN9bBmy68VgviXxE2",
        "type": "approve",
        "info": {
          "identities": {
            "microsoft.com": [
              "287c39bf-8016-4cf4-ae25-eb69505ad732"
            ],
            "email": [
              "halajmi@kfhcapital.com.kw"
            ]
          },
          "sign_in_provider": "microsoft.com"
        }
      }
    ]
  }


  */
}


const GeneratePDFModal = (props: GeneratePDFModalProps) => {
  const rows = props.rows;
  const opened: boolean = props.opened;
  const setOpened = props.setOpened;
  const documentsToFetch: PDFDocumentsDataTypes[] = props.documentsToFetch || []
  const dataCollectionName = props.dataCollectionName;
  const dataAccess = props.dataAccess;
  const getCustomerDataKey = props.getCustomerDataKey
  // const generatedType = props.generatedType
  const metaTypes = props?.metaTypes;
  const [confirming, setConfirming] = useState<boolean>(false);
  const [docsDone, setDocsDone] = useState<number>(0)
  const [documentsToInclude, setDocumentsToInclude] = useState<PDFDocumentsDataTypes[]>(documentsToFetch || []);
  const [msg, setMsg] = useState<NotificationMSG | null>(null);
  const [generatedDocument, setGeneratedDocument] = useState<string>();
  const config = useContext(ConfigContext);

  const functions = useFunctions();
  const setCamelCaseKey = (base: string, prefix?: string) => {
    if (prefix) {
      return prefix + base.charAt(0).toUpperCase() + base.slice(1);
    }
    return base.charAt(0).toLowerCase() + base.slice(1);
  };

  const embedApprovalsInDocumentData = (PDFDataDocuments: Record<string, any>, approvals: Approval[], employeeSignaturesObject: Record<string, EmployeeSignature>, prefix?: string,) => {

    const embedDataForAction = (actionName: string, approval: Approval) => {
      PDFDataDocuments[setCamelCaseKey(actionName, prefix)] = approval.name;
      PDFDataDocuments[setCamelCaseKey(actionName + "Date", prefix)] = approval.date.toDate().toLocaleDateString('en-GB');
      PDFDataDocuments[setCamelCaseKey(actionName + "Signature", prefix)] = employeeSignaturesObject[approval.info?.identities.email[0]]?.signature;
      PDFDataDocuments[setCamelCaseKey(actionName + "Title", prefix)] = approval.title;
    };
    approvals?.forEach((approval) => {
      if (approval.type === "review") {
        embedDataForAction("reviewed", approval);
      } else if (approval.type === "approve") {
        if (approval.level === "normal") {
          embedDataForAction("approved", approval);
        } else if (approval.level === "executive") {
          embedDataForAction("execApproved", approval);
        }
      }
      else if (approval.type === "authorized_signatory_approve") {
        embedDataForAction("authorizedSignatoryApproved", approval);
      }
    });

  }

  const fetchPdfAsArrayBuffer = async (url: string): Promise<ArrayBuffer> => {
    try {
      const response = await axios.get(url, {
        responseType: 'arraybuffer',
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching the PDF:', error);
      throw new Error('Failed to fetch PDF file.');
    }
  };

  const handleSubmitGeneratePDF = async () => {
    const db = getFirestore();

    const mainPDFDataDocuments: Record<string, any>[] = [];


    //let's get the firestore documents needed to fill out the PDF
    for (let idx in rows) {
      let row = rows[idx];

      let PDFDataDocuments: Record<string, any> = {};




      //these are the employee signatures stored in the signatures collection to stamp on PDFs (as if they're real)
      let employeeSignatures = (await getDocs(collection(db, `signatures`))).docs.map((doc) => { return { ...doc.data(), email: doc.id } as EmployeeSignature });

      const employeeSignaturesObject: Record<string, EmployeeSignature> = {};
      //to easily look up by email, let me just make an object instead of searching an array.
      employeeSignatures.forEach(data => {
        employeeSignaturesObject[data.email] = data;
      });


      console.log("employee signatures", employeeSignaturesObject);
      // }
      // else {
      //   customerData = row[getCustomerDataKey];
      //   console.log("We already have the customer obj which should hopefully contain latest KYC", customerData.lastValidKYC?.kycID);
      // }

      //does that order have a valid KYC attached to it?

      //we're gonna need the customer's KYC for everything pretty much, so let's just get that regardless of what docs are chosen.

      let kycRef;
      const customerID = getCustomerDataKey ? row[getCustomerDataKey]?.id : row?.id;

      if (row.kyc_id) {
        console.log("Ok attached KYC ID is ", row.kyc_id);
        kycRef = doc(db, `customers/${customerID}/kyc/${row.kyc_id}`);
      }
      else {
        //get the latest KYC since there's no KYC attached.
        let customerData: Customer = (await getDoc(doc(db, `customers/${customerID}`))).data() as Customer;

        //does he have a valid KYC?  Let's get that.
        if (customerData?.lastValidKYC?.kycID) {
          console.log("Ok latest KYC ID is ", customerData?.lastValidKYC?.kycID);
          kycRef = doc(db, `customers/${customerID}/kyc/${customerData?.lastValidKYC?.kycID!}`);
        }

      }
      //handle that manual kyc dont need to be valid in order to generate pdf
      if (props.manual && props.manualKYCId) {
        console.log("manual KYC ID is ", props.manualKYCId);
        kycRef = doc(db, `customers/${customerID}/kyc/${props.manualKYCId}`);
      }
      if (kycRef) {

        const latestKYCDocQuery = await getDoc(kycRef);

        if (latestKYCDocQuery.exists()) {
          PDFDataDocuments = { ...PDFDataDocuments, ...latestKYCDocQuery.data(), id: customerID };

          //get the kyc approvals!

          console.log("Got it!", PDFDataDocuments);

          //Ok so now we need to get the approvals for that KYC!  Let's get that.
          const kycActionsRef = doc(collection(kycRef, "private"), "actions");
          const kycActionsDoc = await getDoc(kycActionsRef);
          if (kycActionsDoc.exists()) {
            const approvals = (kycActionsDoc.data().approvals ?? []) as Approval[];

            embedApprovalsInDocumentData(PDFDataDocuments, approvals, employeeSignaturesObject, "kyc",);

          }




          //do a fancy sentence for the KYC source of income and source of wealth
          // if (PDFDataDocuments.sourcesOfIncome) {
          //   PDFDataDocuments.sourcesOfIncomeSentence = PDFDataDocuments.sourcesOfIncome.map((income: string) => {
          //     return PDFDataDocuments.sourcesOfIncomeTransformers[income];
          //   }).join(", ");
          // }

        }

      }
      else {
        console.warn("There is no latest KYC ID for", customerID);
      }


      for (const documentObj of documentsToInclude) {
        if (documentObj.name) {
          let dataCollectionQuery: Query<DocumentData>;
          if (documentObj.type === "collectionGroup") {
            dataCollectionQuery = query(collectionGroup(db, documentObj.name), where(documentObj.subCollectionAccessorName!, '==', row[documentObj.subCollectionAccessorName!]), limit(1));
          }
          else if (documentObj.type === "collection" && dataCollectionName && dataAccess) {
            const dataCollection = collection(db, dataCollectionName, row[dataAccess] as string);
            dataCollectionQuery = query(dataCollection, limit(1))
          }
          else {
            continue;  //nothing to query
          }


          // promises[idx].push(
          let snapshot = await getDocs(dataCollectionQuery);
          if (snapshot.docs.length > 0) {
            PDFDataDocuments = { ...PDFDataDocuments, ...snapshot.docs[0].data() };
          }
          // )
        }
      }

      const documentActions = row.documentActions;

      console.log("Document actions!!!!", documentActions);

      embedApprovalsInDocumentData(PDFDataDocuments, documentActions, employeeSignaturesObject);

      mainPDFDataDocuments.push(PDFDataDocuments);
    }


    console.log("Main PDF Data documents", mainPDFDataDocuments);


    let customersList = mainPDFDataDocuments.map((customerDataObj, idx) => {

      let mainPDFData: any = {
        "is_individual": true,
        "wire_transfer": true,
      };



      mainPDFData = { ...mainPDFData, ...customerDataObj }


      let fieldsToConvertToDateStrings = ["birthDate", "civilIDExpiryDate", "passportExpiryDate", "created", "date", "transferDate"]
      let numbersToFormatFields: string[] = ["transferAmount", "fees", "postNavAmount", "units"]

      numbersToFormatFields?.forEach((field: string) => {
        if (mainPDFData[field] != null) {
          mainPDFData[field] = formatNums(mainPDFData[field], ",")
        }
      })
      for (const field of fieldsToConvertToDateStrings) {
        if (mainPDFData[field] != null) {
          mainPDFData[field] = (new Date(mainPDFData[field].toDate())).toLocaleDateString('en-GB');
        }
      }

      if (mainPDFData["address"] != null && Object.entries(mainPDFData["address"])) {
        mainPDFData["address"]["DetailsEnglish"] = getAddressDetailsEnglish(mainPDFData["address"])
      }
      if (mainPDFData.name_en) {
        mainPDFData["nameEnSplit"] = { ...mainPDFData.name_en.split(" ") }
      }
      if (mainPDFData.name_ar) {
        mainPDFData["nameArSplit"] = { ...mainPDFData.name_ar.split(" ") }
      }
      if (mainPDFData.civilID) {
        mainPDFData["civilIDSplit"] = { ...mainPDFData.civilID.split("") }
        mainPDFData["countryOfResidence"] = "KW"
      }
      if (mainPDFData.birthDate) {
        mainPDFData["birthDateSplit"] = { ...mainPDFData.birthDate.split("/") }
      }
      if (mainPDFData.civilIDExpiryDate) {
        mainPDFData["civilIDExpiryDateSplit"] = { ...mainPDFData.civilIDExpiryDate.split("/") }
      }

      if (mainPDFData.passportExpiryDate) {
        mainPDFData["passportExpiryDateSplit"] = mainPDFData.passportExpiryDate.split("/")
      }

      if (mainPDFData.created) {
        mainPDFData["createdSplit"] = { ...mainPDFData.created.split("/") }
      }

      if (mainPDFData.gender === "F") {
        mainPDFData["female"] = true

      }
      else {
        mainPDFData["male"] = true
      }





      let documentsToAdd = [...documentsToInclude]

      console.log("documentsToFetch", documentsToFetch);

      if (documentsToFetch?.length) {

        documentsToFetch.forEach((documentToFetch) => {

          if (documentToFetch?.documentsToAddFunction) {
            console.log("documentsToAddFunction ----- mainPDFData", mainPDFData);

            let addedFiles = documentToFetch?.documentsToAddFunction(mainPDFData!)

            //Check if kyc is already included in the documents or not as if it not included we need to add it in case of manual and the kyc need to be filled
            if (addedFiles?.some((doc: any) => doc.path && (doc.path as string).includes('kycind')) != true && props.manual && props.withFilledKYC) {
              setDocumentsToInclude([...documentsToInclude, { name: "kyc", path: config.pdf_documents!.kyc, title: "KYC Document", }]);
              documentsToAdd = [...documentsToInclude, { name: "kyc", path: config.pdf_documents!.kyc, title: "KYC Document", }];
            }


            //filter out based on agreementType if voting, nonvoting
            let voting = false;
            if (mainPDFData.voting == true) {
              voting = true
            }
            // console.log("voting is", voting);

            addedFiles = addedFiles?.filter((orderPath) => {
              //does the agreementType field exist?
              // console.log("Agreement type is ", orderPath.agreementType);
              if (orderPath.agreementType) {
                const include = voting ? orderPath.agreementType === "voting" : orderPath.agreementType === "nonvoting";
                // console.log("included", include);
                return include;
              }
              return true;
            })

            console.log("addedFiles", addedFiles);

            if (addedFiles?.length) { documentsToAdd = [...documentsToAdd, ...addedFiles] }
          }

        })
      }

      console.log("documentsToAdd", documentsToAdd);

      let idNumber = mainPDFData?.iban
      mainPDFData = {
        ...mainPDFData,
        documentsToInclude: documentsToAdd.filter((docToAdd) => docToAdd?.path != null),
        idNumber: idNumber,
      }
      //workaround for a quick fix, needs to be updated to recursive function to allow nested values in pdf script 
      if (mainPDFData?.paciData) {
        mainPDFData = {
          ...mainPDFData,
          paciInfo: mainPDFData?.paciData.PersonalData,
          paciAddress: mainPDFData?.paciData.PersonalData?.Address,
          paciGovData: mainPDFData?.paciData.PersonalData?.GovData,
        }
      }



      //if it's a kuwaiti national, add the TIN.  
      //TODO: I think it's a better idea to populate this automatically during KYC process

      if (mainPDFData.nationality === "KW" && (mainPDFData.tin == null || mainPDFData.tin.length === 0)) {
        mainPDFData.tin = [{
          available: true,
          country: "KW",
          number: mainPDFData.civilID,
          type: "Civil ID"
        }]
      }


      return mainPDFData;
    });

    console.log("MAIN PDF DATA!", customersList);

    //Are there any files we need to add to the PDF?
    // if (documentsToInclude?.some((doc: any) => doc?.metaKey?.length > 0))
    {
      const customersListFiles: Promise<CustomerPDFData>[] = customersList.map(async (obj: any) => {


        let pathKyc = ""
        let uploadPath = ``;

        if (getCustomerDataKey) {
          pathKyc = `kyc/${obj[getCustomerDataKey].id}/`
          uploadPath = `orders/${obj.id}`;
        }
        else {
          pathKyc = `kyc/${obj.id}/`
          uploadPath = `kyc/${obj.id}`;

        }
        let pathOrders = `orders/${obj.id}/`


        //handle the empty forms and check if kyc need to be added or not
        let userFiles: any[] = [];
        if (props.manual && !props.withFilledKYC) {
          let isKYCAdded = false;
          if (props.manualEmptyFiles) {
            for (let index = 0; index < props.manualEmptyFiles.length; index++) {
              const fileData = props.manualEmptyFiles[index];
              if (fileData.path.includes("kycind-update-closed-ended")) {
                isKYCAdded = true;
              }
              const file = await fetchPdfAsArrayBuffer(fileData.path);
              userFiles.push({
                file: encode(file), type: 'pdf', fileMetas: []
              });
            }
          }
          if (!isKYCAdded) {
            const file = await fetchPdfAsArrayBuffer(config.pdf_documents!.kyc);
            userFiles.push({
              file: encode(file), type: 'pdf', fileMetas: []
            });
          }
        } else {
          userFiles = await getLatestFilesBinaries(pathKyc, pathOrders, documentsToInclude)
        }



        return { ...obj, uploadPath, userFiles: userFiles }
      })
      customersList = await Promise.all(customersListFiles);
    }

    //set type to submit because we want the user to create an entirely new KYC
    // try {
    // let res =
    //   await axios.post(`${appConfigContext.apiUrl}/generatePDF`, {
    //     customersList: customersList,
    //   });


    let isSuccess = false;
    if (props?.fromServer) {
      const generateCustomerPDF = httpsCallable<any, BaseResult>(functions, "generateCustomerPDF");
      const res = await generateCustomerPDF({ "customersList": customersList, customPath: props?.customPath, customFileName: props?.customFileName });
      isSuccess = !!res?.data;
      // console.log("res =======??", res.data);

      if (isSuccess) {
        if (res?.data?.data?.length == 1) {
          try {
            const storage = getStorage();
            const fileRef = ref(storage, res?.data?.data[0])
            const url = await getDownloadURL(fileRef); // Get the file's download URL
            setGeneratedDocument(url);
          } catch (e) {
            // console.log("ERRRR========>>", e);


          }
        }
      }

    }
    else {
      const pdfData = JSON.stringify({ "customersList": customersList });

      const { data: url, status } = await createPDF(pdfData);

      // console.log("DATA IS HERE ======>>>>", url);

      if (status === "success") {
        isSuccess = true;
        if (url) {
          setGeneratedDocument(url);
          window?.open(url);
        }

      }
    }

    // generateCustomerPDF




    if (isSuccess) {
      setMsg({ type: "success", message: "Success" });
      setConfirming(false)
      // window.location.reload();

    }
    console.log("createPDF success state: ", isSuccess);


    // const pdfBase64 = res?.data.data;
    // const pdfBuffer = decode(pdfBase64)
    // const blob = new Blob([pdfBuffer], { type: 'application/pdf' })
    // const pdfUrl = URL.createObjectURL(blob);
    // window.open(pdfUrl);
    // }
    // catch (e) {
    //   console.log("ERRRRRRR==============>>>>>>", e);
    // }
  }
  const form = useForm({

  });


  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setMsg(null);
          setOpened(false);
          setConfirming(false)
          // props?.setPending(false)
          setDocsDone(0)
          if (props?.onGenerateComplete) {

            props.onGenerateComplete()
          }
          // setDocumentsToInclude([] || documentsToFetch)
        }}
        title="Generate PDF"
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            console.log(values);

            // setConfirmDialogError(null);
            setMsg(null);
            // props?.setPending(true)

            setConfirming(true);
            console.log("yeaaa we are here");

            try {

              await handleSubmitGeneratePDF()

              // setIsError(true);
            } catch (error: any) {
              // setIsError(true);
              console.log(error);
              // setMsg(error.message);
              // setMsg({type:"error",msg:});
              setMsg({ type: "error", message: error.message });

              setConfirming(false);

              // setConfirmDialogError(error.message);
            }


          })}
        >

          <Space h="xl" />
          {!props.manual && <Grid>
            <Grid.Col span={{ md: 12, lg: 6 }}>
              {/* TODO:  only enable this when asked by the parent component.  Orders for instance must have a KYC after order date. */}
              {/* TODO: this needs some major refactoring.  typing out URLs three times for a single checkbox is very, very wrong. */}
              <Checkbox
                disabled={confirming}
                label="KYC Form"
                checked={
                  documentsToInclude?.some((doc: any) => doc.path === config.pdf_documents!.kyc)

                }
                onChange={(e) => e.currentTarget.checked ? setDocumentsToInclude([...documentsToInclude, { name: "kyc", path: config.pdf_documents!.kyc, title: "KYC Document", }]) : setDocumentsToInclude(documentsToInclude.filter((docObj: any) => {
                  return !_.isEqual(docObj, { path: config.pdf_documents!.kyc, name: "kyc", title: "KYC Document", })
                }))}
              />
            </Grid.Col>
            {/* 
            <Grid.Col span={{ md: 12, lg: 6 }}>
              <Checkbox
                disabled={confirming}

                label="KFH Trade Form"
                checked={
                  documentsToInclude?.some((doc: any) => doc.path === 'https://storage.googleapis.com/public-pdfs/KFHtrade-contract-new.pdf')
                }
                onChange={(e) => e.currentTarget.checked ? setDocumentsToInclude([...documentsToInclude, { path: "https://storage.googleapis.com/public-pdfs/KFHtrade-contract-new.pdf", title: "KFH Trade Document", }]) : setDocumentsToInclude(documentsToInclude.filter((docObj: any) => {
                  return !_.isEqual(docObj, { path: "https://storage.googleapis.com/public-pdfs/KFHtrade-contract-new.pdf", title: "KFH Trade Document", })
                }))}
              />
            </Grid.Col> */}
            <Grid.Col span={{ md: 12, lg: 6 }}>
              <Checkbox
                disabled={confirming}

                label="Fatca Form"
                checked={
                  documentsToInclude?.some((doc: any) => doc.path === 'https://storage.googleapis.com/public-pdfs/fatca-cayman.pdf')
                }
                onChange={(e) => e.currentTarget.checked ? setDocumentsToInclude([...documentsToInclude, { path: "https://storage.googleapis.com/public-pdfs/fatca-cayman.pdf", title: "FATCA Document", }]) : setDocumentsToInclude(documentsToInclude.filter((docObj: any) => {
                  return !_.isEqual(docObj, { path: "https://storage.googleapis.com/public-pdfs/fatca-cayman.pdf", title: "FATCA Document", })
                }))}
              />
            </Grid.Col>

            <Grid.Col span={{ md: 12, lg: 6 }}>
              <Checkbox
                disabled={confirming}

                label="High Risk Form"
                checked={
                  documentsToInclude?.some((doc: any) => doc.path === 'https://storage.googleapis.com/public-pdfs/highrisk_letter.pdf')

                }

                onChange={(e) => e.currentTarget.checked ? setDocumentsToInclude([...documentsToInclude, { path: "https://storage.googleapis.com/public-pdfs/highrisk_letter.pdf", title: "High Risk Document", }]) : setDocumentsToInclude(documentsToInclude.filter((docObj: any) => {
                  return !_.isEqual(docObj, { path: "https://storage.googleapis.com/public-pdfs/highrisk_letter.pdf", title: "High Risk Document", })
                }))}
              />
            </Grid.Col>
            {
              metaTypes && metaTypes?.length > 0 &&

              <>

                {metaTypes?.map((metaType) => {
                  console.log("MetaType", metaType);
                  if (metaType.key === "hasExpiryDate") return null;

                  return (<Grid.Col span={{ md: 12, lg: 6 }}>

                    <Checkbox
                      label={metaType?.name}
                      disabled={confirming}

                      checked={
                        documentsToInclude?.some((doc: any) => doc.metaKey === metaType?.key)
                      }
                      onChange={(e) => e.currentTarget.checked ? setDocumentsToInclude([...documentsToInclude, { metaKey: metaType?.key, title: metaType.name }]) : setDocumentsToInclude(documentsToInclude.filter((docObj: any) => {
                        return !_.isEqual(docObj, { metaKey: metaType?.key, title: metaType.name, })

                      }))}
                    />
                  </Grid.Col>)

                })}
              </>}
            <>
              <Grid.Col span={{ md: 12, lg: 6 }}>


                <Checkbox
                  label="PACI Civil ID Data "
                  disabled={confirming}
                  checked={
                    documentsToInclude?.some((doc: any) => doc.path === "https://storage.googleapis.com/public-pdfs/civilID-form.pdf")
                  }
                  onChange={(e) => e.currentTarget.checked ? setDocumentsToInclude([...documentsToInclude, { path: "https://storage.googleapis.com/public-pdfs/civilID-form.pdf", title: "Civil ID Document", }]) : setDocumentsToInclude(documentsToInclude.filter((docObj: any) => {
                    return !_.isEqual(docObj, { path: "https://storage.googleapis.com/public-pdfs/civilID-form.pdf", title: "Civil ID Document", })
                  }))}


                />
              </Grid.Col>
            </>

          </Grid>}
          <Space h="xl" />
          <Group>
            <Button color="grape" type="submit" loading={confirming}>
              {confirming
                ? `Generating ${docsDone}/${rows.length}`
                : `Generate KYC for ${rows.length} customers`}
            </Button>

          </Group>

          <Space h="xl" />

          {msg && (
            <Alert
              icon={<IconAlertCircle size={16} />}
              color={msg.type == "success" ? "green" : "red"}
              title={msg.type == "success" ? <>Success


              </> : "Failure"}
            >
              <Grid justify="start">
                <Grid.Col span={12}>

                  {msg.message}

                  <span>
                    {generatedDocument && <Button color="blue" variant="transparent" mx={10} p={0} component="a" href={generatedDocument} target="_blank">

                      View The PDF
                    </Button>}
                  </span>
                </Grid.Col>
                {/* <Grid.Col span={12} offset={0}>



                </Grid.Col> */}
              </Grid>
            </Alert>
          )}
        </form>
      </Modal>
    </>
  );
}

export default GeneratePDFModal;