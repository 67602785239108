/* eslint-disable eqeqeq */

import { useContext, useState } from "react";
import { useForm } from "@mantine/form";
import { CSVLink } from "react-csv";

import {
  Alert,

  Group,
  Modal,
  MultiSelect,
  Space,
} from "@mantine/core";

import { Button } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

import { DocumentData, collectionGroup, getDoc, getDocs, doc, getFirestore, query, where, collection, orderBy, limit } from "firebase/firestore";

// import _ from "underscore";



import { AddressFromFinder, CustomClaims, Customer, KYC, NotificationMSG, Order, OrderStatusCode, } from "../types";
import { MyUserDataContext } from "../contexts";


type FormProps = {
  funds: string[];
  statuses: string[];
};


const GenerateCSVModal = (props: any) => {
  const userContext = useContext(MyUserDataContext);
  let userClaims = userContext.firebaseTokenResult?.claims.customClaims as CustomClaims || {};

  const opened: boolean = props.opened;
  const setOpened = props.setOpened;
  const [confirming, setConfirming] = useState<boolean>(false);
  const [msg, setMsg] = useState<NotificationMSG | null>(null);
  const [downloadReady, setDownloadReady] = useState<boolean>(false);

  const db = getFirestore();

  const headers = [
    //personal info stuff
    { label: "ID", key: "customerObj.id" },
    { label: "Type", key: "type" },
    { label: "Name (en)", key: "customerObj.name_en" },
    { label: "Name (ar)", key: "customerObj.name_ar" },
    { label: "PACI Email", key: "paciData.PersonalData.EmailAddress" },
    { label: "Gender", key: "paciData.PersonalData.Gender" },
    { label: "Civil ID Expiry", key: "paciData.PersonalData.CardExpiryDate" },
    { label: "Birth Date", key: "paciData.PersonalData.BirthDate" },
    { label: "Nationality", key: "paciData.PersonalData.NationalityEn" },
    { label: "Nationality (Arabic)", key: "paciData.PersonalData.NationalityAr" },


    //actual order stuff
    { label: "Transferred Amount", key: "transferAmount" },
    { label: "Transfer Date", key: "transferDate" },
    { label: "Order Creation Date", key: "created" },
    { label: "Post NAV Amount", key: "postNavAmount" },
    { label: "Fees", key: "fees" },
    { label: "Surplus", key: "surplus" },
    { label: "Units", key: "units" },
    { label: "Mobile", key: "customerObj.mobile" },
    { label: "Fund", key: "fund.name_en" },
    { label: "Fund ID", key: "fund.id" },
    { label: "Fund Currency", key: "fund.currency" },
    { label: "IBAN", key: "iban" },
    { label: "Account #", key: "acct" },

    //rm stuff
    { label: "RM Email", key: "rm.email" },
    { label: "RM ID", key: "rm.rm_id" },
    { label: "RM Name", key: "rm.name_en" },
    { label: "RM segment", key: "rm.segment" },

    //fund stuff I guess?
    { label: "NAV Date", key: "nav_date" },
    { label: "NAV Per Unit", key: "nav_per_unit" },
    { label: "RM segment", key: "rm.segment" },

    //status
    { label: "Status", key: "tags.orderStatus.code" },
    //passport stuff
    { label: "Passport Name", key: "customerObj.passportData.fullName" },
    { label: "Passport ID", key: "customerObj.passportData.id" },
    { label: "Passport Expiry", key: "customerObj.passportData.expiryDate" },

    { label: "Civil ID", key: "customerObj.civilID" },
    { label: "Commercial Register", key: "customerObj.commercial_register" },


    { label: "Paid", key: "tags.paid.code" },

    { label: "Address", key: "customerObj.address" },
    { label: "PACI Address", key: "customerObj.address_from_finder.DetailsEnglish" },

    // { label: "Place of Birth", key: "kyc.placeOfBirth" },
    { label: "Country of Birth", key: "kyc.placeOfBirth" },

    { label: "PEP", key: "politicallyExposed" },
    { label: "PEP Reason", key: "pepReason" },
    { label: "High Risk Reason", key: "highRiskReason" },
    { label: "Power of Attorney", key: "powerOfAttorney" },
    { label: "KYC mobile", key: "kyc.mobile" },
    { label: "Voting", key: "voting" },
    { label: "Source of Income", key: "kyc.sourcesOfIncome" },
    { label: "Source of Wealth", key: "kyc.sourcesOfWealth" },
    { label: "Client Classification", key: "clientClassification" },

    //referred by rm stuff
    { label: "Referrer Email", key: "referredBy.email" },
    { label: "Referrer ID", key: "referredBy.rm_id" },
    { label: "Referrer Name", key: "referredBy.name_en" },
  ];


  const [data, setData] = useState<DocumentData[]>([]);

  const handleSubmitGenerateCSV = async (data: FormProps) => {

    //ok get each fund of each status into its separate csv file
    console.log("we query with", data);

    // if ((!data.statuses?.length || !data.funds?.length)) {

    //   throw new Error("Please choose the required status(s) and fund(s)");

    // }
    if ((data.statuses?.length * data.funds?.length) + 1 > 30) {

      throw new Error(`Too many combinations. (${(data.statuses?.length * data.funds?.length) + 1}) Please reduce number of chosen attributes`);

    }
    console.log()

    let q = query(collectionGroup(db, "orders"))
    if (data.statuses?.length > 0) {
      q = query(q, where("tags.orderStatus.code", "in", data.statuses));
    }

    if (data.funds?.length > 0) {
      q = query(q, where("fund.id", "in", data.funds));
    }

    if (userClaims?.availableSegments) { q = query(q, where("rm.segment", "in", userClaims.availableSegments)) }
    else if (userClaims?.segment) { q = query(q, where("rm.segment", "==", userClaims.segment)) }


    const querySnapshot = await getDocs(q);
    const orders: DocumentData[] = [];

    //I need this to run in parallel, so create an array of promises for fetching customers
    const customerPromises = querySnapshot.docs.map(async (document) => {
      let d = document.data() as Order;
      

      let customerID = d.customerObj?.id;
      if (customerID) {
        let customerDocRef = doc(db, "customers", customerID);

        let customerDocPromise = getDoc(customerDocRef); //don't await here

        const kycCollection = collection(customerDocRef, "kyc");
        const kycQuery = query(kycCollection, orderBy("created", "desc"), where("state.status_code", "==", "valid"), limit(1));
        let kycDocPromise = getDocs(kycQuery); // Don't await here

        return Promise.all([customerDocPromise, kycDocPromise]);

      }
      return null;
    });

    // Fetch all customers and their KYCs in parallel
    const customerAndKYCDocs = await Promise.all(customerPromises);

    // Assign fetched customer data and KYC to corresponding orders
    for (let i = 0; i < querySnapshot.docs.length; i++) {
      let d = querySnapshot.docs[i].data();

      d.created = d.created?.toDate().toLocaleString("en-gb");
      d.nav_date = d.nav_date?.toDate().toLocaleDateString("en-gb");
      d.transferDate = d.transferDate?.toDate().toLocaleDateString("en-gb");
      if (!d.referredBy) {
        d.referredBy = d.rm;
      }



      let customerAndKYCDocResult = customerAndKYCDocs[i];

      if (customerAndKYCDocResult) {
        let [customerDoc, kycDocs] = customerAndKYCDocResult;
        let customerData = customerDoc.data();
        let kycData = kycDocs.docs[0]?.data() as KYC | undefined;

        if (customerData) {
          //let's format the address from PACI properly.
          let finderAddress = customerData.address_from_finder as AddressFromFinder;

          //this is the format I want:
          // Block:4, St:443, H:15, 
          // Ishbilya, 
          // Kuwait

          //or
          // Block:1, St:Jamal Abdulnaser, Bld:800049, Apt:. 164, 
          // Shuwaikh industrial(1), 
          // Kuwait

          //or
          // Block:11, St:1, Ave:15, H:47, 
          // Sabah AlSalem, 
          // Kuwait


          if (finderAddress) {
            let address = "";
            if (finderAddress.BlockEnglish) { address += "Block:" + finderAddress.BlockEnglish + ", " }
            if (finderAddress.StreetEnglish) { address += "St:" + finderAddress.StreetEnglish.replace("St ", "") + ", " }
            // if (finderAddress.HouseEnglish) { address +=  (finderAddress.FeatureType == "Unit" ? "Bld" : "H") +":" + finderAddress.HouseEnglish + ", " }
            if (finderAddress.HouseEnglish) { address += "H:" + finderAddress.HouseEnglish + ", " }
            // address += "\n";
            if (finderAddress.NeighborhoodEnglish) { address += finderAddress.NeighborhoodEnglish + ", " }
            // address += "\n";
            address += "Kuwait";
            customerData.address = address;
          }


          d.customerObj = customerData;
        }
        else {
          console.log("Couldn't get customer data for order", d?.id, d.customerObj?.id)
        }

        if (kycData) {
          d.kyc = kycData;
          if (d.kyc?.sourcesOfIncome?.length > 0) {
            d.kyc.sourcesOfIncome = d.kyc.sourcesOfIncome?.join(",")
          }
          if (d.kyc.sourcesofWealth?.length > 0) {
            d.kyc.sourcesofWealth = d.kyc.sourcesofWealth?.join(",")
          }
          if (d.kyc.clientNature?.length > 0) {
            d.clientClassification = d.kyc.clientNature?.join(",")
          }
          //some custom fields for closed ended funds investors template.
          d.powerOfAttorney = kycData.powerOfAttorney ? "Yes" : "No";
          d.politicallyExposed = kycData.politicallyExposed ? "Yes" : "No";
          if (kycData.politicallyExposed) {
            d.pepReason = kycData.politicalPosition;
            if (kycData.relativePoliticalPosition) {
              d.pepReason += " - Relative: " + kycData.relativePoliticalPosition;
            }
          }
          if (kycData.state?.risk_code === "high") {
            d.highRiskReason = kycData.state?.risk;
          }
        }
      }
      orders.push(d);
    }

    console.log(orders);
    setData(orders);
    setDownloadReady(true);
  }
  const form = useForm<FormProps>({

  });

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setMsg(null);
          setOpened(false);
          setConfirming(false)
        }}
        title="Generate CSV"
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            console.log(values);

            setDownloadReady(false);
            setData([]);
            // setConfirmDialogError(null);
            setMsg(null);
            // props?.setPending(true)

            setConfirming(true);
            console.log("yeaaa we are here");

            try {

              await handleSubmitGenerateCSV(values)

              // setIsError(true);
              setMsg({ type: "success", message: "CSV Created Successfully" });
            } catch (error: any) {
              // setIsError(true);
              console.log(error);
              // setMsg(error.message);
              // setMsg({type:"error",msg:});
              setMsg({ type: "error", message: error.message });


              // setConfirmDialogError(error.message);
            }

            setConfirming(false);

          })}
        >

          <Space h="xl" />


          <MultiSelect
            {...form.getInputProps("statuses")}
            label="Select status tags"
            placeholder="All statuses"
            data={
              Object.keys(OrderStatusCode).map(code => {
                return { value: code, label: code };
              })
            }
          />

          <MultiSelect
            {...form.getInputProps("funds")}
            label="Select funds"
            placeholder="All funds"
            data={[
              { value: '902', label: 'USD MMF' },
              { value: '901', label: 'KWD MMF' },
              { value: '500', label: 'Sukuk' },
              { value: '200', label: 'GCC' },
              { value: '201', label: 'Premier' },
              { value: 'Pfn6bBMWKckyFcr2EKPJ', label: 'UK Fund IV' },
              { value: 'usFund', label: 'US Fund VI' },
              { value: 'XY3ENvdw0UEtjQgLeABZ', label: 'US Ijara II' },
              { value: '3006', label: 'UK Fund V' },
              { value: '1008', label: 'US Fund VII' },

            ]}
          />



          <Space h="xl" />
          <Group>
            <Button color="grape" type="submit" loading={confirming}>
              Generate CSV
            </Button>
          </Group>

          <Space h="xl" />

          {msg && (
            <Alert
              icon={<IconAlertCircle size={16} />}
              color={msg.type == "success" ? "green" : "red"}
              title={msg.type == "success" ? "Success" : "Failure"}
            >


              {msg.message}
              <br />
              {downloadReady && <CSVLink data={data} headers={headers}>
                Download CSV
              </CSVLink>
              }

            </Alert>
          )}

        </form>
      </Modal>
    </>
  );
}

export default GenerateCSVModal;