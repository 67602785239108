import { Divider, Grid, Title } from '@mantine/core'
import { Button } from '@mantine/core'
import { PropsWithChildren } from 'react'
import { Campaign } from '../../types'
import { openConfirmModal } from '@mantine/modals'

const CampaignErrors = ({ children, campaign, FailedSum }: PropsWithChildren & {
    campaign: Campaign,
    FailedSum: number
}) => {
    // if (!FailedSum)
    //     return <>

    //         {children}
    //     </>
    return (
        <Button
            // disabled={!FailedSum}
            variant="transparent"
            onClick={() => FailedSum ? openConfirmModal({
                size: "xl",
                labels: { confirm: 'Close', cancel: 'Close' },
                title:
                    <Title order={3}>

                        Campaign Errors
                    </Title>
                ,
                children: <Grid>
                    {campaign?.notification?.errors?.length && <Grid.Col span={{ xs: 12 }}>
                        <Title order={4}>

                            Notifications Errors
                        </Title>
                    </Grid.Col>}
                    {campaign?.notification?.errors?.map((error) =>
                        <Grid.Col span={{ xs: 12 }}>
                            <span style={{ fontWeight: "bold" }}>

                                {error.customer_id} :
                            </span>
                            {error.message}

                        </Grid.Col>
                    )}
                    <Divider />
                    {campaign?.sms?.errors?.length && <Grid.Col span={{ xs: 12 }}>
                        <Title order={4}>
                            sms Errors
                        </Title>
                    </Grid.Col>}
                    {campaign?.sms?.errors?.map((error) =>
                        <Grid.Col span={{ xs: 12 }}>

                            <span style={{ fontWeight: "bold" }}>
                                {error.customer_id} :
                            </span>
                            {error.message}

                        </Grid.Col>
                    )}
                    <Divider />
                    {campaign?.whatsapp?.errors?.length && <Grid.Col span={{ xs: 12 }}>
                        <Title order={4}>
                            whatsapp Errors ({campaign?.whatsapp?.errors?.length})
                        </Title>
                    </Grid.Col>}
                    {campaign?.whatsapp?.errors?.map((error) =>
                        <Grid.Col span={{ xs: 12 }}>

                            <span style={{ fontWeight: "bold" }}>
                                {error.customer_id} :
                            </span>
                            {error.message}

                        </Grid.Col>
                    )}
                    <Divider />
                    {campaign?.email?.errors?.length && <Grid.Col span={{ xs: 12 }}>
                        <Title order={4}>
                            email Errors
                        </Title>
                    </Grid.Col>}
                    {campaign?.email?.errors?.map((error) =>
                        <Grid.Col span={{ xs: 12 }}>

                            <span style={{ fontWeight: "bold" }}>
                                {error.customer_id} :
                            </span>
                            {error.message}

                        </Grid.Col>
                    )}
                    <Divider />
                    {campaign?.custom_email?.errors?.length && <Grid.Col span={{ xs: 12 }}>
                        <Title order={4}>
                            Custom Email Errors
                        </Title>
                    </Grid.Col>}
                    {campaign?.custom_email?.errors?.map((error) =>
                        <Grid.Col span={{ xs: 12 }}>

                            <span style={{ fontWeight: "bold" }}>
                                {error.customer_id} :
                            </span>
                            {error.message}

                        </Grid.Col>
                    )}
                </Grid>,
                cancelProps: {

                    // display: "none"
                },
                confirmProps: {
                    display: "none"
                }
            }) : null}
        >

            {children}
        </Button>
    )
}

export default CampaignErrors