/* eslint-disable eqeqeq */

import { useContext, useState } from "react";
import { useForm } from "@mantine/form";
import { CSVLink } from "react-csv";

import {
  Alert,

  Group,
  Modal,
  Select,
  Space,
} from "@mantine/core";

import { Button } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

import { DocumentData, collectionGroup, getDoc, getDocs, doc, getFirestore, query, where, Timestamp } from "firebase/firestore";

// import _ from "underscore";



import { NotificationMSG, } from "../types";
import { GlobalDataContext } from "../contexts";


type FormProps = {
  fund: string;

};


const GenerateConfirmedOrdersCSVModal = (props: any) => {

  const dataContext = useContext(GlobalDataContext);
  const funds = dataContext.funds;
  const opened: boolean = props.opened;
  const setOpened = props.setOpened;
  const [confirming, setConfirming] = useState<boolean>(false);
  const [msg, setMsg] = useState<NotificationMSG | null>(null);
  const [downloadReady, setDownloadReady] = useState<boolean>(false);

  const db = getFirestore();

  const headers = [
    //personal info stuff
    { label: "Customer ID", key: "customer.id" },
    { label: "Customer Name", key: "customer.name_en" },
    { label: "Customer Area", key: "customer.area" },
    { label: "Customer Gender", key: "customer.gender" },
    { label: "Customer Nationality", key: "customer.nationality" },
    { label: "Customer Type", key: "customer.type" },
    { label: "Type (subscription/redemption/transfer)", key: "type" },
    { label: "Date", key: "deal_date" },
    { label: "Fund Name", key: "fund" },
    { label: "Amount", key: "amount" },
    { label: "Units", key: "bunits" },
    { label: "Segment", key: "segment" },
    { label: "Rm ID", key: "rm.rm_id" },
    { label: "Rm Email", key: "rm.email" },
    { label: "Rm Name (AR)", key: "rm.name_ar" },
    { label: "Rm Name (EN)", key: "rm.name_en" },

  ];


  const [data, setData] = useState<DocumentData[]>([]);

  const handleSubmitGenerateCSV = async (data: FormProps) => {
    let q = query(collectionGroup(db, "fund_data"))
    q = query(q, where("gl_code", "==", data.fund));
    const fundsDataSnapshot = await getDocs(q);
    const orders: DocumentData[] = [];
    const customerDataPromises = fundsDataSnapshot.docs.map(async (document) => {

      let customerID = document.ref.parent.parent?.id;
      if (customerID) {
        let customerDocRef = doc(db, "customers", customerID);

        let customerDocPromise = getDoc(customerDocRef); //don't await here

        return Promise.all([customerDocPromise]);

      }
      return null;
    });

    // Fetch all customers 
    const customerDocs = await Promise.all(customerDataPromises);

    // Assign fetched customer data to corresponding order transactions
    for (let i = 0; i < fundsDataSnapshot.docs.length; i++) {
      let d = fundsDataSnapshot.docs[i].data();
      const transactions = Object.values(d.transactions);
      let customerCDocResult = customerDocs[i];
      if (customerCDocResult) {
        let [customerDoc,] = customerCDocResult;
        let customerData = customerDoc.data();
        if (customerData) {
          delete d["transactions"];
          if (transactions?.length) {
            transactions?.map((transaction: any) => {
              return orders.push({
                ...d,
                ...transaction,
                "customer": {
                  "id": customerData?.id,
                  "name_en": customerData?.name_en,
                  "nationality": customerData?.nationality,
                  "gender": customerData?.gender,
                  "type": (customerData?.commercial_register && customerData?.commercial_register != 12) ? "corporate" : "individual",
                  "area": customerData?.address_from_finder?.NeighborhoodEnglish,
                },
                "rm": {
                  ...customerData?.rm,
                  email: customerData?.rm_email
                },
                "segment": customerData?.segment,
                "deal_date": (transaction?.deal_date as Timestamp)?.toDate()?.toLocaleDateString(),
                "updated_at": (transaction?.updated_at as Timestamp)?.toDate()?.toLocaleDateString(),
                "fund": (funds?.find((fundObj) => fundObj.id === data.fund)?.name_en),
                "amount": (parseFloat(transaction.amount))
              })
            })

          }
        }
      }
    }

    console.log("orders =====>", orders);

    setData(orders);
    setDownloadReady(true);
  }
  const form = useForm<FormProps>({

  });

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setMsg(null);
          setOpened(false);
          setConfirming(false)
        }}
        title="Generate CSV"
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            console.log(values);

            setDownloadReady(false);
            setData([]);
            setMsg(null);
            setConfirming(true);


            try {
              await handleSubmitGenerateCSV(values)
              setMsg({ type: "success", message: "CSV Created Successfully" });
            } catch (error: any) {
              console.log(error);

              setMsg({ type: "error", message: error.message });
            }

            setConfirming(false);

          })}
        >

          <Space h="xl" />



          {funds?.length && <Select
            {...form.getInputProps("fund")}
            label="Select fund"
            placeholder="choose fund"
            data={
              funds?.filter((fundObj) => fundObj?.id)?.map((fundObj) => {
                return {
                  ...fundObj,
                  label: fundObj?.name_en || fundObj?.name || "----",
                  value: fundObj?.id,

                }
              })

            }
          />}



          <Space h="xl" />
          <Group>
            <Button color="grape" type="submit" loading={confirming}>
              Generate CSV
            </Button>
          </Group>

          <Space h="xl" />

          {msg && (
            <Alert
              icon={<IconAlertCircle size={16} />}
              color={msg.type == "success" ? "green" : "red"}
              title={msg.type == "success" ? "Success" : "Failure"}
            >


              {msg.message}
              <br />
              {downloadReady && <CSVLink data={data} headers={headers}>
                Download CSV
              </CSVLink>
              }

            </Alert>
          )}

        </form>
      </Modal>
    </>
  );
}

export default GenerateConfirmedOrdersCSVModal;