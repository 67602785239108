
import { Group, Button, Alert, Space, Grid } from '@mantine/core';
import { BaseResult, ChangeOrderRmRequest, RMUser } from '../types';
import RMAutocomplete from './rm_autocomplete';
import { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { RmsSelectComponent } from './rms_select_component';
import { useFunctions } from '../helpers/firebaseContext';

interface ChangeRmModalContentProps {
    onClose: () => void;
    orderId: string,
    type?: "primary" | "referred",
}

function ChangeRmModalContent({ onClose, orderId, type = "primary" }: ChangeRmModalContentProps) {
    const functions = useFunctions();
    functions.region = "europe-west1";
    const changeOrderRM = httpsCallable<ChangeOrderRmRequest, BaseResult>(functions, "changeOrderRM");
    const [selectedRM, setSelectedRM] = useState<RMUser | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");

    return (
        <>


            <RmsSelectComponent



                defaultValue={selectedRM?.id}
                // ref={civilIDRef}
                // onChange={setBehalfOfRM}
                textProps={{
                    // label: "Civil ID or Commercial Registry",
                    placeholder: "search RM by name or email",
                    // disabled: pending || !onBehalfOfChecked

                }}
                handleSubmit={(selectedRM: RMUser) => {
                    setSelectedRM(selectedRM)
                }}

            />

            <Space h="lg" />
            <Grid >
                <Grid.Col span={6}>

                    <Button fullWidth mt={5} type="button"
                        variant='outline'
                        disabled={selectedRM == null}
                        loading={loading}
                        onClick={async () => {
                            try {
                                setLoading(true);
                                let res = await changeOrderRM({
                                    orderId: orderId,
                                    rmEmail: selectedRM?.email,
                                    type: type,
                                });
                                setLoading(false);
                                if (res.data.status === "success") {
                                    onClose();
                                } else {
                                    setErrorMsg(res.data.message ?? "Something went wrong");
                                }
                            } catch (error: any) {
                                setErrorMsg(error ?? "Something went wrong");
                                setLoading(false);
                            }
                        }}
                    >
                        Submit
                    </Button>
                </Grid.Col>
                <Grid.Col span={6}>

                    <Button fullWidth mt={5}
                        disabled={loading}
                        // loading={loading}

                        onClick={() => { onClose() }}
                        variant='outline'
                        color={"red"}
                    >
                        Cancel
                    </Button>

                </Grid.Col>

            </Grid>
            {
                errorMsg !== "" &&
                <>
                    <br />
                    <Alert color={"red"}>
                        {errorMsg}
                    </Alert>
                </>
            }
        </>
    );
}

export default ChangeRmModalContent;