import { useContext, useEffect, useMemo, useState } from "react";
import {
  Text,
  Alert,
  Box,
  Button,
  Card,
  Group,
  SegmentedControl,
  Space,
  Stack,
  TextInput,
  Title,
  NumberInput,
  Container,
  Checkbox,
  Select,
  Grid,
  Image,
  Divider,
  NumberFormatter,
  Loader,
} from "@mantine/core";
import { UseFormReturnType, useForm } from "@mantine/form";
import { httpsCallable } from "firebase/functions";
import { IconAlertCircle, } from "@tabler/icons-react";


import BigNumber from "bignumber.js";

import { isValidIBAN } from "ibantools";

import { getKFHAccountNo, generateIBANFromKFHAccountNo, civilValid } from '../utils';


import { useDebouncedState } from "@mantine/hooks";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import {

  AppConfigContext,
  ConfigContext,
  MyUserDataContext,
} from "../contexts";


import CustomModal from "../components/custom_modal";
import RequestKYCUpdate from "./requestKYCUpdate";

import CustomerBankAccountsSelect from "../components/CustomerBankAccountsSelect";
import { CurrencyInput } from "../components/currency_input";
import { Customer, Fund, SubscriptionType, FundAutoComplete, UpdateLink, CustomAutocomplete, AddOrderRequest, UploadFileResult, RMUser, CustomClaims, CustomFileWithPath, SubscriptionTier, FundID, Order, AddOrderResult, BaseResult, SendOrderSMSRequest } from "../types";
import { CustomerSelectComponent } from "../components/customer_select_component";

import { FileWithPath } from "@mantine/dropzone";
import BankAccountInfo from "./BankAccountInfo";
import { useNavigate } from "react-router-dom";
import { toTimestamp } from "../helpers/common";
import { RmsSelectComponent } from "../components/rms_select_component";
import { useFirestore, useFunctions } from "../helpers/firebaseContext";
import { animated, to, useSprings } from '@react-spring/web'
import { MessagesSent, refreshWhatsappTemplates } from "../components/send_sms_modal";


export const transformFundsToGroupedData = (funds: FundAutoComplete[]) => {
  // Group funds by currency or any other property
  const grouped = funds.reduce((acc, fund) => {
    const groupName = fund.currency; // Group by currency
    if (!acc[groupName]) {
      acc[groupName] = { group: groupName, items: [] };
    }
    // Add the fund to the appropriate group
    acc[groupName].items.push({ value: fund.id, label: fund.name_en }); // Adjust label as needed
    return acc;
  }, {} as any);

  // Convert the grouped object back into an array
  return Object.values(grouped) as any;
}
const RotatingFallingImage = ({ src, count = 15 }: { src: string, count?: number }) => {
  const items = useMemo(() => new Array(count).fill(null).map((_, index) => ({
    key: index,
    x: Math.random() * 100,
    y: -Math.random() * 100,
    rotation: Math.random() * 360,
  })), [count]);

  const springs = useSprings(
    count,
    items.map((item) => ({
      from: { y: -100, rotation: 0, x: item.x, scaleY: 1 },
      to: async (next: any) => {
        while (true) {
          let duration = 3000 + Math.random() * 2000;

          await Promise.all([
            next({
              y: window.innerHeight,
              rotation: 360,
              x: item.x + (Math.random() - 0.5) * 20, // Random horizontal movement
              config: { duration: duration }
            }),
            (async () => {
              let speed = 500 + (500 * Math.random());
              //make sure that the number of flips matches the duration variable
              let flips = Math.floor(duration / speed);
              for (let i = 0; i < flips; i++) {
                await next({
                  scaleY: -1,
                  config: { duration: speed }
                });
                await next({
                  scaleY: 1,
                  config: { duration: speed }
                });
              }
            })()
          ])
          // Multiple flips during fall

          await next({ y: -100, rotation: 0, x: Math.random() * 100, scaleY: 1, config: { duration: 0 } });
        }
      },
    }))
  );
  return (
    <div style={{ position: 'relative', width: '100%', height: '100%', pointerEvents: 'none', overflow: 'visible' }}>
      {springs.map(({ y, rotation, x, scaleY }, index) => (
        <animated.img
          key={items[index].key}
          src={src}
          style={{
            position: 'absolute',
            left: x.to(x => `${x}%`),
            top: y.to(y => `${y}px`),
            transform: to([rotation, scaleY], (r, s) => `rotate(${r}deg) scaleY(${s})`),
            height: '50px',
            opacity: 0.5, // Add some transparency
          }}
        />
      ))}
    </div>
  );
};



const AddOrder = (props: any) => {

  const userContext = useContext(MyUserDataContext);
  const appConfigContext = useContext(AppConfigContext);
  const config = useContext(ConfigContext);
  let userClaims = userContext.firebaseTokenResult?.claims.customClaims as CustomClaims || {};


  // const civilIDRef = useRef<any>();

  // console.log(configContext);

  // const [RM, setRM] = useState<string>(appContext.user?.displayName || "");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [segment, setSegment] = useState<string>("");

  const functions = useFunctions();

  const [pending, setPending] = useState(false);

  const [msg, setMsg] = useState(null as any);
  const [SMSDocID, setSMSDocID] = useState<string>();
  const [createdOrder, setCreatedOrder] = useState<Order>();

  const [isError, setIsError] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showSendOrderButton, setShowSendOrderButton] = useState(true);
  const [showCommitmentButton, setShowCommitmentButton] = useState(true);


  const [clearFiles, setClearFiles] = useState<any>(false);
  const [customerID, setCustomerID] = useState<string | null>(null);
  const [useWhatsapp, setUseWhatsapp] = useState<boolean>();
  const [whastappTemplateLoading, setWhastappTemplateLoading] = useState<boolean>(true);
  // const [ID, setID] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customerObj, setCustomerObj] = useDebouncedState<Customer | undefined>(undefined, 200)
  // const [behalfOfRM, setBehalfOfRM] = useDebouncedState("", 200);

  // const [action, setAction] = useState<AddOrderActions>('send_order_sms');  //this is to determine which button was pressed.  (save, send)
  const navigate = useNavigate();


  const db = useFirestore();

  // const [autocompleteData, setAutocompleteData] = useState([] as any);
  // const [RMAutocompleteData, setRMAutocompleteData] = useState<RMUser[]>([]);
  // const [RMAutocompleteLoading, setRMAutocompleteLoading] = useState(false);
  const [addClientModal, setAddClientModal] = useState(false);
  const [KYCUrl, setKYCUrl] = useState("");
  // const [reloadCivilData, setReloadCivilData] = useState<Number | undefined>();


  const [funds, setFunds] = useState([] as FundAutoComplete[]);
  // const [fundName, setFundName] = useState<any>("");
  // const [orderType, setOrderType] = useState<SubscriptionType >("subscription");

  const [onBehalfOfChecked, setOnBehalfOfChecked] = useState(false);

  // const [searchParams, setSearchParams] = useSearchParams();
  // const civilIDParam = searchParams.get("civilID")
  const [customerData, setCustomerData] = useState<Customer[]>([]);

  //This needs to change into typesense search instead.
  const [customerCivilID, setCustomerCivilID] = useState<string>()
  const [customerCivilIDInputError, setCustomerCivilIDInputError] = useState<string | undefined>()
  const [customerOrders, setCustomerOrders] = useState<Order[]>([]);
  const [selectedFund, setSelectedFund] = useState<Fund | undefined>();
  const [subscriptionType, setSubscriptionType] = useState<SubscriptionType>("subscription");
  const [manualChecked, setManualChecked] = useState(false);
  const [votingChecked, setVotingChecked] = useState(false);
  const [toBeUpdatedCustomer, setToBeUpdatedCustomer] = useState<Customer | undefined>(undefined)
  const [currentRM, setCurrentRM] = useState<RMUser | undefined>(undefined)
  const [selectedRM, setSelectedRM] = useState<RMUser | undefined>(undefined)








  /*
    useEffect(() => {
      appContext.microsoftClient
        ?.api("/me/memberOf")
        .get()
        .then((res: ODataResponse<MicrosoftGraph.Group>) => {
          console.log(res);
          for (let group of res.value) {
  
            if (group.id! in configContext!.segment_groups!) {
              setSegment(configContext!.segment_groups![group.id!]);
              break;
            }
          }
        });
    }, [appContext.microsoftClient, configContext]);
  */

  /*

    //This used to be for searching on Microsoft Azure Active Directory, but circumstances have changed and KFH are damn slow to adopt.
    //Therefore, we'll just use firestore collection of rms.
    //Previously, this would get the user and their groups.  From there, we can determine what segment they belong to.
    //I still like the idea.  I hope we get to use it someday.


  //TODO:  This will change when it's eventually used by KFH
  let kfhcapitalGroup = "07adab0d-322f-4bf0-907a-5911cf746d99";

  //search for RM
  useEffect(() => {
    if (behalfOfRM.length <= 1) return;
    setRMAutocompleteLoading(true);

    
    appContext.microsoftClient
      ?.api(`/groups/${kfhcapitalGroup}/members`)
      .header("ConsistencyLevel", "eventual")
      // .expand("memberOf")
      // .select('displayName,jobTitle,memberOf,mail')
      // .filter('endsWith(mail,\'kfhcapital.com.kw\')')
      .search(`"displayName:${behalfOfRM}" OR "mail:${behalfOfRM}"`)
      .top(10)
      .get()
      .then((userRes) => {
        console.log(userRes.value);

        let batchSteps: BatchRequestStep[] = [];

        let userResults: MicrosoftUserAutoComplete[] = userRes.value;

        for (let user of userResults) {

          // user.value = user.displayName;
          batchSteps.push({
            request: new Request(`/users/${user.id}/memberOf`),
            id: user.id!,
          });
        }

        setRMAutocompleteData(userResults);

        // Create the batch request content with the steps created
        // above
        let batchRequestContent = new BatchRequestContent(batchSteps);

        batchRequestContent.getContent().then((content) => {
          console.log(content);
          appContext.microsoftClient
            ?.api("/$batch")

            .post(content)
            .then((batchResponse) => {
              let batchResponseContent = new BatchResponseContent(
                batchResponse
              );

              let promises: Promise<any>[] = [];

              for (let user of userResults) {
                promises.push(
                  batchResponseContent.getResponseById(user.id!).json()
                );
              }

              Promise.all(promises).then((groupResults: MicrosoftGraph.DirectoryObject[]) => {
                console.log("Group results: ", groupResults);

                for (let i = 0; i < groupResults.length; i++) {
                  userResults[i].memberOf = groupResults;
                }
                // user.groups = results;
                // console.log(results);
                // console.log(res.value);
                setRMAutocompleteData(userResults);
                setRMAutocompleteLoading(false);
              });
            });
        });
      });

      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [behalfOfRM, appContext.microsoftClient, configContext]);
*/
  //get funds
  useEffect(() => {
    // check if a civil id is passed in url query params 
    // if (civilIDParam && civilValid(civilIDParam)) {
    //   setID(civilIDParam)
    // }
    //get current funds with active == true

    const fundCollection = collection(db, "funds");
    const q = query(fundCollection, where("active", "==", true));

    return onSnapshot(q, (snapshot) => {
      if (snapshot.metadata.fromCache) {
        console.log("Cached data", snapshot.docs.length);
        // return;
      } else {
        console.log("Server data", snapshot.docs.length);
      }

      // let dict = {} as any;
      let fundArray = [] as FundAutoComplete[];

      snapshot.docs.forEach((doc) => {
        var data = doc.data() as FundAutoComplete;

        //if the fund is internal only, don't show it to non-RM users
        if (data.fund_state && data.fund_state === "internal_only" && (userClaims["kfh_rm"] || userClaims["kfh_oper"])) return;

        //TODO: for now, only return funds with ibans available (open ended).  Later, we need to have a flag for the type of fund
        if (!data.iban) return;
        data["id"] = doc.id as FundID;
        data["value"] = doc.id
        data["label"] = `${data.name_en} - ${data.name_ar}`

        fundArray.push(data);
        // return data;
      });
      // console.log("funds", rows);
      setFunds(fundArray);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  //get RMs
  useEffect(() => {
    // check if a civil id is passed in url query params 
    // if (civilIDParam && civilValid(civilIDParam)) {
    //   setID(civilIDParam)
    // }
    //get current funds with active == true
    if (!userContext.user?.email) {
      return;
    }

    const rmDoc = doc(db, "rms", userContext.user.email.toLowerCase());
    // let q = query(rmCollection, where("active", "==", true));

    //is it a KFH RM or operator?  If so, limit it to the segment
    // if (userClaims["kfh_rm"] || userClaims["kfh_oper"]) {
    //   q = query(q, where("segment", "==", userClaims.segment));  //TODO: we need the RM segment asap!
    // }


    return onSnapshot(rmDoc, (snapshot) => {
      if (snapshot.metadata.fromCache) {
        console.log("RM Cached data", snapshot.exists());
        // return;
      } else {
        console.log("RM Server data", snapshot.exists());
      }

      // let dict = {} as any;
      let rm: RMUser | undefined = undefined;

      if (snapshot.exists()) {
        rm = snapshot.data() as RMUser;
      }



      setCurrentRM(rm);
      setSelectedRM(rm);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.user]);


  // const { status, data: idSearch } = useFirestoreCollectionData(customerQuery, {
  //     idField: 'id', // this field will be added to the object created from each document
  // });

  // console.log(idSearch);

  // let fund = funds[fundName];
  const form: UseFormReturnType<AddOrderRequest> = useForm<AddOrderRequest>({
    initialValues: {
      maxUnits: false,
      onBehalfOf: undefined,
      updateLink: {
        senderID: "KFHC-FUNDS"
      } as UpdateLink,
      slipFilePaths: [],
      action: "send_order_sms",
      order: {
        customerObj: {} as Customer,
        acct: "",
        iban: "",
        fund: {} as Fund,
        type: "subscription",
        units: undefined,
        estimated_units: undefined,
        transferAmount: 0,
        // token: null as any, //used for getting submitter information and onBehalfOf user info if needed
        // slipFilePaths: null as string | null,
      },
    },

    validate: {
      // name_en: (value) =>
      //   value.length < 2 ? "Name must have at least 2 letters" : null,
      // name_ar: (value) =>
      //   value.length < 2 ? "Name must have at least 2 letters" : null,
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      // mobile: (value) => (value.length !== 8 ? "Incorrect mobile" : null),
      order: {
        fund: (value) => {
          return (!value || !Object.keys(value).length)
            ? "Invalid fund"
            : null;
        },
        customerObj: (value) => (!value ? "Invalid customer" : null),
        type: (value) => {
          return !ORDER_TYPES.filter((e) => e.value === value).length
            ? "Choose subscription/redemption"
            : null;
        },
        transferAmount: (value, values) => {
          //is it a string?  if so, throw an error
          if (!value && values.order.type === "subscription") return "Please enter an amount";
          if (typeof value === "string") return "Please enter a number or decimal";
          return ((!form.values.order.maxUnits) && (value || 0) <= 0) ? "Invalid amount" : null;

        },
        units: (value, values) => {

          if (!Number.isInteger(value)) {
            return "Invalid units";
          }


          if (values.order.type === "redemption") return null;


          if (!form.values.order.maxUnits && !form.values.order.voting) {
            if (form.values.order.fund.min_units && value! < (form.values.order.fund.min_units)) {
              return `Units must be greater than ${form.values.order.fund.min_units}`;
            }


            if (form.values.order.fund.multiples_of_units && value! % (form.values.order.fund.multiples_of_units) !== 0) {
              return `Units must be multiples of ${form.values.order.fund.multiples_of_units}`;
            }
          }
        },
        iban: (value, values) => {
          console.log("IBAN value", value);
          //there's no IBAN?  probably means he's going to add it later.
          if (values.order.type === "subscription" && !manualChecked) {
            if (!value || value.length === 0) return null;
          }
          if (values.order.type === "subscription" || values.order.type === "redemption") {
            if (!value || value.length < 3) return "Please fill out the IBAN"
            if (!isValidIBAN(value)) return "Invalid IBAN"
          }

          return null;
        },
      }
    },
  });

  useEffect(() => {
    let ID = customerID;


    if (ID) {
      console.log("We got ID", ID);

      //find customer from ID
      let customer = customerData?.find((customer: any) => customer.id === ID);

      console.log("We got customer", customer);

      if (customer) {
        setCustomerObj(customer);
        if (customer.civilID && (!customer.gender || !customer.nationality)) {
          setToBeUpdatedCustomer(customer);
          setAddClientModal(true);
        } else {
          setToBeUpdatedCustomer(undefined);
        }
      } else {
        setToBeUpdatedCustomer(undefined);
      }
      form.setFieldValue("order.customerObj", customer);
      form.setFieldValue("order.type", "subscription");
    }
    else {
      setCustomerObj(undefined);
      setToBeUpdatedCustomer(undefined);
      form.setFieldValue("order.customerObj", undefined);
      form.setFieldValue("order.type", "subscription");

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerID]);

  const getCustomerData = async (civilId: string) => {

    const customerSnapshot = await getDocs(query(collection(db, 'customers'), where('civilID', '==', civilId), limit(1)));
    const customerData = customerSnapshot.docs[0]?.data() as Customer;
    // await setCustomerID(civilId)
    console.log("customerData", customerData);
    if (customerData) {
      await setCustomerData([customerData])
      await setCustomerObj(customerData);
      if (customerData.civilID && (!customerData.gender || !customerData.nationality)) {
        setToBeUpdatedCustomer(customerData);
        form.setFieldValue("order.customerObj", customerData);
        setAddClientModal(true);
      } else {
        form.setFieldValue("order.customerObj", customerData);
        setToBeUpdatedCustomer(undefined);
        setAddClientModal(false);
      }
      return true;
    }
    else {
      setToBeUpdatedCustomer(undefined);
      setAddClientModal(true);
      return false;
    }
  }


  const getCustomerOrders = async (id: string) => {
    const ordersQuery = collection(
      db,
      "customers",
      id,
      "orders"
    );
    let ordersSnapshots = await getDocs(ordersQuery);
    let orders: Order[] = [];
    ordersSnapshots.forEach((order) => {
      orders.push(order.data() as Order);
    });
    setCustomerOrders(orders);

  }

  useEffect(() => {
    if (customerCivilID && customerCivilID.length === 12) {
      if (civilValid(customerCivilID)) {
        getCustomerData(customerCivilID)
        getCustomerOrders(customerCivilID)
      } else {
        setCustomerObj(undefined);
        form.setFieldValue("order.customerObj", undefined);
        setCustomerCivilIDInputError("Invalid Civil ID");
      }
    }
    else {
      setCustomerObj(undefined);
      form.setFieldValue("order.customerObj", undefined);
      setCustomerCivilIDInputError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerCivilID])


  const customerAccountsMemo = useMemo(() =>

    <CustomerBankAccountsSelect form={form} customerObj={form.values.order.customerObj} disabled={pending} />

    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [form.values.order.customerObj, form.values.order.type, pending])

  let fees_percent =
    form.values.order.type === "subscription"
      ? form.values.order.fund?.subscription_fees_percent
      : 0;

  let calc = (units?: number, amount?: number, fees_percent?: number, overhead_percent?: number): { amt: number, fees: number, overhead: number } | undefined => {

    let nav = form.values.order.fund?.nav_per_unit || 0;

    fees_percent = fees_percent || 0;
    overhead_percent = overhead_percent || 0;
    if (form.values.order.type === "redemption") {
      //no overhead for redeem
      overhead_percent = 0;
    }

    if (units) {
      let amt = nav * units;
      let fees = (amt * fees_percent) / 100;
      let overhead = (amt * overhead_percent) / 100;

      amt = amt + fees + overhead;
      // console.trace(fees_percent, overhead_percent, amt, fees, overhead)
      return { amt, fees, overhead };
      // return amt + fees + overhead;
    } else if (amount) {
      let fees = (amount * fees_percent) / 100;
      let overhead = (amount * overhead_percent) / 100;
      let amt = (amount - fees - overhead) / nav;
      amt = Math.floor(amt);

      return { amt, fees, overhead };
      // return (amount - fees - overhead) / nav;
    }

    return;

  }
  /*
  let calcUnitsOrAmount = (
    units?: number,
    amount?: number,
    fees_percent?: number,
    overhead_percent?: number
  ): number => {
    let nav = form.values.order.fund?.nav_per_unit || 0;

    fees_percent = fees_percent || 0;
    overhead_percent = overhead_percent || 0;
    if (form.values.order.type === "redemption") {
      //no overhead for redeem
      overhead_percent = 0;
    }

    if (units) {
      let amt = nav * units;
      let fees = (amt * fees_percent) / 100;
      let overhead = (amt * overhead_percent) / 100;

      // console.trace(fees_percent, overhead_percent, amt, fees, overhead)
      return amt + fees + overhead;
    } else if (amount) {
      let fees = (amount * fees_percent) / 100;
      let overhead = (amount * overhead_percent) / 100;
      return (amount - fees - overhead) / nav;
    }

    return 0;
  };
*/
  // console.log("Reloading!", form.getInputProps("fund").value);

  // let recalcAmount = (validateFieldFirst?: string) => {
  //   // if (form.values.fund) {
  //   console.log("Recalculating", "units", form.values.order.units, "amount", form.values.order.transferAmount, "validateFieldFirst", validateFieldFirst);
  //   // console.log("eeeeeee =====>>>", e);

  //   const isSubscription = form.values.order.type === "subscription";

  //   if (validateFieldFirst) {
  //     const result = form.validateField(validateFieldFirst);
  //     console.log("Validation result:", result);
  //     if (result.hasError) return;
  //   }
  //   // let fund = funds[fundName];
  //   let fees_percent = isSubscription ? form.values.order.fund?.subscription_fees_percent ?? 0 : 0;
  //   let nav = isSubscription ? form.values.order.fund?.nav_per_unit ?? 0 : 0;

  //   //make sure it's multiples of multiples_of_units
  //   let num = form.values.order.units;
  //   let multiples_of_units = form.values.order.fund?.multiples_of_units ?? 1;
  //   let newNum = Math.floor(num / multiples_of_units) * multiples_of_units;

  //   // let surplus = 

  //   //set priority on who changes based on who.

  //   if (isSubscription) {
  //     // newTransferAmount = newTransferAmount + (newTransferAmount * (fees_percent / 100));
  //     form.setFieldValue("order.units", newNum);
  //     // form.setFieldValue(
  //     //   "order.transferAmount",
  //     //   calcUnitsOrAmount(
  //     //     newNum,
  //     //     undefined,
  //     //     fees_percent,
  //     //     form.values.order.fund?.overhead_percent
  //     //   ) || 0
  //     // );
  //   }
  //   else {

  //     form.setFieldValue("order.units", newNum);
  //     form.setFieldValue(
  //       "order.transferAmount",
  //       calc(
  //         newNum,
  //         undefined,
  //         fees_percent,
  //         form.values.order.fund?.overhead_percent
  //       )?.amt || 0
  //     );
  //   }


  // };

  const calcFeesBasedOnTiers = (units: number, tiers: SubscriptionTier[]) => {
    let amount = calc(units, undefined)?.amt || 0;
    return amount * ((tiers.find(t => amount >= t.from && amount <= t.to)?.fees || 0) / 100);
  }

  let surplusCalc = new BigNumber(form.values.order.fund?.nav_per_unit || 0).times(form.values.order.units ?? (form.values.order.estimated_units ?? 0))
  console.log("Calculated amount is ", surplusCalc.toNumber());
  surplusCalc = surplusCalc.minus(form.values.order.transferAmount || 0);

  let feesCalc = calc(form.values.order.units, undefined, fees_percent, form.values.order.fund?.overhead_percent);

  if (feesCalc) {
    surplusCalc = surplusCalc.minus(feesCalc.fees).minus(feesCalc.overhead);
  }

  // let surplus = surplusCalc.toNumber();
  console.log("Surplus is", surplusCalc.toNumber(), "fees", feesCalc?.fees, "overhead", feesCalc?.overhead, "amount", form.values.order.transferAmount);


  const ORDER_TYPES = [
    { label: "Subscribe", value: "subscription" },
    { label: "Redeem", value: "redemption", disabled: form.values.order.fund?.type === "closed_ended" },
  ];

  let fees: number | undefined;

  if (form.values.order.type === "subscription") {
    if (form.values.order.fund.subscription_fees_percent) {
      fees = (calc(form.values.order.units, undefined)?.amt || 0) * (form.values.order.fund?.subscription_fees_percent / 100);
    }
    else if (form.values.order.fund.subscription_tiers) {
      fees = calcFeesBasedOnTiers(form.values.order.units ?? (form.values.order.estimated_units ?? 0), form.values.order.fund.subscription_tiers);
    }
  }



  useEffect(() => {
    const getData = async () => {

      await refreshWhatsappTemplates(true, form, appConfigContext.apiUrl!, () => { }, false, setWhastappTemplateLoading,
        config?.orderTemplates?.whatsappTemplate, "updateLink.selectedWhatsappTemplate",
      )

    }
    if (useWhatsapp) { getData() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useWhatsapp])

  // let showSendOrderButton = true;
  // let showCommitmentButton = true;




  useEffect(() => {
    //only allow button to be shown when fund is ready for investors
    if (userClaims["kfh_rm"] || userClaims["kfh_oper"]) {
      //backwards compatibility.  If the fund_state is not set, show the button anyway by default.
      if (selectedFund?.fund_state) {
        if (selectedFund?.fund_state === "ready_for_investors") {
          setShowSendOrderButton(true)
        }
        else {
          setShowSendOrderButton(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFund])


  return (
    <Stack>
      <Title>Add an order</Title>
      <Card shadow="sm" p="xl" radius="md" withBorder>
        <Container>
          <form
            onSubmit={form.onSubmit(async (formValues) => {


              // Use FormData to retrieve the form values

              console.log("submitting", form.values.action);

              // const values: (Order & UpdateLink) = formValues as (Order & UpdateLink)
              setMsg(null)
              setPending(true);

              let formValuesToBeSent = { ...formValues };  //I'm making a copy because I might modify things here.  I don't want the form to reflect those changes.
              formValuesToBeSent.order = { ...formValues.order };  //same


              try {
                let token = await userContext.getAzureADToken!(userContext);
                // console.log("Token", token);

                if (userContext.user?.providerId === "microsoft.com" && !token) {
                  setIsError(true);
                  console.log("No Microsoft Token");
                  setMsg("No Microsoft Token.  Try refreshing.");
                  return;
                }
                else if (token) {
                  formValuesToBeSent.token = token;
                }

                //generate a doc ID
                const docID = doc(collection(db, "sms")).id;
                setSMSDocID(docID);
                if (formValuesToBeSent.updateLink) {
                  formValuesToBeSent.updateLink.smsDocID = docID;
                }

                formValuesToBeSent.id = customerObj?.id;

                if (!form.values.order.fund.fees_included_in_transferred_amount && formValuesToBeSent.order.transferAmount && fees) {
                  //Ok so the fees are not included in the transferred amount.  We need to add it to the transferred amount.
                  //Usually these are for closed-ended funds, so the calculation for fees is already done.
                  formValuesToBeSent.order.postNavAmount = formValuesToBeSent.order.transferAmount;
                  formValuesToBeSent.order.transferAmount += fees;
                  formValuesToBeSent.order.fees = fees;

                  // console.log(formValuesToBeSent);
                  // return;
                }
                // console.log(values);

                //change the units to estimated units if the order is open ended and do nothing if it close ended
                if (form.values.order.type === "subscription" && form.values.order.fund.fees_included_in_transferred_amount) {
                  formValuesToBeSent.order.estimated_units = formValuesToBeSent.order.units;
                  formValuesToBeSent.order.units = undefined;
                }
                if (useWhatsapp) {

                  // formValuesToBeSent.updateLink!.selectedWhatsappTemplate = undefined;
                  formValuesToBeSent.updateLink!.useWhatsapp = true;
                  // form.setFieldValue("updateLink.selectedWhatsappTemplate", form.values.selectedWhatsappTemplate)

                  // form.setFieldValue("updateLink.useWhatsapp", true)
                }

                //change to call firebase function and calculate the stuff or something

                const addOrder = httpsCallable<AddOrderRequest, AddOrderResult>(functions, "addOrder");


                //set type to submit because we want the user to create an entirely new KYC
                const res = await addOrder(formValuesToBeSent);
                const returnedOrderData = res.data.data;
                if (returnedOrderData) {

                  setCreatedOrder(returnedOrderData as Order);
                }
                console.log(res);
                if (res.data.link) {
                  setKYCUrl(res.data.link);
                }
                form.setFieldValue(
                  "slipFilePaths",
                  [],
                );
                // e.target.reset();
                //the href is absolutely stupid.  I'm reutilizing the sendBatchSMS from firebase functions, so yeah.
                setMsg(
                  <Stack>

                    <Text>{form.values.order.type === "subscription" ? "Subscription" : "Redemption"} order created successfully {" "}

                      <Text span >
                        <a href={`/orders/${res.data.orderId}`}>

                          view
                        </a>
                      </Text>

                    </Text>

                    {/* {orderType === "subscribe" && <Anchor
                      target="_blank"
                      href={res.data.smsResult[0]?.value.link}
                    >
                      Fill KYC on behalf of client
                    </Anchor>} */}


                  </Stack>
                );
                setIsError(false);
                setIsSubmitted(true)
                setShowCommitmentButton(false);
                setShowSendOrderButton(false);
                setClearFiles(Math.random())

                // if (res.data.orderId) {
                //   navigate(`/orders/${res.data.orderId}`);
                // }
                // if (!res.data.link) {
                //   navigate("/orders", {
                //     state: {
                //       refreshOrdersIn: 1000,
                //     }
                //   });
                // }
              } catch (error: any) {
                setIsError(true);
                console.log(error);
                setMsg(error.message);
              }

              setPending(false);
            })}
          >
            <Grid>
              <Grid.Col span={{ xs: 12 }}>

                <Text
                  variant="gradient"
                  gradient={{
                    from: form.errors?.civilID ? "#fa5252" : "indigo",
                    to: form.errors?.civilID ? "#FF6B6B" : "cyan",
                    deg: 45,
                  }}
                  style={{ fontFamily: 'Greycliff CF, sans-serif' }}
                  //   ta="center"
                  fz="xl"
                  fw={700}
                >
                  Customer Information
                </Text>
              </Grid.Col>

              <Grid.Col span={{ xs: 12, lg: 6 }}>


                {
                  !userClaims["kfh_rm"] ?
                    <CustomerSelectComponent
                      setCustomerData={setCustomerData}
                      customerData={customerData as (Customer & CustomAutocomplete)[]}
                      defaultValue={form.values.order.customerObj?.value}
                      // ref={civilIDRef}

                      textProps={{
                        label: "Civil ID or Commercial Registry",
                        placeholder: "Enter Civil ID or Commercial Registry",
                        disabled: pending
                      }}
                      handleSubmit={(customerIDValue) => {
                        console.log("We set ID to", customerIDValue);
                        setCustomerID(customerIDValue as string)
                        if (customerIDValue) {
                          getCustomerOrders(customerIDValue as string);
                        }
                      }}
                    /> :
                    <>
                      <TextInput
                        disabled={pending}

                        label="Civil ID"
                        value={form.values.order.customerObj?.value || customerCivilID}
                        onChange={(event) => {
                          setCustomerCivilID(event.currentTarget.value);

                        }}
                        error={customerCivilIDInputError}
                        onKeyDown={(e) => {
                          // console.log("ON KEY DOWN!!!", e.key);
                          //was it a backspace?
                          if (e.key === "Backspace") {
                            console.log("backspace");
                            setCustomerID(null);

                          }
                        }}


                      />

                    </>
                }
              </Grid.Col>
              <Grid.Col span={{ xs: 12, lg: 6 }}>

                <TextInput
                  // withAsterisk
                  label="Email"
                  // placeholder="their@email.com"
                  disabled
                  value={form.values?.order.customerObj?.email || ""}

                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, lg: 6 }}>

                <TextInput
                  // withAsterisk
                  label="Name (English)"
                  // placeholder="Joe"
                  disabled
                  // {...form.getInputProps("customerObj.name_en")}
                  value={form.values?.order.customerObj?.name_en || ""}

                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, lg: 6 }}>

                <TextInput
                  // withAsterisk
                  label="Name (Arabic)"
                  // placeholder="جو"
                  disabled
                  value={form.values?.order.customerObj?.name_ar || ""}

                // {...form.getInputProps("customerObj.name_ar")}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, lg: 6 }}>
                <TextInput
                  // withAsterisk
                  label="Mobile"
                  // placeholder="xxxxxxxx"
                  disabled
                  value={form.values?.order.customerObj?.mobile || ""}

                // {...form.getInputProps("customerObj.mobile")}
                />
              </Grid.Col >
              <Space h="md" />
            </Grid>
            <Grid>


              <Grid.Col span={{ xs: 24 }}>
                <Space h="xl" />
                <Divider />
              </Grid.Col>

              <Grid.Col span={{ xs: 6 }} >

                <Group gap="xl">
                  <Stack>
                    <Text
                      variant="gradient"
                      gradient={{
                        from: form.errors?.fund ? "#fa5252" : "indigo",
                        to: form.errors?.fund ? "#FF6B6B" : "cyan",
                        deg: 45,
                      }}
                      style={{ fontFamily: 'Greycliff CF, sans-serif' }}
                      //   ta="center"
                      fz="xl"
                      fw={700}
                    >
                      Select Fund
                    </Text>
                    <Select
                      // onChange={setFund}
                      disabled={pending}
                      {...form.getInputProps("order.fund")}
                      miw={400}
                      maxDropdownHeight={400}
                      value={form.values?.order.fund?.id}
                      data={transformFundsToGroupedData(funds)}
                      placeholder="Choose fund type"
                      onChange={(value) => {
                        //get the fund object
                        let fund = funds.find((fund) => fund.id === value);
                        if (!fund) return;
                        if (form.values.order.fund.id !== value) {
                          form.setFieldValue("order.transferAmount", 0);
                          form.setFieldValue("order.units", 0);
                        }
                        form.setFieldValue("order.fund", fund as Fund);
                        setSelectedFund(fund as Fund);
                        // recalcAmount();
                      }}

                    />
                  </Stack>
                  <Stack>
                    <Text
                      variant="gradient"
                      gradient={{
                        from: form.errors?.type ? "#fa5252" : "indigo",
                        to: form.errors?.type ? "#FF6B6B" : "cyan",
                        deg: 45,
                      }}
                      style={{ fontFamily: 'Greycliff CF, sans-serif' }}
                      //   ta="center"
                      fz="xl"
                      fw={700}
                    >
                      Select Type
                    </Text>
                    <SegmentedControl
                      disabled={pending}

                      // value={type}
                      // onChange={setType}
                      {...form.getInputProps("order.type")}
                      color={
                        form.values.order.type === "subscription"
                          ? "green"
                          : "red"
                      }
                      data={ORDER_TYPES}
                      onChange={(type) => {
                        const typeObj = ORDER_TYPES.find((obj) => type === obj.value)
                        form.setFieldValue("order.type", typeObj!.value as SubscriptionType);
                        form.setFieldValue("order.acct", "");
                        form.setFieldValue("order.iban", "");
                        form.setFieldValue("order.transferAmount", 0);
                        form.setFieldValue("order.units", 0);
                        // setOrderType(type);
                        // recalcAmount();

                        setSubscriptionType(typeObj!.value as SubscriptionType);
                      }}
                    />
                  </Stack>


                </Group>
              </Grid.Col >

              <Grid.Col span={{ xs: 6 }}>

                <Card shadow="sm" padding="lg" radius="md" withBorder>
                  {form.values.order.fund?.currency && <RotatingFallingImage src={`/funds/${form.values.order.fund?.currency?.toLowerCase()}.png`} />}

                  {
                    form.values.order.fund.image &&
                    <><Card.Section>
                      <Image
                        src={form.values.order.fund.image}
                        height={100}
                        fit="contain"

                        alt={form.values.order.fund.name_en}
                      />
                    </Card.Section>
                      <Space h="md" />
                    </>

                  }
                  <Group>

                    {form.values.order.fund?.min_units && (
                      <>
                        <Text c="dimmed" size="xs">
                          Min Units:</Text>

                        <Text size="xs"> <NumberFormatter thousandSeparator value={form.values.order.fund?.min_units ?? 0} decimalScale={0} /></Text>
                      </>
                    )}
                    {form.values.order.fund?.nav_per_unit && (
                      <>
                        <Text c="dimmed" size="xs">
                          NAV per unit:                       </Text>
                        <Text size="xs"> <NumberFormatter thousandSeparator value={form.values.order.fund?.nav_per_unit ?? 0} decimalScale={6} suffix={` ${form.values.order.fund?.currency}`} /></Text>
                      </>
                    )}


                  </Group>
                  {form.values.order.fund?.updated_at && (
                    <Text c="dimmed" size="xs">
                      NAV update date: <Text size="xs" c="black" style={{ display: 'inline' }}>
                        {form.values.order.fund?.updated_at?.toDate().toLocaleDateString("en-UK")}
                        {" "}
                        {(() => {
                          const weeksDiff = Math.floor((new Date().getTime() - form.values.order.fund.updated_at.toDate().getTime()) / (7 * 24 * 60 * 60 * 1000));
                          return weeksDiff >= 1 ? `(${weeksDiff} ${weeksDiff === 1 ? 'week' : 'weeks'} ago)` : '';
                        })()}
                      </Text>
                    </Text>
                  )}
                  {form.values.order.fund?.subscription_fees_percent && (
                    <Text c="dimmed" size="xs">
                      Subscription Fees: {form.values.order.fund?.subscription_fees_percent}%
                    </Text>
                  )}
                  {form.values.order.fund?.subscription_tiers && (
                    <Text c="dimmed" size="xs">
                      Subscription Tiers: <Grid m={10}>
                        {
                          form.values.order.fund?.subscription_tiers.map((tier) => <><Grid.Col span={6}><Text>{tier.from}-{tier.to} {form.values.order.fund?.currency}</Text></Grid.Col><Grid.Col span={6}><Text> fees: {tier.fees}%</Text></Grid.Col></>)
                        }
                      </Grid>
                    </Text>
                  )}

                  {form.values.order.fund?.multiples_of_units && (
                    <Text c="dimmed" size="xs">
                      Multiples of {form.values.order.fund?.multiples_of_units} units
                    </Text>
                  )}
                  {form.values.order.fund?.iban && (
                    <Text c="dimmed" size="xs">
                      IBAN: <Text span c="black" style={{ display: 'inline' }}>{form.values.order.fund?.iban}</Text>
                    </Text>
                  )}
                  <Group gap="xl" ta="start" mt={20}>
                    <Text fs="italic" size="xs">
                      Total Amount:{" "}<NumberFormatter thousandSeparator decimalScale={0} value={calc(
                        form.values.order.units,
                        undefined
                      )?.amt.toFixed(3)} suffix={` ${form.values.order.fund?.currency}`} />
                    </Text>
                    {fees && (
                      <Text fs="italic" size="xs">
                        Fees:  <NumberFormatter thousandSeparator value={fees} decimalScale={0} suffix={` ${form.values.order.fund?.currency}`} />
                      </Text>
                    )}


                    {form.values.order.type === "subscription" &&
                      form.values.order.fund?.overhead_percent && (
                        <Text fs="italic" size="xs">
                          Overhead:{" "}
                          {(
                            (calc(
                              form.values.order.units,
                              undefined
                            )?.amt || 0) *
                            (form.values.order.fund?.overhead_percent / 100)
                          ).toFixed(3)}{" "}
                          {form.values.order.fund?.currency}
                        </Text>
                      )}
                  </Group>
                </Card>

              </Grid.Col>

              <Grid.Col span={{ xs: 12 }}>


              </Grid.Col>

              <Space h="xl" />

              <Grid.Col span={{ xs: 24 }}>
                <Space h="xl" />
                <Divider />
              </Grid.Col>
              <Grid.Col span={{ xs: 12 }}>

                {form.values.order.type && <>
                  <Space h="xl" />
                  <Text
                    variant="gradient"
                    gradient={{ from: "indigo", to: "cyan", deg: 45 }}
                    style={{ fontFamily: 'Greycliff CF, sans-serif' }}
                    //   ta="center"
                    fz="xl"
                    fw={700}
                  >
                    Payment Information
                  </Text>
                  <Grid columns={24}>
                    <Grid.Col span={{ xs: 24 }} >
                      <Group>
                        <CurrencyInput
                          // defaultValue={18}
                          // placeholder={`Amount in ${fund?.currency ?? "..."}`}
                          label={`${form.values.order.type === "redemption" ? "Estimated" : ""} amount ${form.values.order.fund?.currency ? `in ${form.values.order.fund?.currency}` : "..."}`}
                          // description="Available: 3000"
                          currency={form.values.order.fund?.currency}
                          thousandSeparator=","
                          disabled={form.values.order.type === "redemption" || form.values.order.maxUnits || pending || votingChecked}


                          // formatter={(value) =>
                          //   value && !Number.isNaN(parseFloat(value))
                          //     ? `${value}`.replace(
                          //       /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                          //       ","
                          //     )
                          //     : ""
                          // }
                          hideControls
                          {...form.getInputProps("order.transferAmount")}
                          onChange={(num) => {
                            // let fund = fund;
                            // let fees_percent = form.getInputProps("type").value === "subscribe" ? fund?.subscription_fees_percent : 0;
                            form.setFieldValue("order.transferAmount", num as number || 0);
                            const numValue = Number(num)

                            form.setFieldValue(
                              "order.units",
                              calc(
                                undefined,
                                numValue,
                                fees_percent,
                                form.values.order.fund?.overhead_percent
                              )?.amt || 0
                            );
                          }}

                        // min={form.values.order.fund?.min_units ?? 0}
                        // onBlur={() => recalcAmount("order.transferAmount")}
                        />


                        <NumberInput
                          // defaultValue={18}

                          placeholder="Units"
                          thousandSeparator=","
                          disabled={form.values.order.type === "subscription" || form.values.order.maxUnits || pending}
                          maw={150}

                          label={`${form.values.order.type === "subscription" ? "Estimated" : ""} # of Units`}
                          // description="Available: 3000"
                          hideControls
                          {...form.getInputProps("order.units")}
                          onChange={(num) => {
                            // let fund = fund;

                            // let fees_percent = form.getInputProps("type").value === "subscribe" ? fund?.subscription_fees_percent : 0;
                            const numValue = Number(num)
                            form.setFieldValue("order.units", numValue || 0);
                            form.setFieldValue(
                              "order.transferAmount",
                              calc(
                                numValue,
                                undefined,
                                fees_percent,
                                form.values.order.fund?.overhead_percent
                              )?.amt || 0
                            );
                          }}
                        // onBlur={() => recalcAmount("order.units")}

                        // min={form.values.order.fund?.min_units ?? 0}
                        />


                        {/* <Text size="xs" c="dimmed">Surplus: {surplus} {form.values.order.fund?.currency}</Text> */}

                        {form.values.order.type === "redemption" && <Checkbox
                          checked={form.values.order.maxUnits}
                          disabled={pending}

                          label="Max Units"
                          onChange={(event) => {
                            form.setFieldValue("order.maxUnits", event?.currentTarget.checked);
                            if (event?.currentTarget.checked) {
                              form.setFieldValue("order.units", 0);
                              form.setFieldValue(
                                "order.transferAmount",
                                calc(
                                  0,
                                  undefined,
                                  fees_percent,
                                  form.values.order.fund?.overhead_percent
                                )?.amt || 0
                              );
                            }

                          }}
                        />}

                        {!form.values.order.fund.fees_included_in_transferred_amount &&

                          <Text mt={40} size="xs" c="red">
                            Total amount to be transferred: <NumberFormatter thousandSeparator value={(fees || 0) + (form.values.order.transferAmount || 0)} decimalScale={0} suffix={` ${form.values.order.fund?.currency}`} />
                          </Text>
                        }
                      </Group>


                    </Grid.Col>
                    <Grid.Col
                      span={{
                        lg: form.values.order.type === "subscription" ? 24 : 24,
                        xs: 24
                      }}
                    >


                      <Stack>
                        <Checkbox
                          checked={manualChecked}

                          onChange={(e) => {

                            setManualChecked(e.target.checked)
                            if (!e.target.checked) {
                              form.setFieldValue("order.iban", "")
                              form.setFieldValue("order.acct", "")
                            }
                          }}

                          label="Manual Entry"
                        />

                        {form.values.order.type === "subscription" ?

                          <>

                            {form.values.order.fund?.voting_units && form.values.order.type === "subscription" && <Checkbox
                              checked={votingChecked}

                              onChange={(e) => {

                                setVotingChecked(e.target.checked)
                                if (e.target.checked) {
                                  form.setFieldValue("order.units", form.values.order.fund!.voting_units!);
                                  form.setFieldValue(
                                    "order.transferAmount",
                                    calc(
                                      form.values.order.fund?.voting_units,
                                      undefined,
                                      form.values.order.fund.fees_included_in_transferred_amount ? fees_percent : undefined,
                                      form.values.order.fund.overhead_percent
                                    )?.amt || 0
                                  );
                                  form.setFieldValue("order.voting", true);
                                } else {
                                  form.setFieldValue("order.voting", undefined);
                                }

                              }}

                              label="Voting Order"
                            />}
                            {manualChecked && <BankAccountInfo
                              hideUploadSlip={true}
                              ibanProps={form.getInputProps("order.iban")}
                              transferDateProps={{ hide: true }}
                              acctNumberProps={form.getInputProps("order.acct")}
                              onBlurIban={
                                () => {
                                  console.log("Blurring!");
                                  if (form.values.order.iban && form.values.order.iban.length === 30) {
                                    let acct = getKFHAccountNo(form.values.order.iban);
                                    if (acct) {
                                      form.setFieldValue("order.acct", acct);
                                    }
                                  }
                                }
                              }

                              onBlurAccountNumber={
                                () => {
                                  if (form.values.order.acct && form.values.order.acct.length === 12) {
                                    const ibanValue = generateIBANFromKFHAccountNo(form.values.order.acct)
                                    if (ibanValue) { form.setFieldValue("order.iban", ibanValue); }
                                  }
                                }

                              }

                              handleUploadResponse={
                                (response: UploadFileResult, filesName: string[], setUploadedFiles?: (values?: FileWithPath[]) => void, filesList?: CustomFileWithPath[], currentFiles?: FileWithPath[]) => {


                                  const localFilePaths = filesName.map((fileName: string) => {
                                    const fileInfo = response.info![fileName];
                                    if (fileInfo && setUploadedFiles) {
                                      if (filesList) {
                                        setUploadedFiles(
                                          currentFiles?.concat({ ...fileInfo, name: fileName })
                                        )
                                      }
                                    }

                                    if (fileInfo.metadata?.acct) form.setFieldValue("order.acct", fileInfo.metadata?.acct);
                                    if (fileInfo.metadata?.iban) form.setFieldValue("order.iban", fileInfo.metadata?.iban);

                                    if (fileInfo.metadata?.amount) form.setFieldValue("order.transferAmount", (fileInfo.metadata?.amount));

                                    if (fileInfo.metadata?.transferDate) {
                                      let d = fileInfo.metadata?.transferDate.split("/");
                                      let transferDate = (`${d[1]}-${d[0]}-${d[2]}`);
                                      console.log("Setting transfer date to", transferDate);
                                      form.setFieldValue("order.transferDate", toTimestamp(transferDate));
                                    }

                                    return fileInfo.filepath;

                                    //loop through slip paths
                                    // if (response.info.filepaths && response.info.filepaths.length > 0) {
                                    //   for (const path of response.info.filepaths) {
                                    // form.insertListItem("slipFilePaths", fileInfo.filepath);
                                    //   }
                                    // }

                                    // form.setFieldValue(dwew
                                    //   "slipFilePaths",
                                    //   response.info.filepaths
                                    // );
                                  })

                                  console.log("localFilePaths", localFilePaths);
                                  console.log("serverFilePaths", response.info)




                                  form.setFieldValue("slipFilePaths", response.info!.filepaths)



                                }

                              }

                              setIsError={setIsError}
                              setMsg={setMsg}
                              clearFiles={clearFiles}
                              onRemoveFile={
                                (file) => {

                                  form.setFieldValue("slipFilePaths", form.values.slipFilePaths?.filter((path: string) => path !== (file as CustomFileWithPath).serverPath))


                                }

                              }

                            />}

                          </>
                          :
                          customerAccountsMemo
                        }

                      </Stack>
                    </Grid.Col>

                  </Grid>
                </>}
              </Grid.Col >

              <Space h="xl" />

              <Grid.Col span={{ xs: 24, lg: 6 }}>

                <Text
                  variant="gradient"
                  gradient={{ from: "indigo", to: "cyan", deg: 45 }}
                  style={{ fontFamily: 'Greycliff CF, sans-serif' }}
                  //   ta="center"
                  fz="xl"
                  fw={700}
                >
                  RM Information
                </Text>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, }}>

                <Grid >

                  <Grid.Col span={{ xs: 4 }} >

                    <Text>
                      {userContext.user?.displayName} - {segment}
                    </Text>
                  </Grid.Col>

                  <Grid.Col span={{ xs: 6 }} >

                    <Checkbox
                      checked={onBehalfOfChecked}

                      disabled={pending}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setSelectedRM(currentRM);
                          form.setFieldValue("onBehalfOf", undefined);
                          // setBehalfOfRM("");
                        }
                        setOnBehalfOfChecked(e.target.checked)
                      }}

                      label="On behalf of"
                    />

                  </Grid.Col>
                  <Grid.Col span={{ lg: 6 }}>
                    <RmsSelectComponent


                      defaultValue={form.values.order.rm?.rm_id}
                      // ref={civilIDRef}
                      // onChange={setBehalfOfRM}
                      textProps={{
                        label: "On Behalf of RM:",
                        placeholder: "search RM by name or email",
                        disabled: pending || !onBehalfOfChecked

                      }}
                      handleSubmit={(selectedOnBehalfRM: RMUser) => {
                        form.setFieldValue("onBehalfOf", selectedOnBehalfRM);
                        setSelectedRM(selectedOnBehalfRM);
                      }}

                    />

                    {/* <Autocomplete
                        // withAsterisk
                        maw={"700px"}
                        disabled={!onBehalfOfChecked || pending}
                        onChange={setBehalfOfRM}
                        placeholder="RM Name"
                        // itemComponent={RMAutoCompleteItem}
                        data={RMs.map((rm) => {
                          return {
                            ...rm,
                            value: rm.email,
                            label: (rm.name_en || rm.name_ar || rm.email || "Unknown") + "",
                          };
                        }) as ComboboxItem[]
                        }
                        onOptionSubmit={(item) => {
                          console.log("selected item", item);
                          const rmUser = RMs.find((rm) => rm.email === item)
                          console.log("User", rmUser);
                          form.setFieldValue("onBehalfOf", rmUser);
                        }}
                        limit={10}

                      /> */}

                    {/* <RMAutocomplete
                      disabled={!onBehalfOfChecked}
                      onChange={setBehalfOfRM}
                      onSubmit={(selectedRM: RMUser) => {
                        form.setFieldValue("onBehalfOf", selectedRM);
                      }}
                    /> */}

                    {/* <Loader
                        size="xs"
                        maw={"50px"}
                        width={"50px"}
                        style={{
                          visibility: RMAutocompleteLoading ? "visible" : "hidden",

                        }}

                      /> */}

                  </Grid.Col>
                </Grid>


              </Grid.Col >

              {/* <Text
                  variant="gradient"
                  gradient={{ from: "indigo", to: "cyan", deg: 45 }}
                  style={{ fontFamily: 'Greycliff CF, sans-serif' }}
                  //   ta="center"
                  fz="xl"
                  fw={700}
                >
                  Sender Identity
                </Text>

                <Grid>

                  <Grid.Col span={{ xs: 12 }}>

                    Identity for confirmation message to be sent from  to the client
                  </Grid.Col>


                  <Grid.Col span={{ xs: 12 }}>
                    <Group grow={true}>
                      <SenderIDSelect
                        {...form.getInputProps("updateLink.senderID")}
                      />
                    </Group>
                  </Grid.Col>
                </Grid> */}



              <Space h="xl" />
              <Space h="xl" />
              {((customerOrders &&
                customerOrders.length > 0 &&
                selectedFund &&
                // selectedFund.type === "closed_ended" &&
                subscriptionType === "subscription" &&
                customerOrders.filter((order) => order.fund?.id === selectedFund.id).length > 0)) &&
                <Grid.Col span={{ xs: 12, }}  >
                  <Alert

                    // w={"50%"}
                    variant="light"
                    icon={<IconAlertCircle size={16} />}
                    color="yellow"
                    title="Warning"
                  >
                    Already subscribed to this fund.
                  </Alert>
                </Grid.Col>
              }
              <Space h="xl" />



              {!manualChecked && showCommitmentButton &&
                <Grid.Col span={4}><Button type="submit" color="yellow" onClick={() => form.setFieldValue("action", "save_for_later")} loading={pending}
                  // disabled={isSubmitted}
                  disabled={(!selectedRM?.certified && (!selectedRM?.order_handler_email || selectedRM?.order_handler_email.trim() == ""))}
                  fullWidth

                >
                  <div
                    style={{
                      whiteSpace: "normal", // Allow text to wrap to the next line
                      wordBreak: "break-word", // Break words if they are too long
                      textAlign: "center", // Center-align text inside the button
                    }}
                  >

                    Save as Commitment (no SMS will be sent)
                  </div>
                </Button>    </Grid.Col>}


              {(manualChecked && showSendOrderButton) &&

                <Grid.Col span={4}>
                  <Button type="submit" color="indigo" onClick={() => form.setFieldValue("action", "manual")} loading={pending}
                    // disabled={isSubmitted}
                    // disabled={form.values.order.customerObj?.gender === undefined || form.values.order.customerObj?.nationality === undefined}
                    disabled={(!selectedRM?.certified && (!selectedRM?.order_handler_email || selectedRM?.order_handler_email.trim() == ""))}
                  >
                    <div
                      style={{
                        whiteSpace: "normal", // Allow text to wrap to the next line
                        wordBreak: "break-word", // Break words if they are too long
                        textAlign: "center", // Center-align text inside the button
                      }}
                    >

                      Save as Manual {!selectedRM?.certified ? "Referral" : ""}
                    </div>
                  </Button>
                </Grid.Col>}



              {showSendOrderButton && !manualChecked &&
                <Grid.Col span={4} style={{ overflowWrap: "break-word" }}>
                  <Button
                    type="submit"
                    loading={pending}
                    fullWidth
                    disabled={!selectedRM?.certified && (!selectedRM?.order_handler_email || selectedRM?.order_handler_email.trim() == "")}
                    onClick={() => {

                      if (selectedRM?.certified) {
                        form.setFieldValue("action", "send_order_sms")
                      } else {
                        form.setFieldValue("action", "submit_as_referral")
                      }

                    }}

                  >
                    <div
                      style={{
                        whiteSpace: "normal", // Allow text to wrap to the next line
                        wordBreak: "break-word", // Break words if they are too long
                        textAlign: "center", // Center-align text inside the button
                      }}
                    >
                      {selectedRM?.certified
                        ? `Send Order ${useWhatsapp ? "Whatsapp" : "SMS"} Message`
                        : "Submit Order As Referral"}

                    </div>

                  </Button>
                </Grid.Col>
              }


              {(userClaims.admin || userClaims.cr) && showSendOrderButton && !manualChecked &&
                <Grid.Col span={3} >

                  <Checkbox
                    checked={useWhatsapp}

                    label={<Text>
                      send through whatsapp
                      <Text span c="blue">
                        {(useWhatsapp ? " (uncheck to use SMS)" : "")}

                      </Text>
                      {useWhatsapp && whastappTemplateLoading && <Text c={"green"}>
                        Loading Template...

                        <span>

                          <Loader size={14} />
                        </span>
                      </Text>}
                    </Text>
                    }
                    onChange={(e) => {
                      setUseWhatsapp(e.target.checked)
                      form.setFieldValue("updateLink.selectedWhatsappTemplate", undefined);
                      form.setFieldValue("updateLink.useWhatsapp", false);
                    }}
                  />
                </Grid.Col>
              }

              {
                (useWhatsapp && !whastappTemplateLoading && !form.values.updateLink?.selectedWhatsappTemplate) &&
                <Grid.Col span={12}>
                  <Text c="red">
                    Invalid WhatsApp template, Please use SMS Instead
                  </Text>
                </Grid.Col>
              }
              {
                (!selectedRM?.certified && (!selectedRM?.order_handler_email || selectedRM?.order_handler_email.trim() == "")) &&
                <Grid.Col span={12}>
                  <Alert

                    // w={"50%"}
                    variant="light"
                    icon={<IconAlertCircle size={16} />}
                    color="yellow"
                    title="Warning"
                  >
                    You do not have a CMA certification and don't have a certified RM to handle your orders
                  </Alert>
                  {/* <Text c="yellow">
                    You are not allowed to add order, because you are note CMA certified and dont have certified RM to handle your orders
                  </Text> */}
                </Grid.Col>
              }


              <Grid.Col span={12}>
                {msg != null && ( //love my check if it's an error or not.  Please don't do this, it's just a proof of concept page.
                  <Alert
                    icon={<IconAlertCircle size={16} />}
                    color={isError ? "red" : "green"}
                    title={isError ? "Failure" : "Success"}
                  >
                    {msg}
                  </Alert>
                )}
              </Grid.Col>
              {SMSDocID && !manualChecked && form.values.action !== "save_for_later" && form.values.action !== "submit_as_referral" &&
                <Grid.Col span={12} >


                  <MessagesSent docID={SMSDocID} handleFailedMessages={() => {

                    const handleOrderSMS = async () => {

                      const sendOrderSMS = httpsCallable<SendOrderSMSRequest, BaseResult>(functions, "sendOrderSMS");


                      //generate a doc ID
                      const docID = doc(collection(db, "sms")).id;
                      setSMSDocID(docID);

                      //set type to submit because we want the user to create an entirely new KYC
                      await sendOrderSMS({
                        order: createdOrder!,
                        senderID: "KFHC-FUNDS",
                        smsDocID: docID,
                        token: await userContext.getAzureADToken!(userContext)
                      });
                    }
                    handleOrderSMS();
                  }} />




                </Grid.Col>
              }
              {createdOrder && <Button fullWidth mt={5}
                onClick={() => navigate("/orders", {
                  state: {
                    refreshOrdersIn: 1000,
                  }
                })}
                variant='outline'
                color={"darkgreen"}
              >
                Back to Orders
              </Button>}
              {/* <Menu shadow="md" width={200}>
                    <Menu.Target>

                      {!manualChecked && <Button
                        // type="submit" onClick={() => form.setFieldValue("action", "send_order_sms")} 
                        loading={pending}
                      // disabled={isSubmitted}
                      >
                        Send order Message
                      </Button>}
                    </Menu.Target>

                    <Menu.Dropdown>


                      <Menu.Divider />

                      <Menu.Label>Danger zone</Menu.Label>
                      <Menu.Item


                      >
                        <Button
                          type="submit"

                          fullWidth
                          onClick={() => {
                            setUseWhatsapp(true);
                            form.setFieldValue("action", "send_order_sms")
                          }}
                        >

                          whatsapp
                        </Button>
                      </Menu.Item>
                      <Menu.Item


                      >
                        <Button
                          type="submit"
                          fullWidth
                          onClick={() => form.setFieldValue("action", "send_order_sms")} loading={pending}
                        // disabled={isSubmitted}
                        >
                          sms
                        </Button>

                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu> */}


              <Box mx="auto">
                <Space h="xl" />

                {form.errors != null && Object.keys(form.errors).length > 0 && (
                  <>
                    <Alert
                      icon={<IconAlertCircle size={16} />}
                      color="red"
                      title="Check form"
                    >
                      {Object.values(form.errors).join(", ")}
                    </Alert>
                    {/* <Text>{JSON.stringify(form.values)}</Text> */}
                  </>
                )}
              </Box>
            </Grid >

          </form >
        </Container >
      </Card >
      {
        (customerCivilID || toBeUpdatedCustomer?.civilID) && <CustomModal
          title={<Title>{toBeUpdatedCustomer === undefined ? "Add Client" : "Update Client"}</Title>}
          opened={addClientModal}

          // setOpened={setAddClientModal`}
          onClose={async () => {
            setAddClientModal(false);
          }}
          // overlayOpacity={0.22}
          // overlayBlur={3}
          centered
        >
          <RequestKYCUpdate civilID={toBeUpdatedCustomer?.civilID ?? customerCivilID!} customer={toBeUpdatedCustomer} isUpdate={toBeUpdatedCustomer !== undefined}

            onClose={() => {
              if (customerCivilID) {
                getCustomerData(customerCivilID)
              }

            }}
            setModal={setAddClientModal} />
        </CustomModal>
      }
      {
        KYCUrl !== "" && <CustomModal
          title="Order Created Successfully"
          withCloseButton={false}
          overlayProps={{
            backgroundOpacity: 0.55,
            blur: 10,
          }}
          opened={KYCUrl !== ""}
          onClose={async () => {
            setAddClientModal(false);
          }}
          centered
        >
          <Grid >
            <Grid.Col span={6}>
              <Button fullWidth mt={5}
                onClick={() => navigate("/orders", {
                  state: {
                    refreshOrdersIn: 1000,
                  }
                })}
                variant='outline'
                color={"darkgreen"}
              >
                Back to Orders
              </Button>
            </Grid.Col>
            <Grid.Col span={6}>
              <a style={{ textDecoration: 'none' }} href={KYCUrl} target="_blank" rel="noopener noreferrer">
                <Button fullWidth mt={5} type="button"
                  onClick={() => navigate("/orders", {
                    state: {
                      refreshOrdersIn: 1000,
                    }
                  })}
                  variant='outline'
                >
                  Fill KYC
                </Button>
              </a>
            </Grid.Col>
          </Grid>
        </CustomModal>
      }
    </Stack >
  );
}




export default AddOrder