import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Card,
    Group,
    Loader,
    Stack,
    Text,
    FileInput,
    NumberFormatter,
    SimpleGrid,
} from '@mantine/core';
import * as ExcelJS from 'exceljs';
import {
    getFirestore,
    collection,
    doc,
    setDoc,
    writeBatch,
    getDoc,
    query,
    where,
    getDocs,
    Timestamp,
} from 'firebase/firestore';
import { ConfigContext, MyUserDataContext } from '../../contexts';
import { CustomerFundData, CustomerFundDataTransaction, Fund, RedemptionBreakdown, CustomerFundDataTransactionType } from '../../types';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { entries } from 'lodash';



const UploadSubRed = () => {
    const [file, setFile] = useState<File | null>(null);
    const [processing, setProcessing] = useState(false);
    const [updatingFirestore, setUpdatingFirestore] = useState(false);
    const [customersUpdated, setCustomersUpdated] = useState(0);
    const [customersTotal, setCustomersTotal] = useState(0);
    const [failedCustomers, setFailedCustomers] = useState<string[]>([]);
    const [processingErrors, setProcessingErrors] = useState<string[]>([]);
    const [withinWeekWarnings, setWithinWeekWarnings] = useState(0);

    const [results, setResults] = useState<any>(null);
    const [fundsDataMap, setFundsDataMap] = useState<Record<string, Fund>>({});
    const [loadingFunds, setLoadingFunds] = useState(true);
    const appContext = useContext(MyUserDataContext);

    const db = getFirestore();


    useEffect(() => {
        const fetchFunds = async () => {
            const fundsQuery = query(
                collection(db, 'funds'),
                where('active', '==', true)
            );
            const querySnapshot = await getDocs(fundsQuery);
            const funds: Fund[] = [];
            querySnapshot.forEach((doc) => {
                funds.push({ id: doc.id, ...doc.data() } as Fund);
            });
            const fundsDataMap = funds.reduce((acc, fund) => {
                acc[fund.id!] = fund;
                return acc;
            }, {} as Record<string, Fund>);
            setFundsDataMap(fundsDataMap);
            setLoadingFunds(false);
        };

        fetchFunds();
    }, []);



    const handleFileUpload = async (file: File) => {
        setProcessing(true);
        const workbook = new ExcelJS.Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);

        reader.onload = async () => {
            const buffer = reader.result as ArrayBuffer;
            await workbook.xlsx.load(buffer);

            // let entries: CustomerFundData[] = [];
            //basically a map of customerID to fundCode to fund data
            let customers: Record<string, Record<string, CustomerFundData>> = {};
            let transactionsPerFundCode: Record<string, number> = {};
            let totalQtyPerFundCode: Record<string, number> = {};
            let totalAmountPerFundCode: Record<string, number> = {};




            workbook.eachSheet((worksheet) => {
                worksheet.eachRow((row, rowNumber) => {
                    if (rowNumber === 1) return; // Skip header row

                    let data: CustomerFundDataTransaction = {};


                    let customerID = row.getCell('C').value?.toString().trim();
                    if (!customerID) {
                        console.log('Customer ID is missing at row ' + rowNumber);
                        setProcessingErrors(prevErrors => [...prevErrors, 'Customer ID is missing at row ' + rowNumber]);
                        return;
                    }

                    const fundCode = row.getCell('A').value?.toString().trim();
                    if (!fundCode) {
                        console.log('Fund Code is missing at row ' + rowNumber);
                        setProcessingErrors(prevErrors => [...prevErrors, 'Fund Code is missing at row ' + rowNumber]);
                        return;
                    }


                    customerID = customerID.replace(/\//g, '-');

                    let deal_date: Date;

                    //I want to log the type
                    if (row.getCell('B').value) {
                        if (typeof row.getCell('B').value === 'string') {
                            // console.log(customerID, fundCode, row.getCell('B').value);
                            var parts = (row.getCell('B').value as string).split("/");
                            deal_date = new Date(parseInt(parts[2], 10),
                                parseInt(parts[1], 10) - 1,
                                parseInt(parts[0], 10));
                        }
                        else if (typeof row.getCell('B').value === 'object') {
                            deal_date = row.getCell('B').value as Date;
                        }
                        else {
                            console.log('Invalid date at row ' + rowNumber);
                            setProcessingErrors(prevErrors => [...prevErrors, 'Invalid date at row ' + rowNumber]);
                            return;
                        }
                    }
                    else {
                        console.log('No date at row ' + rowNumber);
                        setProcessingErrors(prevErrors => [...prevErrors, 'No date at row ' + rowNumber]);
                        return;
                    }

                    //is the date more than last week?
                    if (deal_date < new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)) {
                        setWithinWeekWarnings(prevWarnings => prevWarnings + 1);
                    }
                    //is it in the future?
                    if (deal_date > new Date()) {
                        setWithinWeekWarnings(prevWarnings => prevWarnings + 1);
                    }


                    const formatted_date = deal_date.toLocaleDateString("en-GB", { // you can use undefined as first argument
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    }).replace(/\//g, "");

                    //check if the date is valid
                    if (isNaN(deal_date.getTime())) {
                        console.log('Invalid date at row ' + rowNumber);
                        setProcessingErrors(prevErrors => [...prevErrors, 'Invalid date at row ' + rowNumber]);
                        return;
                    }

                    data.code = fundCode;
                    data.deal_date = Timestamp.fromDate(deal_date);
                    //make sure the type is part of TransactionType
                    const t = row.getCell('D').value?.toString().toLowerCase();
                    if (!Object.values(CustomerFundDataTransactionType).includes(t as CustomerFundDataTransactionType)) {
                        console.log('Invalid transaction type at row ' + rowNumber);
                        setProcessingErrors(prevErrors => [...prevErrors, 'Invalid transaction type at row ' + rowNumber + ' (' + t + ')']);
                        return;
                    }
                    data.type = t as CustomerFundDataTransactionType;

                    data.shares = Math.abs(Number(row.getCell('E').value || 0));
                    data.amount = Math.abs(Number(row.getCell('F').value || 0));
                    data.description = (data.shares || 0) === 0 ? "PnL" : t;
                    if (data.description === "PnL") {
                        data.dividend = true;
                    }
                    else {
                        data.dividend = false;
                    }

                    data.user_amount = data.amount;
                    data.user_shares = data.shares;
                    data.currency = fundsDataMap[fundCode]?.currency;

                    // Validate transaction type
                    const validTypes = ['subscription', 'redemption', 'transfer in', 'transfer out'];
                    if (!validTypes.includes(data.type || "")) {
                        console.log('Invalid transaction type at row ' + rowNumber);
                        setProcessingErrors(prevErrors => [...prevErrors, 'Invalid transaction type at row ' + rowNumber + ' (' + data.type + ')']);
                        return;
                    }

                    if (!customers[customerID]) {
                        customers[customerID] = {};
                    }

                    if (!customers[customerID][fundCode]) {
                        customers[customerID][fundCode] = {
                            gl_code: fundCode,
                            gl_name: fundsDataMap[fundCode]?.name_en,
                            currency: fundsDataMap[fundCode]?.currency,
                            transactions: {},
                            eunits: 0,
                            bunits: 0,
                        };
                    }


                    // Start of Selection
                    // Add transaction to customer's fund data
                    const customerFund = customers[customerID][fundCode];
                    customerFund.transactions![`${data.type}_${formatted_date}`] = data;

                    // customerFund.eunits! += data.shares;
                    // customerFund.bunits = customerFund.eunits!;
                    customerFund.force_recalculate = true;
                    // customers[customerID][fundCode].totalQty += data.shares;
                    // customers[customerID][fundCode].totalAmount += data.amount;
                    // entries.push(data);
                    // customers.add(customerID);
                    // setCustomersUpdated(prevUpdated => prevUpdated + 1);

                    // Initialize fund code summaries if not already
                    if (!transactionsPerFundCode[fundCode]) {
                        transactionsPerFundCode[fundCode] = 0;
                        totalQtyPerFundCode[fundCode] = 0;
                        totalAmountPerFundCode[fundCode] = 0;
                    }

                    // Update summaries
                    transactionsPerFundCode[fundCode] += 1;
                    totalQtyPerFundCode[fundCode] += data.shares;
                    totalAmountPerFundCode[fundCode] += data.amount;
                });
            });

            // Prepare results for display
            const totalTransactions = Object.keys(transactionsPerFundCode).reduce((sum, fundCode) => sum + transactionsPerFundCode[fundCode], 0);
            const totalCustomers = Object.keys(customers).length;

            setCustomersTotal(Object.keys(customers).length);
            setCustomersUpdated(0);
            setFailedCustomers([]);

            setResults({
                totalTransactions,
                totalCustomers,
                transactionsPerFundCode,
                totalQtyPerFundCode,
                totalAmountPerFundCode,
                customers,
            });

            setProcessing(false);
            console.log(results);
        };
    };

    const submitData = async () => {
        if (!results) return;
        setUpdatingFirestore(true);

        const { customers } = results;

        let batch = writeBatch(db);

        // const customersData: Record<string, any[]> = {};

        // Organize entries by customer ID
        // for (let entry of entries) {
        //     const customerID = entry['id'];
        //     if (!customersData[customerID]) {
        //         customersData[customerID] = [];
        //     }
        //     customersData[customerID].push(entry);
        // }

        // Start of Selection
        let writes = 0;
        const BATCH_LIMIT = 400;
        const batchCommits: Promise<void>[] = [];
        const customerIDs = Object.keys(customers);
        const CHUNK_SIZE = 200; // Adjust this value based on your needs


        for (let i = 0; i < customerIDs.length; i += CHUNK_SIZE) {
            const chunk = customerIDs.slice(i, i + CHUNK_SIZE);

            // Fetch customer documents in parallel
            const customerPromises = chunk.map(async (customerID) => {
                const customersRef = collection(db, 'customers');
                const q = query(customersRef, where('crmID', '==', customerID));
                const querySnapshot = await getDocs(q);
                if (querySnapshot.empty) {
                    return { customerID, customerDoc: null };
                }
                //be careful, customerID is not necessarily the same as the firestoreID
                return { firestoreID: querySnapshot.docs[0].id, customerID, customerDoc: querySnapshot.docs[0] };
            });

            // Wait for all customer documents in the chunk to be fetched
            const customerResults = await Promise.all(customerPromises);

            for (const { firestoreID, customerID, customerDoc } of customerResults) {
                if (!customerDoc) {
                    console.log('Customer does not exist: ' + customerID);
                    setFailedCustomers((prevFailed) => [...prevFailed, customerID]);
                    continue;
                }

                try {
                    const customerFunds = customers[customerID];

                    // Save fund data for each fund code
                    for (let fundCode in customerFunds) {
                        const fundData = customerFunds[fundCode];

                        const fundDocRef = doc(
                            db,
                            'customers',
                            firestoreID,
                            'fund_data',
                            fundCode
                        );


                        //   console.log("Saving data for ", fundDocRef.path);

                        console.log(fundData);
                        // Merge to avoid overwriting existing transactions
                        batch.set(fundDocRef, fundData, { merge: true });
                        //   batch.set(fundDocRef, fundData);
                        writes++;
                    }

                    setCustomersUpdated((prevUpdated) => prevUpdated + 1);
                }
                catch (error) {
                    console.error('Error processing chunk:', error);
                    setProcessingErrors((prevErrors) => [...prevErrors, 'Error processing chunk: ' + error]);
                }
                if (writes >= BATCH_LIMIT) {
                    batchCommits.push(batch.commit());
                    batch = writeBatch(db);
                    writes = 0;
                }
            }
        }

        // Commit any remaining writes
        if (writes > 0) {
            batchCommits.push(batch.commit());
        }

        // Await all batch commits
        try {
            await Promise.all(batchCommits);
            notifications.show({
                title: 'Data Submission',
                message: 'Data submitted successfully!',
                color: 'green',
                icon: <IconCheck />,
            });
            // Clear everything
            //   setResults(null);
            setFile(null);
        } catch (error) {
            console.error('Batch commit failed:', error);
            notifications.show({
                title: 'Batch Commit Failed',
                message: 'Some batches failed to commit.',
                color: 'red',
                icon: <IconX />,
            });
        }

        setUpdatingFirestore(false);
    };

    if (loadingFunds) {
        return <Loader />;
    }

    return (
        <Card shadow="sm" p="xl" radius="md" withBorder>
            <FileInput
                label="Upload Excel File"
                placeholder="Choose file"
                accept=".xlsx"
                value={file}
                onChange={(file) => {
                    // setFile(file);
                    if (file) {
                        handleFileUpload(file);
                    }
                }}
            />

            {processing && (
                <Group p="center" mt="md">
                    <Loader />
                    <Text>Processing...</Text>
                </Group>
            )}

            {results && (
                <Stack mt="md">
                    <Text><Text fw={700} span>Total Transactions:</Text> <NumberFormatter value={results.totalTransactions} thousandSeparator /></Text>
                    <Text><Text fw={700} span>Total Customers:</Text> <NumberFormatter value={results.totalCustomers} thousandSeparator /></Text>
                    {withinWeekWarnings > 0 && <Text c="orange"><Text fw={700} span>Warning:</Text> {withinWeekWarnings} transactions not within this week</Text>}
                    <SimpleGrid cols={3}>

                        {Object.keys(results.transactionsPerFundCode).map((code) => (
                            <Card key={code} withBorder shadow="sm" p="md">
                                <Group justify="space-between" mb="sm">
                                    <Text fw={700}>Fund Code:</Text>
                                    <Text>{code}</Text>
                                </Group>
                                <Group justify="space-between" mb="sm">
                                    <Text fw={700}>Transactions:</Text>
                                    <NumberFormatter value={results.transactionsPerFundCode[code]} thousandSeparator />
                                </Group>
                                <Group justify="space-between" mb="sm">
                                    <Text fw={700}>Total Quantity:</Text>
                                    <NumberFormatter value={results.totalQtyPerFundCode[code]} thousandSeparator />
                                </Group>
                                <Group justify="space-between">
                                    <Text fw={700}>Total Amount:</Text>
                                    <NumberFormatter
                                        value={results.totalAmountPerFundCode[code].toFixed(3)}
                                        thousandSeparator
                                        suffix={` ${fundsDataMap[code]?.currency}`}
                                    />
                                </Group>
                            </Card>
                        ))}
                    </SimpleGrid>
                    <Button onClick={submitData} mt="md" loading={updatingFirestore}>
                        Submit Data to Firestore
                    </Button>
                    <Text><Text fw={700} span>Customers Updated:</Text> {customersUpdated}/{customersTotal}</Text>
                    {failedCustomers.length > 0 && <Text><Text fw={700} span>Failed Customers:</Text> {failedCustomers.join(', ')}</Text>}
                    {processingErrors.length > 0 && <Text><Text fw={700} span>Processing Errors:</Text> {processingErrors.join(', ')}</Text>}
                </Stack>
            )}
        </Card>
    );
};

export default UploadSubRed;