
import React, { useContext, useEffect, useState } from 'react';
import 'dayjs/locale/en-gb';
import { collection, orderBy, query, limit, DocumentData, onSnapshot, DocumentReference, doc, Timestamp, deleteDoc, where, getDocs, QueryDocumentSnapshot, getDoc } from 'firebase/firestore';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AppConfigContext, ConfigContext, GlobalDataContext, MyUserDataContext } from '../contexts';
import { httpsCallable } from 'firebase/functions';
import { Group, Modal, TextInput, Text, Button, Space, Title, SimpleGrid, Grid, Checkbox, Textarea, Stack, Radio, Alert, Timeline, Image, ActionIcon, NumberInput, NumberFormatter, Accordion, ThemeIcon, Menu, Card, } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Action, ActionType, BaseResult, Customer, KYC, Order, SubscriptionType, OrderRefreshRequest, UploadFileResult, UpdateOrderRequest, OrderStatusCode, CustomClaims, CustomFileWithPath, SendOrderSMSRequest, DocType, ItemInfoEditedObj, Fund, GenericProduct, OrdersPath, RMUser, GeneralOrderRequest, } from '../types';
import { IconThumbUp, IconThumbDown, IconGitCommit, IconMessageDots, IconEye, IconAlertCircle, IconStar, IconEdit, IconPhone, IconSchool, IconShoppingCart, IconBuildingBank, IconUsers, IconScript, IconCreditCard, IconX, IconCheck } from '@tabler/icons-react';
import { FirebaseError } from 'firebase/app';
import { FilesList } from './files';
import GeneratePDFModal from './generate_pdf_modal';
import { ordersPath } from '../helpers/orderFilesPath';
import SenderIDSelect from './SenderIDSelect';
import { getAddressDetailsEnglish } from '../helpers/addressHelpers';
import { getCustomerFromPaciPersonalData, isCorporateEmail } from '../helpers/common';
import { CustomerKYC } from '../routes/customers/customer_kyc';
import { modals, openConfirmModal } from '@mantine/modals';
import { updateDoc } from 'firebase/firestore';
import { FileRejection, FileWithPath } from '@mantine/dropzone';
import BankAccountInfo from '../routes/BankAccountInfo';
import { generateIBANFromKFHAccountNo, getKFHAccountNo } from '../utils';
import { isValidIBAN } from 'ibantools';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import areAllFolderFilesTagged from '../helpers/areAllFolderFilesTagged';
import { notifications, showNotification } from '@mantine/notifications';
import { CustomInfoCard } from '../routes/customers/CustomInfoCard';
import { map } from 'lodash';
import EditableInfoCard from '../routes/customers/EditableInfoCard';
import { calc } from '../helpers/orderHelpers';
import ChangeRmModalContent from './change_rm_modal';
import { useFirestore, useFunctions } from '../helpers/firebaseContext';
import useFirestoreDoc from '../helpers/useFirestoreDoc';
import useApprovals from '../helpers/useApprovals';
import { uploadToStorage } from '../helpers/uploadToStorage';
import { UploadBox } from './upload_box';
import { MessagesSent, refreshWhatsappTemplates } from './send_sms_modal';
// import useFirestoreDocData from '../helpers/useFirestoreDocData';


const EditUnits = ({ orderRef, startingUnits = 0 }: { orderRef: DocumentReference, startingUnits?: number }) => {

    const [updating, setUpdating] = useState(false);
    const [units, setUnits] = useState(startingUnits);

    const userData = useContext(MyUserDataContext);

    console.log("userData in EditUnits", userData);


    return <>
        <NumberInput label="Number of units" data-autofocus value={units} onChange={(v) => setUnits(Number(v))} />

        <Button loading={updating} fullWidth onClick={async () => {
            setUpdating(true);

            //change number of units in firestore
            console.log("userData", userData);
            await updateDoc(orderRef, { units: units, unitsUpdatedBy: userData.user?.email || "No email" });

            setUpdating(false);
            modals.closeAll();

        }} mt="md">
            Submit
        </Button>

    </>


}

export interface OrderInfoProps {
    order: Order,
    customerRef: DocumentReference,
    orderRef: DocumentReference,
    orderDoc?: QueryDocumentSnapshot<Order>
    updatedOrderData?: any
}

//create CustomerInfo component
export const OrderInfo = ({ orderDoc, order, customerRef, orderRef }: OrderInfoProps) => {
    const { id } = useParams();
    const storage = getStorage();
    const globalDataContext = useContext(GlobalDataContext);

    // const customerData =
    //     (order?.paciData ? { ...getCustomerFromPaciPersonalData(order?.paciData) } : (order.customerObj)) as (Customer & KYC)

    const [updatedOrderData, setUpdatedOrderData] = useState<ItemInfoEditedObj<Order>>({});


    const paciData = getCustomerFromPaciPersonalData(order?.paciData);

    const userData = useContext(MyUserDataContext);
    const appConfigContext = useContext(AppConfigContext);
    const config = useContext(ConfigContext);
    let userClaims = userData.firebaseTokenResult?.claims.customClaims as CustomClaims || {};

    console.log("The user data in OrderInfo is", userData);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [links, setLinks] = useState([] as any[]);
    const [SMSDocID, setSMSDocID] = useState<string>();

    const navigate = useNavigate();

    const db = useFirestore();

    //make pending state
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pending, setPending] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [openAccordions, setOpenAccordions] = useState<string[]>(["order"])

    const [msg, setMsg] = useState(null as any);
    const [isError, setIsError] = useState(false);
    const [sendSMS, setSendSMS] = useState(false);
    const [usePaci, setUsePaci] = useState(true);
    const [useWhatsapp, setUseWhatsapp] = useState(false);
    const [outdatedKyc, setOutdatedKyc] = useState(false);
    const [generatePDFOpen, setGeneratePDFOpen] = useState(false);
    const [generateFilledPDFOpen, setGenerateFilledPDFOpen] = useState(false);
    const [allFilesTagged, setAllFilesTagged] = useState<boolean>(true);
    const [isRejectingOrder, setIsRejectingOrder] = useState<boolean>(true);
    const [changeRmOpen, setChangeRmOpen] = useState(false);
    const [changeReferredByRmOpen, setChangeReferredByRmOpen] = useState(false);
    const [refetchAgreement, setRefetchAgreement] = useState(false)
    const [finalSignedAgreementLoading, setFinalSignedAgreementLoading] = useState(false);
    const [emptyOrderFilePath, setEmptyOrderFilePath] = useState<OrdersPath[]>([]);

    const [metaTypes, setMetaTypes] = useState<DocType[]>([]);  //this is for CustomerKYC to set the metatypes of files that exist on the KYC.  That way, we can populate the Generate PDF form properly.


    const [draftActionLoading, setDraftActionLoading] = useState(false);

    const [kycData, setKycData] = useState<DocumentData | null>(null);
    // const [kycTags, setKycTags] = useState<Tag>();
    const [latestFundData, setLatestFundData] = useState<Fund | null>(null);


    const [forceRefreshFiles, setForceRefreshFiles] = useState<number | null>()

    const [refreshing, setRefreshing] = useState(false);

    const { actions, actionsAfterRejections } = useApprovals(orderRef);
    const [currentRM, setCurrentRM] = useState<RMUser | undefined>(undefined)



    const functions = useFunctions();
    functions.region = "europe-west1";

    const orderAction = httpsCallable<Action, BaseResult>(functions, 'orderAction');
    const kycAction = httpsCallable<Action, BaseResult>(functions, "kycAction");

    //get customerRef from order
    useEffect(() => {
        if (!order) return;
        let kycUnsubscriber = () => { };
        if (order?.kyc_id) {

            const kycStateDoc = doc(db, `customers/${customerRef?.id}/kyc`, order?.kyc_id);
            setKycLoading(true);

            kycUnsubscriber = onSnapshot(kycStateDoc, (snapshot) => {
                if (snapshot.metadata.fromCache) {
                    console.log("kycState Cached data", snapshot.data());
                } else {
                    console.log("kycState Server data", snapshot.data());
                }

                const data = { ...snapshot.data(), id: snapshot.id } as KYC;

                setKycData(data);

                //TODO : KYC Object should have a state "object"
                setKycState(data.state)
                setKycLoading(false);
            });

        }
        else {

            // const kycTagsDoc = query(collection(db , "customers"),where("id","==",order.customerRef.id));
            const kycCollection = collection(customerRef as DocumentReference, "kyc");
            let kycQuery = query(kycCollection, orderBy("created", "desc"), limit(1));
            // kycQuery = query(kycQuery, limit(1));


            kycUnsubscriber = onSnapshot(kycQuery,
                (snapshot) => {
                    if (snapshot.metadata.fromCache) {
                        console.log("Cached data kycUnsubscriber", snapshot.docs.length);
                        // return;
                    }
                    else {
                        console.log("Server data kycUnsubscriber", snapshot.docs.length);
                    }

                    if (!snapshot.empty) {
                        let data = snapshot.docs[0].data();
                        data.id = snapshot.docs[0].id;
                        setKycData(data);
                        setKycState(data.state)
                    }

                },
                (error) => {
                    console.log(error);
                })

        }
        let customerUnsubscriber = onSnapshot(customerRef as DocumentReference,
            (snapshot) => {
                if (snapshot.metadata.fromCache) {
                    console.log("Cached data", snapshot);
                    // return;
                }
                else {
                    console.log("Server data", snapshot);
                }

                if (snapshot) {
                    // setKycTags((snapshot?.data() as Customer)?.tags?.kycStatus);
                }

            },
            (error) => {
                console.log(error);
            })

        let updatedDataObj: ItemInfoEditedObj<Order> = {}
        if (order.updated_data && order.updated_data?.length) {
            const DataKeys = (map(order.updated_data!, (value) => (map(value?.updated_fields!, (innerValue) => Object.keys(innerValue)))));
            updatedDataObj = DataKeys?.flat(2).reduce((o, key) => ({ ...o, [key]: {} }), {})

            order?.updated_data!.forEach((objEntry) => {
                objEntry?.updated_fields?.forEach((updatedField) => {

                    const objKey = Object.keys(updatedField)[0] as keyof Order;
                    updatedDataObj = {
                        ...updatedDataObj, [objKey]: {
                            ...updatedDataObj[objKey],
                            currentValue: updatedField[objKey],
                            updatedOn: objEntry?.updated_on!,
                            updatedBy: objEntry?.updated_by!

                        }
                    }

                }
                )
                objEntry?.initial_value?.forEach((previousField) => {

                    const objKey = Object.keys(previousField)[0] as keyof Order;
                    updatedDataObj = {
                        ...updatedDataObj, [objKey]: {
                            ...updatedDataObj[objKey],
                            previousValue: previousField[objKey],
                            updatedOn: objEntry?.updated_on!,
                            updatedBy: objEntry?.updated_by!



                        }
                    }

                }
                )

            }
            )

            return setUpdatedOrderData(updatedDataObj)
        }

        if (order.manual && order?.type && order?.fund) {

            let files = ordersPath[order?.type as SubscriptionType][order.fund.id];

            const fundsMap = globalDataContext?.fundsMap;

            if (fundsMap) {
                const orderPath = fundsMap[order.fund.id]?.orders_path;

                if (order?.type === "subscription") {
                    files = orderPath?.subscription ?? files;
                } else if (order?.type === "redemption") {
                    files = orderPath?.redemption ?? files;
                }
            }

            if (order.voting) {
                files = files.filter((file) => file.agreementType !== 'nonvoting')
            } else {
                files = files.filter((file) => file.agreementType !== 'voting')
            }

            setEmptyOrderFilePath(files);
        }

        return () => {
            console.log("Cleaning up...")
            kycUnsubscriber();
            customerUnsubscriber();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);


    useEffect(() => {

        if (order.id) {
            areAllFolderFilesTagged(`orders/${order.id}/`).then((res) => {
                setAllFilesTagged(res);
            }).catch((err) => {
                console.error("Caught error when checking for tags", err);
                setAllFilesTagged(false);
            });
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [links]);



    useEffect(() => {
        if (!order) return;
        console.log("Running effect!");

        if (order.fund.id) {
            getDoc(doc(db, `funds/${order.fund.id}`)).then((result) => {
                let data = result.data() as Fund | null;
                if (data) {
                    setLatestFundData(data!);
                }
            });
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);
    useEffect(() => {
        const getData = async () => {

            await refreshWhatsappTemplates(true, form, appConfigContext.apiUrl!, () => { }, false, () => { },
                config?.orderTemplates?.whatsappTemplate, "linkInfo.selectedWhatsappTemplate",
            )

        }
        if (useWhatsapp) { getData() }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useWhatsapp])

    //get Current RM
    useEffect(() => {

        if (!userData.user?.email) {
            return;
        }

        const rmDoc = doc(db, "rms", userData.user.email.toLowerCase());

        return onSnapshot(rmDoc, (snapshot) => {
            if (snapshot.metadata.fromCache) {
                console.log("RM Cached data", snapshot.exists());
                // return;
            } else {
                console.log("RM Server data", snapshot.exists());
            }
            let rm: RMUser | undefined = undefined;

            if (snapshot.exists()) {
                rm = snapshot.data() as RMUser;
            }



            setCurrentRM(rm);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData.user]);
    // console.log(data);
    const [rejectionOpened, setRejectionOpened] = useState(false);
    const [uploadSlipOpened, setUploadSlipOpened] = useState(false);
    const [uploadFinalSignedAgreementOpened, setUploadFinalSignedAgreementOpened] = useState(false);

    const [confirmDialog, setConfirmDialog] = useState<any>(null);
    const [confirmDialogError, setConfirmDialogError] = useState<any>(null);
    const [clearFiles, setClearFiles] = useState<any>(false);
    const orderRefresh = httpsCallable<OrderRefreshRequest, BaseResult>(functions, 'orderRefresh');



    const [pendingCreateOrderAMS, setPendingCreateOrderAMS] = useState<boolean>(false);
    const createAMSOrder = httpsCallable<Record<string, any>, BaseResult>(functions, "createAMSOrder");


    // const [rejectionReasonSelections, setRejectionReasonSelections] = useState<string[]>([]);

    const form = useForm<Action>({

        initialValues: {
            comments: '',
            action: "reject",
            token: "",
            customerID: customerRef?.id,
            orderID: order?.id,
            linkInfo: {
                smsMessage: "",
                updateType: "none",
                senderID: "",
                usePACI: true,
                passportRequired: false,  //TODO: this is hardcoded for now
            }
        },
    });


    const bankAccountForm = useForm<UpdateOrderRequest>({

        initialValues: {
            iban: "",
            account: "",
            transferDate: undefined,
        },

        validate: {
            iban: (value) => {
                if (!value) return "";
                if (value.length === 0) return 'Please enter IBAN';
                if (!isValidIBAN(value)) return 'Invalid IBAN';
                return null;
            },
            account: (value, values) => {
                if (!value) {
                    if (getKFHAccountNo(values.iban ?? "")) {
                        return 'Please enter account number';
                    }
                    return null;
                }
                if (value.length === 0) return 'Please enter account number';
                if (value.length !== 12) return 'Invalid account number';
                return null;
            },
            transferDate: (value) => {
                if (!value) return "Please enter transfer date";
                return null;
            },
        },
    });

    const customerFullDataDoc = doc(db, "customers", customerRef?.id as string);

    const { loading: customerLoading, data: customerFullData } = useFirestoreDoc<Customer>(customerFullDataDoc);

    //Used onSnapshot instead of 'useFirestoreDoc' because the kycStateDoc is depending on kycData
    const [kycState, setKycState] = useState<DocumentData | undefined>(undefined);
    const [kycLoading, setKycLoading] = useState<boolean>(false);
    // useEffect(() => {
    //     console.log("KYC STATE ========>>", kycData);

    //     const kycStateDoc = doc(db, `customers/${customerRef?.id}/kyc/${kycData?.id}/private`, "state");
    //     setKycLoading(true);

    //     const unsubscribe = onSnapshot(kycStateDoc, (snapshot) => {
    //         if (snapshot.metadata.fromCache) {
    //             console.log("kycState Cached data", snapshot.data());
    //         } else {
    //             console.log("kycState Server data", snapshot.data());
    //         }

    //         const data = snapshot.data();

    //         setKycState(data);
    //         console.log("KYC STATE HERE CHECK ===>>", data);
    //         setKycLoading(false);
    //     });

    //     // Cleanup function to unsubscribe when the component unmounts or dependencies change
    //     return () => {
    //         unsubscribe();
    //     };

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [kycData]);



    useEffect(() => {
        bankAccountForm.reset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearFiles])


    useEffect(() => {
        if (order.created && kycData?.created) {
            let kycDate = kycData?.created?.toDate();

            kycDate.setHours(0, 0, 0, 0);
            let orderDate = order?.created?.toDate();
            orderDate?.setHours(0, 0, 0, 0);

            if (kycDate < orderDate) {
                setOpenAccordions(["order"])
                setOutdatedKyc(true)
            }
            else {
                setOpenAccordions(["kyc", "order"])
                setOutdatedKyc(false)


            }
        }
    }, [kycData, order])


    const customPath = `orders/${order?.id}/documents`;
    const customFileName = `${kycData?.civilID}-${(kycData as KYC)?.name_en}-${order.fund.name_en}`;
    const agreementFileRef = ref(storage, `${customPath}/${customFileName}.pdf`);

    const [docURL, setDocURL] = useState<string | undefined>()
    useEffect(() => {


        const getFile = async () => {
            try {

                const url = await getDownloadURL(agreementFileRef); // Get the file's download URL

                console.log("getDownloadURL returned: " + url);

                setDocURL(url);
            } catch (error) {
                console.warn("Error fetching the file URL:", error);
            }
        }
        if (kycData) {

            getFile();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchAgreement, kycData,])
    if (kycLoading || customerLoading || !customerFullData) {

        return <p>Fetching kyc status...</p>;
    }


    const customClaims = userData.firebaseTokenResult?.claims.customClaims as CustomClaims || {};

    // generatedType={"order"}


    let kycCompleted: boolean = false;

    if (kycData?.created && order.created && (kycState?.status_code === "valid" || order.manual)) {
        //compare the day and make sure the kyc date is always the same or after the order date.  Ignore the time.
        let kycDate = kycData?.created?.toDate();


        kycDate.setHours(0, 0, 0, 0);
        let orderDate = order.created?.toDate();
        orderDate.setHours(0, 0, 0, 0);

        if (kycDate >= orderDate) {
            kycCompleted = true;
        }
    }

    //Or maybe KYC was skipped?
    if (order.skipKYC) {
        kycCompleted = true;
    }

    if (!order) {
        return <p>Fetching...</p>;
    }

    let isSubscription = order.type === "subscription";

    let orderStatusCode = order.tags?.orderStatus.code as OrderStatusCode;

    let unitsEditable: boolean = !isSubscription && userData.msal !== undefined;

    if (unitsEditable) {
        //let's make sure the units aren't editable after the order has been confirmed.

        if (orderStatusCode && [OrderStatusCode.done, OrderStatusCode.processing].includes(orderStatusCode)) {
            unitsEditable = false;
        }
        if (order.units === undefined) {
            unitsEditable = false;
        }
    }

    const orderCreatedByMe = order.rm?.email === userData.user?.email;

    const deleteButton = <Button color="red" type="button" name="delete" loading={draftActionLoading} onClick={async () => {

        try {


            //just remove the order from firestore directly and go back.
            setDraftActionLoading(true);

            await deleteDoc(orderRef);

            //set field cancelled = true in kycupdatelinks collection with that orderID field
            const q = query(collection(db, "kycupdatelinks"), where("orderID", "==", order.id));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
                await updateDoc(doc.ref, { cancelled: true });
            });

            //go back to orders
            navigate("/orders", {
                state: {
                    refreshOrdersIn: 1000,
                }
            });
            setDraftActionLoading(false);
        }
        catch (e: any) {
            setDraftActionLoading(false);

            // Check if the error is a FirebaseError and has a specific code
            if (e?.code === 'permission-denied') {
                if (isCorporateEmail(userData.user?.email ?? "")) {

                    notifications.show({
                        title: 'Permission Denied',
                        message: <>
                            You do can only delete orders with the following status/type
                            <ul>

                                <li>
                                    Need signature
                                </li>
                                <li>
                                    Commitment
                                </li>
                                <li>
                                    Manual
                                </li>
                            </ul>
                        </>,
                        color: 'red',
                    });
                }
                else {
                    notifications.show({
                        title: 'Permission Denied',
                        message: 'You do not have permission to delete this order.',
                        color: 'red',
                    });
                }

            } else {
                notifications.show({
                    title: 'Error',
                    message: 'An error has occurred.',
                    color: 'red',
                });
            }


        }
    }}>
        Delete
    </Button>;

    return (
        <>

            <>
                <Modal
                    opened={rejectionOpened}
                    onClose={() => { setRejectionOpened(false); setConfirmDialogError(null); }}
                    title="Reject KYC"
                >

                    <form onSubmit={form.onSubmit(async (values) => {
                        console.log(values);

                        //get token, somehow.
                        setConfirming(true);
                        setConfirmDialogError(null);


                        try {
                            // console.log("Msal is!", userData.msal);

                            let token = await userData.getAzureADToken!(userData);
                            console.log("Token", token);

                            if (!token) {
                                setIsError(true);
                                console.log("No Microsoft Token");
                                setMsg("No Microsoft Token.  Try refreshing.");
                                return;
                            }

                            //wtf I can use nullables?
                            //ok so action will be reject if updateType is none
                            console.log("Update type", values);
                            let action = values.action; // !== "none" ? "needs_update" : "reject" as ActionType;

                            if (isRejectingOrder) {
                                const resOrder: any = await orderAction({
                                    action: action,
                                    orderID: order.id,
                                    customerID: customerRef.id,
                                    token: token,
                                    comments: values.comments,
                                    sendSMS: sendSMS,
                                    linkInfo: {
                                        smsMessage: values.linkInfo?.smsMessage,
                                        updateType: values.linkInfo?.updateType,
                                        senderID: values.linkInfo?.senderID,
                                        usePACI: usePaci,
                                    }
                                });
                                console.log(resOrder);
                                setRefreshing(true);
                                await orderRefresh({ orderID: order.id! });
                                setRefreshing(false);
                                console.warn("Order refreshed!");
                            } else {

                                const resKYC = await kycAction({
                                    action: action,
                                    kycID: kycData?.id,
                                    customerID: customerRef.id,
                                    token: token,
                                    comments: values.comments,
                                    sendSMS: sendSMS,
                                    linkInfo: {
                                        smsMessage: values.linkInfo?.smsMessage,
                                        updateType: values.linkInfo?.updateType,
                                        senderID: values.linkInfo?.senderID,
                                        usePACI: usePaci,
                                    }
                                });
                                console.log(resKYC);
                            }

                            // setIsError(true);
                            // setMsg("Rejected");
                            setRejectionOpened(false)

                        } catch (error: any) {
                            // setIsError(true);
                            console.log(error);
                            // setMsg(error.message);
                            setConfirmDialogError(error.message);

                        }

                        setConfirming(false);
                    })}>

                        {/* <MultiSelect
                                data={[{ value: "missing_documents", label: "Missing documents" }]}
                                label="Rejection reasons"
                                placeholder="Choose reasons"
                                value={rejectionReasonSelections} onChange={setRejectionReasonSelections}
                            /> */}

                        <Space h="sm" />
                        <Group ta="end">
                            <TextInput id="" placeholder="Comments" style={{ flex: 1 }} {...form.getInputProps('comments')} />
                        </Group>
                        <Space h="sm" />

                        <Grid ta="center">
                            <Grid.Col span={6}>
                                <Checkbox
                                    label="Send SMS"
                                    checked={sendSMS}
                                    onChange={(e) => setSendSMS(e.target.checked)}
                                />
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Checkbox
                                    disabled={!sendSMS}

                                    label="Use PACI"
                                    checked={usePaci}
                                    onChange={(e) => setUsePaci(e.target.checked)}
                                />
                            </Grid.Col>
                            {sendSMS && <Grid.Col span={12}>
                                <SenderIDSelect
                                    {...form.getInputProps("linkInfo.senderID")}
                                    label="Sender Identity"
                                />
                            </Grid.Col>}


                            <Grid.Col span={12}>
                                <Textarea
                                    disabled={!sendSMS}
                                    placeholder="Type in SMS message"
                                    label="Message"
                                    {...form.getInputProps('linkInfo.smsMessage')}
                                    minRows={2}
                                    autosize
                                />
                            </Grid.Col>
                        </Grid>
                        {/* </Group> */}
                        <Space h="sm" />
                        <Radio.Group
                            label="Add update link"
                            description="Allow client to update his KYC?"
                            // gap="sm"
                            {...form.getInputProps('linkInfo.updateType')}
                        >
                            <Radio disabled={!sendSMS} value="none" label="None" defaultChecked />
                            <Radio disabled={!sendSMS} value="update" label="Update" />
                            <Radio disabled={!sendSMS} value="upload_docs" label="Upload docs" />
                        </Radio.Group>
                        <Space h="xl" />

                        <Button color="red" type="submit" loading={confirming}>Reject</Button>
                        {confirmDialogError && <Alert icon={<IconAlertCircle size={16} />} title="Whoops!" color="red">{confirmDialogError}</Alert>}

                    </form>
                </Modal>
                <Modal
                    opened={confirmDialog != null}
                    onClose={() => { setConfirmDialog(null); setConfirmDialogError(null); }}
                    title={<Title order={3}>{confirmDialog?.title}</Title>}
                >

                    <form onSubmit={form.onSubmit(async (values) => {
                        setConfirmDialogError(null);
                        setConfirming(true);
                        try {
                            await confirmDialog?.callback(values);
                            setConfirmDialog(null);
                        } catch (error: any) {
                            setConfirmDialogError(error.message);
                        }
                        setConfirming(false);

                    })}>
                        <Stack>

                            <Text fw={300}>{confirmDialog?.description}</Text>
                            {/* <Space h="xl"/> */}
                            {confirmDialog?.textField && <><Text>{confirmDialog?.textFieldDescription}</Text><TextInput  {...form.getInputProps(confirmDialog.textField)} /></>}
                            <Button color={confirmDialog?.buttonColor} type="submit" loading={confirming}>{confirmDialog?.action}</Button>
                            {confirmDialogError && <Alert icon={<IconAlertCircle size={16} />} title="Whoops!" c="red">{confirmDialogError}</Alert>}
                        </Stack>
                    </form>
                </Modal>

            </>
            <Space h="xl" />


            <Group justify='space-between'>
                {/* {kycData && <Title><Text tt='capitalize'>{kycData!.name_en}</Text></Title>} */}
                <MyUserDataContext.Consumer>
                    {(value) =>
                        <Group>

                            <Menu shadow="md" width={200}>
                                <Menu.Target>
                                    <Button
                                        color="violet" loading={pending}
                                        disabled={!kycCompleted && !order.manual}
                                    >
                                        Generate PDF
                                    </Button>
                                </Menu.Target>

                                <Menu.Dropdown>
                                    <Menu.Label>Document</Menu.Label>
                                    <Menu.Item
                                        disabled={!docURL}
                                        component="a"
                                        href={docURL}
                                        target="_blank"

                                    >
                                        View Agreement
                                    </Menu.Item>

                                    <Menu.Divider />

                                    <Menu.Label>Actions</Menu.Label>
                                    <Menu.Item
                                        disabled={!kycCompleted || order.tags?.orderStatus?.code == OrderStatusCode.waiting_certified_rm}
                                        onClick={

                                            () => {
                                                if (order.manual) {
                                                    setGenerateFilledPDFOpen(true)
                                                } else {
                                                    setGeneratePDFOpen(true)
                                                }
                                            }

                                        }
                                    >
                                        Generate  Agreement Document
                                    </Menu.Item>
                                    {order.manual && <Menu.Item
                                        onClick={
                                            () => {
                                                setGeneratePDFOpen(true)
                                            }
                                        }
                                    >
                                        Generate Empty Form
                                    </Menu.Item>}
                                    <Menu.Item
                                        disabled
                                        color="red"
                                        onClick={

                                            () => openConfirmModal({
                                                title: 'confirm',
                                                children: (
                                                    <>
                                                        <Title order={3} c="red">
                                                            Are you sure you want to send the docuemnt to the client
                                                        </Title>
                                                        <Text size="xl" c="red">
                                                            This Action cannot be reverted
                                                        </Text>
                                                    </>
                                                ),
                                                labels: { confirm: 'Send', cancel: 'Cancel' },
                                                confirmProps: {
                                                    disabled: true
                                                }

                                            })

                                        }

                                    >
                                        Send to client
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>


                            {
                                userClaims["admin"] &&
                                <Button
                                    color="yellow"
                                    loading={pendingCreateOrderAMS}
                                    disabled={customerFullData?.tags?.kycStatus?.code !== "valid" && false}
                                    type="button"
                                    name="create_account"
                                    onClick={async () => {
                                        //send API request to create AMS
                                        setPendingCreateOrderAMS(true);
                                        try {
                                            let res = await createAMSOrder({ order: order, kyc: kycData, customer: customerFullData });

                                            console.log("Result from createAMS", res);
                                        } catch (error: any) {
                                            showNotification({
                                                id: "error",
                                                color: "red",
                                                icon: <IconX />,
                                                withBorder: true,
                                                // loading: true,
                                                message: `Error creating order: ${error.message}`,
                                                // message: 'Data will be loaded in 3 seconds, you cannot close this yet',
                                                // autoClose: true,
                                                // disallowClose: true,
                                            });

                                        }
                                        setPendingCreateOrderAMS(false);

                                    }}
                                >
                                    Create AMS Order
                                </Button>
                            }



                            < GeneratePDFModal
                                getCustomerDataKey={"customerObj"}

                                // fromServer={true}
                                customPath={customPath}
                                // generatedType={"order"}
                                customFileName={customFileName}
                                objID={order.id}
                                onGenerateComplete={() =>
                                    setRefetchAgreement(!refetchAgreement)
                                    // window.location.reload()
                                }
                                rows={[{


                                    ...order,
                                    ...kycData,
                                    customerID: customerFullData?.id,
                                    id: order.id,
                                    iban: order.iban,
                                    documentActions: actionsAfterRejections,
                                    address: {
                                        ...kycData?.address, "DetailsEnglish":
                                            kycData?.address ? getAddressDetailsEnglish(kycData?.address)
                                                : "",
                                    },

                                }]}



                                // getCustomerDataKey={null}
                                documentsToFetch={
                                    [{
                                        name: "orders",
                                        subCollectionAccessorName: "id",
                                        documentsToAddFunction: (orderObj: GenericProduct) => {
                                            // Return an empty array for manual orders or when type or fund is not defined
                                            if (order.manual || !orderObj?.type || !orderObj?.fund) return [];

                                            let files = ordersPath[orderObj?.type as SubscriptionType][orderObj.fund.id];
                                            const fundsMap = globalDataContext?.fundsMap;

                                            if (fundsMap) {
                                                const orderPath = fundsMap[orderObj.fund.id]?.orders_path;
                                                if (orderObj?.type === "subscription") {
                                                    files = orderPath?.subscription ?? files;
                                                } else if (orderObj?.type === "redemption") {
                                                    files = orderPath?.redemption ?? files;
                                                }
                                            }

                                            return files;
                                        },
                                        type: "collectionGroup"

                                    }]
                                }

                                metaTypes={metaTypes}
                                manual={order.manual}
                                //set the kyc id to be used in case of manual as in that case we dont use latest valid kyc
                                manualKYCId={kycData?.id}
                                opened={generatePDFOpen}
                                setOpened={setGeneratePDFOpen}
                                manualEmptyFiles={emptyOrderFilePath}


                            />
                            <GeneratePDFModal
                                getCustomerDataKey={"customerObj"}
                                // generatedType={"order"}
                                objID={order.id}
                                rows={[{
                                    ...order,
                                    ...kycData,
                                    customerID: customerFullData?.id,
                                    id: order.id,
                                    iban: order.iban,
                                    documentActions: actionsAfterRejections,
                                    address: {
                                        ...kycData?.address, "DetailsEnglish":
                                            kycData?.address ? getAddressDetailsEnglish(kycData?.address)
                                                : "",
                                    },
                                }]}



                                // getCustomerDataKey={null}
                                documentsToFetch={
                                    [{
                                        name: "orders",
                                        subCollectionAccessorName: "id",
                                        documentsToAddFunction: (orderObj: GenericProduct) => {
                                            //if order is manual then in that case this modal will be used to generate empty forms there for we return [] and add the list of document needed in manualEmptyFiles
                                            if (!orderObj?.type || !orderObj?.fund) return [];

                                            let files = ordersPath[orderObj?.type as SubscriptionType][orderObj.fund.id];
                                            const fundsMap = globalDataContext?.fundsMap;

                                            if (fundsMap) {
                                                const orderPath = fundsMap[orderObj.fund.id]?.orders_path;
                                                if (orderObj?.type === "subscription") {
                                                    files = orderPath?.subscription ?? files;
                                                } else if (orderObj?.type === "redemption") {
                                                    files = orderPath?.redemption ?? files;
                                                }
                                            }

                                            return files;
                                        },
                                        type: "collectionGroup"
                                    }]
                                }
                                metaTypes={metaTypes}
                                manual={order.manual}
                                withFilledKYC={true}
                                //set the kyc id to be used in case of manual as in that case we dont use latest valid kyc
                                manualKYCId={kycData?.id}
                                opened={generateFilledPDFOpen}
                                setOpened={setGenerateFilledPDFOpen}


                            />
                            {!kycCompleted && <Text fs="italic" c="dimmed">Please complete KYC first!</Text>}

                            {/* {value.user?.email === "yalhusayan@kfhcapital.com.kw" && */}
                            <Button color="green" loading={refreshing} type="button" onClick={

                                async () => {

                                    setRefreshing(true);
                                    await orderRefresh({ orderID: order.id! });
                                    setRefreshing(false);
                                    console.warn("Order refreshed!");
                                }

                            }>
                                Refresh Order
                            </Button>

                            <Button onClick={() => setUploadSlipOpened(true)} disabled={orderStatusCode === "rejected"}>

                                Upload Slip {orderStatusCode === "rejected" ? " (Order Rejected)" : ""}
                            </Button>

                            {order.manual && <Button color='pink' onClick={() => setUploadFinalSignedAgreementOpened(true)} disabled={orderStatusCode === "rejected"}>
                                Upload Agreement
                            </Button>}



                            <Modal
                                size={"xl"}
                                opened={uploadSlipOpened}
                                onClose={() => {

                                    //remove any uploaded slips
                                    bankAccountForm.values.slipFilePaths?.forEach((slipPath) => {
                                        ///
                                        const desertRef = ref(storage, `${slipPath}`);

                                        deleteObject(desertRef).then(() => {
                                            orderRefresh({ orderID: order.id! }).then(() => {

                                                setForceRefreshFiles(Math.random())

                                            });

                                            // File deleted successfully
                                        }).catch((error) => {

                                            console.error(error);

                                            // Uh-oh, an error occurred!
                                        });
                                    })


                                    setUploadSlipOpened(false);
                                }}
                                title="Upload Slip"
                            >

                                <form onSubmit={bankAccountForm.onSubmit(async (values) => {

                                    if (!values.iban || !values.transferDate) {
                                        // setIsError(true);
                                        // setMsg("Please fill out all fields");
                                        return;
                                    }

                                    setPending(true);

                                    try {
                                        await updateDoc(orderRef, {
                                            iban: values.iban, acct: values.account, transferDate: Timestamp.fromDate(values.transferDate)
                                        });

                                        console.warn("Order refreshed!");
                                        setPending(false);
                                        setForceRefreshFiles(Math.random())
                                        orderRefresh({ orderID: order.id! });
                                        bankAccountForm.reset()
                                        setUploadSlipOpened(false)
                                    } catch (error) {
                                        setPending(false);
                                        orderRefresh({ orderID: order.id! });
                                        //TODO: show an error to the user at least.
                                    }
                                    setForceRefreshFiles(Math.random())
                                    setPending(false);

                                })}>
                                    <BankAccountInfo
                                        ibanProps={
                                            {
                                                ...bankAccountForm.getInputProps("iban")
                                                ,
                                                required: true
                                            }

                                        }
                                        transferDateProps={
                                            {
                                                ...bankAccountForm.getInputProps("transferDate"),
                                                required: true
                                            }
                                        }
                                        acctNumberProps={
                                            { ...bankAccountForm.getInputProps("account"), }
                                        }
                                        orderID={order.id}
                                        onBlurIban={
                                            () => {
                                                console.log("Blurring!");
                                                if (bankAccountForm.values?.iban && bankAccountForm.values.iban.length === 30) {
                                                    let acct = getKFHAccountNo(bankAccountForm.values.iban);
                                                    if (acct) {
                                                        bankAccountForm.setFieldValue("account", acct);
                                                    }
                                                }
                                            }
                                        }

                                        onBlurAccountNumber={
                                            () => {
                                                if (bankAccountForm.values.account && bankAccountForm.values.account.length === 12) {
                                                    bankAccountForm.setFieldValue("iban", generateIBANFromKFHAccountNo(bankAccountForm.values.account) || "");
                                                }
                                            }

                                        }

                                        handleUploadResponse={
                                            (response: UploadFileResult, filesName: string[], setUploadedFiles?: (values?: FileWithPath[]) => void, filesList?: CustomFileWithPath[], currentFiles?: FileWithPath[]) => {


                                                filesName.map((fileName: string) => {
                                                    const fileInfo = response.info![fileName];
                                                    console.log(fileInfo)
                                                    const newfileNAme = (fileInfo as CustomFileWithPath).serverPath?.split("/").pop()//temp server path
                                                    const fileServerPath = `orders/${order.id}/${newfileNAme}`;


                                                    if (fileInfo && setUploadedFiles) {
                                                        if (filesList) {

                                                            setUploadedFiles(
                                                                currentFiles?.concat({ ...fileInfo, name: fileName, serverPath: fileServerPath })
                                                            )
                                                        }
                                                    }

                                                    if (fileInfo.metadata?.acct) bankAccountForm.setFieldValue("account", fileInfo.metadata?.acct);
                                                    if (fileInfo.metadata?.iban) bankAccountForm.setFieldValue("iban", fileInfo.metadata?.iban);

                                                    if (fileInfo.metadata?.amount) bankAccountForm.setFieldValue("transferAmount", fileInfo.metadata?.amount);

                                                    if (fileInfo.metadata?.transferDate) {
                                                        let d = fileInfo.metadata?.transferDate.split("/");
                                                        let transferDate = new Date(`${d[1]}-${d[0]}-${d[2]}`);
                                                        console.log("Setting transfer date to", transferDate);
                                                        bankAccountForm.setFieldValue("transferDate", transferDate);
                                                    }

                                                    return fileInfo.filepath;
                                                })
                                                if (response.filePaths?.length) {
                                                    bankAccountForm.setFieldValue("slipFilePaths", response.filePaths)

                                                }
                                                else {

                                                    bankAccountForm.setFieldValue("slipFilePaths", response.info!.filepaths)
                                                }



                                            }

                                        }

                                        setIsError={setIsError}
                                        setMsg={setMsg}
                                        clearFiles={clearFiles}
                                        onRemoveFile={
                                            (file) => {
                                                {
                                                    const desertRef = ref(storage, `${(file as CustomFileWithPath).serverPath}`);

                                                    // Delete the file
                                                    deleteObject(desertRef).then(() => {
                                                        orderRefresh({ orderID: order.id! }).then(() => {

                                                            setForceRefreshFiles(Math.random())


                                                        });

                                                        // File deleted successfully
                                                    }).catch((error) => {

                                                        console.error(error);

                                                        // Uh-oh, an error occurred!
                                                    });
                                                }
                                                bankAccountForm.setFieldValue("slipFilePaths",
                                                    bankAccountForm.values.slipFilePaths?.filter((path: string) => (path?.split("/").pop() !== (file as CustomFileWithPath).serverPath?.split("/").pop())
                                                    ))


                                                // orderRefresh({ orderID: order.id! });


                                            }
                                        }

                                    />




                                    {
                                        msg != null &&   //love my check if it's an error or not.  Please don't do this, it's just a proof of concept page.
                                        <Alert color={isError ? "red" : "green"}>
                                            {msg}
                                        </Alert>
                                    }

                                    <Group justify="center" mt="md">
                                        <Button
                                            type="submit"
                                            loading={pending}
                                        // disabled={isSubmitted}
                                        >
                                            Upload Slip
                                        </Button>
                                        <Button
                                            loading={pending}
                                            color="red" type="button"
                                            // disabled={isSubmitted}
                                            onClick={() => {
                                                setClearFiles(Math.random())
                                                //remove any uploaded slips
                                                bankAccountForm.values.slipFilePaths?.forEach((slipPath) => {
                                                    ///
                                                    const desertRef = ref(storage, `${slipPath}`);

                                                    deleteObject(desertRef).then(() => {
                                                        orderRefresh({ orderID: order.id! }).then(() => {

                                                            setForceRefreshFiles(Math.random())

                                                        });

                                                        // File deleted successfully
                                                    }).catch((error) => {

                                                        console.error(error);

                                                        // Uh-oh, an error occurred!
                                                    });
                                                })

                                                setUploadSlipOpened(false)
                                                setMsg(null)

                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Group>
                                </form>
                            </Modal>
                            <Modal
                                size={'xl'}
                                opened={uploadFinalSignedAgreementOpened}
                                onClose={() => { setUploadFinalSignedAgreementOpened(false); }}
                                title="Signed Agreement"
                            >
                                <Card withBorder>
                                    <UploadBox
                                        loading={finalSignedAgreementLoading}
                                        label="Drag Signed Agreement here or click to select file"
                                        multiple={false}
                                        onDropAny={async (files, filesRejected: FileRejection[], setUploadedFiles, uploadedFiles?: FileWithPath[]) => {

                                            if (!files || files?.length === 0) return;
                                            console.log("Files dropped!", files, filesRejected, uploadedFiles);

                                            setFinalSignedAgreementLoading(true);
                                            //upload file to fileUpload endpoint
                                            const promises = files.map(async (file) => {
                                                let tags: Record<string, string> = {};
                                                tags.finalSignedAgreement = 'true';
                                                if (order.id) {
                                                    await uploadToStorage(file, 'orders/' + order.id, tags);
                                                }
                                                if (customerFullData.id) {
                                                    await uploadToStorage(file, 'kyc/' + customerFullData.id, tags);
                                                }
                                                if (agreementFileRef) {
                                                    await uploadBytesResumable(ref(agreementFileRef, customFileName), file);
                                                }
                                            });

                                            await Promise.all(promises);
                                            // const messaging = getMessaging(getApp());

                                            //wait 6 seconds.  I can't know when the thumbnail is ready.
                                            await new Promise((resolve) => {
                                                setTimeout(() => {
                                                    resolve(true);
                                                }, 6000);
                                            });
                                            setUploadFinalSignedAgreementOpened(false);
                                            setFinalSignedAgreementLoading(false);
                                        }}
                                    />
                                </Card>
                            </Modal>

                        </Group>

                    }
                </MyUserDataContext.Consumer>

            </Group>

            <Space h="xl" />
            <Accordion multiple variant='contained' transitionDuration={1000}
                // defaultValue={['kyc', 'order']}

                value={openAccordions}
                onChange={setOpenAccordions}



            >
                <Accordion.Item key="kyc" value="kyc" >
                    <Accordion.Control disabled={!(kycState && customerFullData)} >
                        <Group p="apart">
                            <ThemeIcon
                                variant="gradient"
                                size="xl"
                                aria-label="Gradient action icon"
                                gradient={{ from: 'red', to: 'grape', deg: 180 }}
                            >
                                <IconScript size={30} />
                            </ThemeIcon>
                            <Text
                                component="span"
                                ta="center"
                                variant="gradient"
                                gradient={{ from: 'red', to: 'grape', deg: 180 }}
                                size="xl"
                                fw={700}
                            // style={{ fontFamily: 'Greycliff CF, sans-serif' }}
                            >
                                KYC Information ({
                                    // kycCompleted ? kycTags?.title : 
                                    kycState?.status ||
                                    "No KYC"})
                            </Text>
                            <Text component='span'
                                c="red"
                                fw={700}

                            >
                                {outdatedKyc ? "--Too old--" : ""}
                            </Text>
                        </Group>

                    </Accordion.Control>
                    <Accordion.Panel>

                        {/* below is the old disableUpload condition */}
                        {/* kycState?.status_code === "rejected" || orderStatusCode === "rejected" */}
                        <CustomerKYC
                            kycID={kycData?.id}
                            metaTypes={metaTypes} setMetaTypes={setMetaTypes} disableUpload={orderStatusCode === "rejected"} customerData={customerFullData!} showButton={true} showActiveLinks={true} showKycVersion={false} showGeneratePDF={false} />
                        {/* )} */}
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item key="order" value="order">
                    <Accordion.Control >
                        <Group p="apart">
                            <ThemeIcon
                                variant="gradient"
                                size="xl"
                                aria-label="Gradient action icon"
                                gradient={{ from: 'red', to: 'grape', deg: 180 }}
                            >
                                <IconCreditCard size={30} />
                            </ThemeIcon>
                            <Text
                                component="span"
                                ta="center"
                                variant="gradient"
                                gradient={{ from: 'red', to: 'grape', deg: 180 }}
                                size="xl"
                                fw={700}
                                style={{ fontFamily: 'Greycliff CF, sans-serif' }}
                            >
                                Order Information
                            </Text>
                        </Group>
                    </Accordion.Control>
                    <Accordion.Panel>


                        <Space h="xl" />


                        {order &&

                            <SimpleGrid cols={3}>

                                <EditableInfoCard
                                    title="Order Information"
                                    color="teal"
                                    itemDoc={orderDoc}
                                    updatedItemData={updatedOrderData}
                                    icon={<IconShoppingCart size={16} />}
                                    list={[

                                        {
                                            label: 'Date',
                                            value: order.created?.toDate().toDateString()
                                        },

                                        {
                                            label: 'Fund',
                                            value: order.fund.name_en
                                        },
                                        {

                                            label: 'Type',
                                            value: order.type
                                        },
                                        {
                                            label: "KYC Status",
                                            value:

                                                <Link to={`/customers/${customerRef?.id}`}>

                                                    {
                                                        // kycCompleted ? <Text c="green">{kycTags?.title}</Text> : 
                                                        <Text c="yellow">{kycState?.status || "No KYC"}</Text>}
                                                </Link>

                                        },
                                        {

                                            label: 'KYC ID',
                                            value: kycData?.id,
                                            noCapitalize: true,
                                        },

                                        {
                                            label: 'Transfer Date',

                                            value: isSubscription ? order.transferDate?.toDate().toDateString() : undefined  //this has been a source of many crashes.

                                        },

                                        {
                                            label: 'Approval Date',
                                            value: order.approvedAt?.toDate().toDateString()
                                        },

                                        {
                                            label: 'Transfer Amount',
                                            editable: userClaims["admin"] && order.fund.fees_included_in_transferred_amount && order.type === "subscription" && order.tags?.orderStatus.code !== OrderStatusCode.processing,
                                            fieldName: "transferAmount",
                                            componentType: "NumberInput",
                                            handleChange: (value) => {

                                                const numValue = Number(value)
                                                order.fund = {
                                                    ...order.fund,
                                                    ...latestFundData
                                                };

                                                const result = calc(
                                                    order,
                                                    undefined,
                                                    numValue,
                                                    undefined,
                                                    undefined
                                                )


                                                return { units: result?.amt || 0, fund: order.fund };
                                            },
                                            value: <>{order.type === "subscription" && <Text span fw={500} size="sm" ta="right"><NumberFormatter thousandSeparator value={order.transferAmount ?? 0} suffix={" " + order.fund.currency} /></Text>}</>,
                                        },

                                        {
                                            label: 'NAV Date',
                                            value: order.nav_date?.toDate().toDateString()   //this has been a source of many crashes.
                                        },
                                        {
                                            label: 'NAV per unit',
                                            value: order.nav_per_unit ? <Text fw={500} size="sm" ta="right"><NumberFormatter thousandSeparator value={order.nav_per_unit} suffix={" " + order.fund.currency} /></Text> : undefined
                                        },

                                        {
                                            label: 'Subscription Amount',
                                            editable: userClaims["admin"] && !order.fund.fees_included_in_transferred_amount && order.type === "subscription",
                                            fieldName: "postNavAmount",
                                            componentType: "NumberInput",
                                            handleChange: (value) => {
                                                const numValue = Number(value)
                                                let fees_percent = 0;
                                                order.fund = {
                                                    ...order.fund,
                                                    ...latestFundData
                                                };

                                                if (order.fund.subscription_tiers) {
                                                    fees_percent = ((order.fund.subscription_tiers.find(t => numValue >= t.from && numValue <= t.to)?.fees || 0))
                                                } else if (order.fund?.subscription_fees_percent) {
                                                    fees_percent = order.fund?.subscription_fees_percent;
                                                }

                                                const result = calc(
                                                    order,
                                                    undefined,
                                                    numValue,
                                                    undefined,
                                                    undefined
                                                )
                                                let fees = numValue * (fees_percent / 100);

                                                let transferAmount = numValue + fees
                                                return { units: result?.amt || 0, fees: fees, transferAmount: transferAmount, fund: order.fund };
                                            },
                                            value: <Text span fw={500} size="sm" ta="right"><NumberFormatter thousandSeparator value={order.postNavAmount} suffix={" " + order.fund.currency} /></Text>
                                        },
                                        {
                                            label: 'Fees',
                                            value: order.fees ? <Text span fw={500} size="sm" ta="right"><NumberFormatter thousandSeparator value={order.fees} suffix={" " + order.fund.currency} /></Text> : undefined
                                        },
                                        {
                                            label: order.units ? 'Units' : 'Estimated Units',
                                            value: <>
                                                <Text span fw={500} size="sm" ta="right">

                                                    {order.units === 0 ? "All Units" :
                                                        <NumberFormatter thousandSeparator value={order.units ?? order.estimated_units} />}

                                                </Text>
                                                {unitsEditable &&
                                                    <>


                                                        <ActionIcon variant="white" aria-label="Edit units" onClick={() => {
                                                            modals.open({
                                                                title: 'Edit number of units',
                                                                children: (
                                                                    <EditUnits orderRef={orderRef} startingUnits={order.units || 0} />
                                                                ),
                                                            });
                                                        }}>
                                                            <IconEdit style={{ width: '70%', height: '70%' }} stroke={1.5} />
                                                        </ActionIcon>
                                                        {order.units === 0 &&

                                                            <Text size="xs" c="red" ta="right">(Please enter all available units)</Text>

                                                        }
                                                    </>
                                                }
                                            </>
                                        },

                                        {
                                            label: 'Surplus',

                                            value: isSubscription && order.surplus && order.surplus > 0 ? <Text fw={500} size="sm" ta="right"><NumberFormatter thousandSeparator value={order.surplus} suffix={" " + order.fund.currency} /></Text> : undefined

                                        },
                                        {
                                            label: 'Voting',

                                            value: order.voting === undefined
                                                ? undefined
                                                : order.voting
                                                    ? <ThemeIcon radius="xl" size="xs" color="green">
                                                        {" "}
                                                        <IconCheck size={12} />{" "}
                                                    </ThemeIcon>
                                                    : <ThemeIcon radius="xl" size="xs" color="red">
                                                        <IconX size={12} />
                                                    </ThemeIcon>

                                        },

                                    ]}

                                />





                                <CustomInfoCard
                                    title="Bank Account Information"
                                    color="red"
                                    attention={order.ibanVerified === false}
                                    icon={
                                        <IconBuildingBank size={16} />
                                    }
                                    list={[

                                        {
                                            label: 'IBAN',
                                            value: order.iban,
                                            noCapitalize: true,

                                        },
                                        {
                                            label: 'Account',
                                            value: order.acct,
                                            noCapitalize: true,
                                        },
                                        {
                                            label: 'Verified',
                                            value: order.ibanVerified,
                                            noCapitalize: true,
                                        },

                                    ]}
                                />


                                <CustomInfoCard
                                    title="Client Information"
                                    color="yellow"
                                    icon={<IconPhone size={16} />}
                                    list={[
                                        {
                                            label: "Name (en)",
                                            value: customerFullData?.name_en,
                                        },
                                        {
                                            label: "Name (ar)",
                                            value: customerFullData?.name_ar,
                                        },
                                        {
                                            label: "Commercial Register",
                                            value: customerFullData?.commercial_register,
                                        },

                                        {
                                            label: "Civil ID",
                                            value: customerFullData?.civilID,
                                        },
                                        {
                                            label: "Civil ID Expiry",
                                            value: paciData?.civilIDExpiryDate?.toDate().toLocaleDateString("en-gb")
                                        },

                                        {
                                            label: "Mobile",
                                            value: customerFullData?.mobile,
                                        },
                                        {
                                            label: "Nationality",
                                            value: customerFullData?.nationality,
                                        },
                                        {
                                            label: "Gender",
                                            value: customerFullData?.gender,
                                        },
                                        {
                                            label: "PACI Sponsor",
                                            value: order.paciData?.PersonalData?.GovData?.SponsorName || undefined
                                        },
                                        {
                                            label: "PACI Mobile",
                                            value: order.paciData?.PersonalData?.MobileNumber || undefined
                                        },
                                        {
                                            label: "PACI Email",
                                            value: order.paciData?.PersonalData?.EmailAddress || undefined,
                                            noCapitalize: true,
                                        },

                                        {
                                            label: "Address (en)",
                                            value: paciData?.address?.DetailsEnglish,
                                        },

                                        {
                                            label: "Address (ar)",
                                            value: paciData?.address?.DetailsArabic,
                                        },
                                        {
                                            label: "Address",
                                            value: paciData?.address ? getAddressDetailsEnglish(paciData?.address) : null,
                                        },
                                        // ...Object.keys(data?.address).map((key) => {return {label: key, value: data?.address[key]}} )
                                    ]}
                                />


                                <CustomInfoCard
                                    title="RM Information"
                                    color="blue"
                                    icon={<IconUsers size={16} />}
                                    list={[

                                        {
                                            label: 'RM Name',
                                            value: <Text tt="capitalize" className="showBtnOnHover" fw={500} size="sm" ta="right">
                                                <>
                                                    {order.rm?.name_en || "-"}


                                                    {userClaims["admin"] && <ActionIcon variant="white" ml={4} aria-label="Change RM" onClick={() => setChangeRmOpen(true)}>
                                                        <IconEdit style={{ width: '70%', height: '70%' }} stroke={1.5} />
                                                    </ActionIcon>}
                                                </>
                                            </Text>
                                        },
                                        {
                                            label: 'Segment',
                                            value: order.rm?.segment
                                        },
                                        {
                                            label: 'RM Email',
                                            value: order.rm?.email,
                                            noCapitalize: true,

                                        },
                                        {
                                            label: 'Submitted By',
                                            value: order.submittedBy?.name_en
                                        },
                                        {
                                            label: 'Submitter Email',
                                            value: order.submittedBy?.email,
                                            noCapitalize: true,
                                        },
                                        {
                                            label: 'Referred By',
                                            value: <Text tt="capitalize" className="showBtnOnHover" fw={500} size="sm" ta="right">
                                                <>
                                                    {order.referredBy?.name_en || "-"}


                                                    {userClaims["admin"] && <ActionIcon variant="white" ml={4} aria-label="Change Referred by RM" onClick={() => setChangeReferredByRmOpen(true)}>
                                                        <IconEdit style={{ width: '70%', height: '70%' }} stroke={1.5} />
                                                    </ActionIcon>}
                                                </>
                                            </Text>
                                        },
                                        {
                                            label: 'Referrer Email',
                                            value: order.referredBy?.email,
                                            noCapitalize: true,
                                        },

                                    ]}
                                />


                                {order.signature &&


                                    <CustomInfoCard
                                        title="Signature"
                                        color="violet"
                                        icon={
                                            <IconSchool size={16} />
                                        }
                                        list={[

                                            {
                                                label: '',
                                                value: order.signature && <Image
                                                    fit="contain"
                                                    // w="auto"
                                                    // h={150}



                                                    src={`data:image/png;base64,${order.signature}`}
                                                />
                                            },


                                        ]}
                                    />
                                }
                            </SimpleGrid>
                        }

                        {/* Show photos from firebase storage */}
                        <Space h="xl" />

                        <Stack>
                            {
                                <>
                                    <Title order={3}>Files</Title>
                                    <FilesList forceRefresh={forceRefreshFiles}
                                        setMetaTypes={setMetaTypes}
                                        path={`orders/${id}/`}
                                        links={links}
                                        setLinks={setLinks}
                                        onChange={() => {
                                            orderRefresh({ orderID: order.id! });
                                            // if (order.id) {
                                            //     areAllFolderFilesTagged(`orders/${order.id}/`).then((res) => {
                                            //         setAllFilesTagged(res);
                                            //     }).catch((err) => {
                                            //         setAllFilesTagged(false);
                                            //     });
                                            // };

                                        }}
                                        showUploader={false}
                                    />
                                </>}
                            {/* show actions taken on this KYC */}

                            {actions && actions.length > 0 &&
                                <>
                                    <Title order={3}>Actions</Title>


                                    {/* <Container size="xl"> */}
                                    <Timeline active={100} bulletSize={24} lineWidth={2} reverseActive>

                                        {

                                            actions.map((action, index: number) => {
                                                // console.log(action.id, index);
                                                let verb = "Unknown";
                                                let color = "gray";
                                                let icon = <IconMessageDots size={12} />;


                                                if (action.type === "reject") {
                                                    verb = "Rejected";
                                                    color = "red";
                                                    icon = <IconThumbDown size={12} />;
                                                }
                                                else if (action.type === "needs_update") {
                                                    verb = "Needs update";
                                                    color = "yellow";
                                                    icon = <IconGitCommit size={12} />;
                                                }
                                                else if (action.type === "review") {
                                                    verb = "Reviewed";
                                                    color = "cyan";
                                                    icon = <IconEye size={12} />;
                                                }
                                                else if (action.type === "operations_approve") {
                                                    verb = "Operations Approved";
                                                    color = "indigo";
                                                    icon = <IconEye size={12} />;
                                                }
                                                else if (action.type === "approve") {
                                                    verb = "Approved";
                                                    color = "green";
                                                    icon = <IconThumbUp size={12} />;
                                                    if (action.level === "executive") {
                                                        verb = "Approved";
                                                        color = "grape";
                                                        icon = <IconStar size={12} />;

                                                    }
                                                }
                                                else if (action.type === "update") {
                                                    verb = "Updated";
                                                    color = "blue";
                                                    icon = <IconEdit size={12} />;
                                                }

                                                return <Timeline.Item bullet={icon} title={verb} color={color} key={index}>
                                                    <Text c="dimmed" size="sm">{`${verb} by ${action.name}`}</Text>
                                                    {action.comments && <Text c="violet" size="sm">{action.comments}</Text>}
                                                    <Text size="xs" mt={4}>{new Date(action.date.seconds * 1000).toLocaleString("en-GB", { hour12: true })}</Text>
                                                </Timeline.Item>

                                            }).reverse()  /* reverse so the latest action is at the top */
                                        }
                                    </Timeline>
                                    {/* </Container> */}
                                </>
                            }
                        </Stack>

                        <Grid w="100%" >



                            {orderStatusCode !== "commitment" &&

                                <Grid.Col span={4}>
                                    <ConfigContext.Consumer>
                                        {
                                            (config) => {

                                                //TODO: We don't need approvers anymore I believe.  We can just rely on the custom claims.  Let's rewrite it to remove approvers.
                                                //

                                                //TODO: I think I need to refactor this into something more general
                                                //      since I'll most likely use it somewhere else.
                                                if (!order) return null;
                                                //change buttons based on state




                                                if (!config || !config.approvers) return null;

                                                if (!userData || !userData.msal || !(userData.msal.idTokenClaims as any).groups) return null;

                                                let groups = (userData.msal.idTokenClaims as any).groups;

                                                //loop through all groups and see if they intersect.
                                                let approvers = config.approvers.kyc_normal;
                                                let execApprovers = config.approvers.kyc_executive;

                                                let isApprover = groups.some((group: string) => approvers.includes(group));
                                                let isExecApprover = groups.some((group: string) => execApprovers.includes(group));
                                                let isOperationsApprover = customClaims.oper;
                                                let isOrderAndKycSameState = false;


                                                if (!isApprover && !isExecApprover && !isOperationsApprover) return null;

                                                // if (isApprover && data.approvedBy) return null;
                                                // if (isExecApprover && data.approvedByExec) return null;


                                                //loop backwards through actionsData and get the last actions that were after needs_update


                                                let actionToTakeLabel: string | null = null;
                                                let actionToTake: ActionType | null = null;
                                                let actionColor: string = "";




                                                if (orderStatusCode === "review") {
                                                    actionToTakeLabel = "Review";
                                                    actionToTake = "review";
                                                    actionColor = "cyan";
                                                    if (kycState?.status_code === orderStatusCode && kycState?.risk_code !== "high") {
                                                        actionToTakeLabel = actionToTakeLabel + " (Order & KYC)";
                                                        isOrderAndKycSameState = true;
                                                    }
                                                }
                                                else if (orderStatusCode === "approval") {
                                                    actionToTakeLabel = "Approve";
                                                    actionToTake = "approve";
                                                    actionColor = "green";
                                                    if (kycState?.status_code === orderStatusCode && kycState?.status_code !== "high") {
                                                        actionToTakeLabel = actionToTakeLabel + " (Order & KYC)";
                                                        isOrderAndKycSameState = true;
                                                    }
                                                }

                                                //TODO: this needs rewriting.  I can't use two different methods of checking if the user is an approver.
                                                else if (orderStatusCode === "processing") {
                                                    if (!customClaims["oper"]) return null;
                                                    actionToTakeLabel = "Operations Approve";
                                                    actionToTake = "operations_approve";
                                                    actionColor = "indigo";
                                                }

                                                console.log("Yes we can!", orderStatusCode, actionToTake, actionToTakeLabel, actionColor);

                                                let isKycInApproveAndOrderInReview = orderStatusCode === "review" && kycState?.status_code === "approval";

                                                let disableOrderRejectAction = orderStatusCode === "rejected";
                                                // let disableKycRejectAction = !kycState || kycState?.status_code === "rejected";
                                                // let disableRejectButton = disableOrderRejectAction && disableKycRejectAction;

                                                //make sure reviewer is not the same as approver

                                                // let approveButtonDisabled = kycCompleted === false;

                                                // if (actionToTake === "approve") {
                                                //     //loop through actionsAfterRejection
                                                //     for (let i = 0; i < actionsAfterRejection.length; i++) {
                                                //         if (actionsAfterRejection[i].id === userData.user?.uid) {
                                                //             // approveButtonDisabled = true;
                                                //             break;
                                                //         }
                                                //     }
                                                // }


                                                return <Group>


                                                    {actionToTake &&
                                                        <Button disabled={(!kycCompleted && !isOrderAndKycSameState && !isKycInApproveAndOrderInReview) || ((orderStatusCode === "review" || orderStatusCode === "approval") && !allFilesTagged)} color={actionColor} type="button" name="approve" onClick={async () => {


                                                            setConfirmDialog({
                                                                title: "Confirm",
                                                                action: actionToTakeLabel,
                                                                description: `${actionToTakeLabel} ${order.customerObj?.name_en}'s order?`,
                                                                buttonColor: actionColor,
                                                                callback: async () => {

                                                                    //The action needs Microzift's accessToken so I can verify groups from the server side.
                                                                    let token = await userData.getAzureADToken!(userData);

                                                                    if (!token) {
                                                                        throw new FirebaseError("no_microsoft_token", "No Microsoft token.  Try refreshing");
                                                                    }
                                                                    if (isOrderAndKycSameState) {
                                                                        const resKYC: any = await kycAction({
                                                                            action: actionToTake!,
                                                                            kycID: kycData?.id,
                                                                            customerID: customerFullData.id,
                                                                            token: token,
                                                                        });
                                                                        console.log(resKYC);
                                                                    }



                                                                    await orderAction({ action: actionToTake as ActionType, orderID: order.id, customerID: customerRef.id, token: token });
                                                                    setRefreshing(true);
                                                                    await orderRefresh({ orderID: order.id! });
                                                                    setRefreshing(false);
                                                                    console.warn("Order refreshed!");
                                                                }
                                                            })
                                                        }}>
                                                            {actionToTakeLabel} {!kycState ? "(No valid KYC)" : ""} {!allFilesTagged ? "(Files not tagged)" : ""}
                                                        </Button>
                                                    }
                                                    {/* <Menu shadow="md" width={200} trigger="click-hover">
                                                    <Menu.Target>
                                                        <Button disabled={disableRejectButton} rightSection={<IconMenu2 style={{ width: rem(14), height: rem(14) }} />} color="red">Reject</Button>
                                                    </Menu.Target>

                                                    <Menu.Dropdown>
                                                        <Menu.Item disabled={disableOrderRejectAction} onClick={async () => {
                                                            console.log("Rejecting Order!!!");
                                                            setIsRejectingOrder(true);
                                                            setRejectionOpened(true)
                                                        }} >
                                                            Reject Order
                                                        </Menu.Item>
                                                        <Menu.Item disabled={disableKycRejectAction} onClick={async () => {
                                                            console.log("Rejecting KYC!!!");
                                                            setIsRejectingOrder(false);
                                                            setRejectionOpened(true)
                                                        }} >
                                                            Reject KYC
                                                        </Menu.Item>

                                                    </Menu.Dropdown>
                                                </Menu> */}

                                                    <Button disabled={disableOrderRejectAction} color="red" onClick={async () => {
                                                        console.log("Rejecting Order!!!");
                                                        setIsRejectingOrder(true);
                                                        setRejectionOpened(true)
                                                    }} >Reject</Button>

                                                </Group>
                                            }
                                        }
                                    </ConfigContext.Consumer>
                                </Grid.Col>
                            }

                            {(((orderStatusCode === OrderStatusCode.commitment && ((customClaims.kfh_rm && orderCreatedByMe) || customClaims.kfh_oper === true || customClaims.admin))) || (orderStatusCode == OrderStatusCode.waiting_certified_rm && !order.manual && currentRM?.certified)) &&

                                <Grid.Col span={4}>


                                    <Button type="button" name="send_sms" loading={draftActionLoading} onClick={async () => {
                                        setDraftActionLoading(true);


                                        const sendOrderSMS = httpsCallable<SendOrderSMSRequest, BaseResult>(functions, "sendOrderSMS");


                                        //generate a doc ID
                                        const docID = doc(collection(db, "sms")).id;
                                        setSMSDocID(docID);
                                        //set type to submit because we want the user to create an entirely new KYC
                                        await sendOrderSMS({
                                            order: order,
                                            token: await userData.getAzureADToken!(userData),
                                            smsDocID: docID,
                                            senderID: "KFHC-FUNDS",
                                            type: useWhatsapp ? "whatsapp" : "sms"
                                        });

                                        setDraftActionLoading(false);

                                        //call function to send SMS
                                    }}>
                                        { currentRM?.certified ? "Send Order SMS" : "Submit as Referral"}
                                    </Button>
                                    {((userClaims.admin || userClaims.cr) && currentRM?.certified) && <Checkbox
                                        checked={useWhatsapp}
                                        label="send through whatsapp"
                                        onChange={(e) => {
                                            setSMSDocID(undefined);
                                            setUseWhatsapp(e.target.checked)


                                        }}
                                    />}



                                </Grid.Col>


                            }

                            {(((orderStatusCode == OrderStatusCode.waiting_certified_rm && order.manual && currentRM?.certified))) &&

                                <Grid.Col span={4}>


                                    <Button type="button" name="send_sms" loading={draftActionLoading} onClick={async () => {
                                        try {
                                            setDraftActionLoading(true);


                                            const transferOrderToCertifiedRM = httpsCallable<GeneralOrderRequest, BaseResult>(functions, "transferOrderToCertifiedRM");


                                            //set type to submit because we want the user to create an entirely new KYC
                                            await transferOrderToCertifiedRM({
                                                orderId: order.id,
                                                // token: await userData.getAzureADToken!(userData),
                                                // smsDocID: docID,
                                                // senderID: "KFHC-FUNDS",
                                                // type: useWhatsapp ? "whatsapp" : "sms"
                                            });

                                            setDraftActionLoading(false);
                                            setIsError(false);
                                            setMsg("Order Transferred Successfully");
                                        } catch (error: any) {
                                            setIsError(true);
                                            console.log(error);
                                            setMsg(error.message);
                                        }


                                        //call function to send SMS
                                    }}>
                                        Review and Transfer Order to Me
                                    </Button>
                                    {(userClaims.admin || userClaims.cr) && <Checkbox
                                        checked={useWhatsapp}
                                        label="send through whatsapp"
                                        onChange={(e) => {
                                            setSMSDocID(undefined);
                                            setUseWhatsapp(e.target.checked)


                                        }}
                                    />}



                                </Grid.Col>


                            }


                            {orderStatusCode === "need_signature" &&

                                <Grid.Col span={12}>
                                    <Grid>
                                        <Grid.Col span={2}>
                                            <Button type="button" name="send_sms" loading={draftActionLoading} onClick={async () => {
                                                setDraftActionLoading(true);


                                                const sendOrderSMS = httpsCallable<SendOrderSMSRequest, BaseResult>(functions, "sendOrderSMS");


                                                //generate a doc ID
                                                const docID = doc(collection(db, "sms")).id;
                                                setSMSDocID(docID);

                                                //set type to submit because we want the user to create an entirely new KYC
                                                await sendOrderSMS({
                                                    order: order,
                                                    token: await userData.getAzureADToken!(userData),
                                                    smsDocID: docID,
                                                    senderID: "KFHC-FUNDS",
                                                    type: useWhatsapp ? "whatsapp" : "sms"
                                                });



                                                setDraftActionLoading(false);





                                                //call function to send SMS
                                            }}>
                                                Re-send Order SMS
                                            </Button>
                                        </Grid.Col>

                                        <Grid.Col span={10}>

                                            {(userClaims.admin || userClaims.cr) && <Checkbox
                                                checked={useWhatsapp}
                                                label="send through whatsapp"
                                                onChange={(e) => {
                                                    setUseWhatsapp(e.target.checked)

                                                    setSMSDocID(undefined);

                                                }}
                                            />}

                                        </Grid.Col>
                                        <Grid.Col span={6}>
                                            {(SMSDocID) &&

                                                <MessagesSent docID={SMSDocID} handleFailedMessages={() => {

                                                    const handleOrderSMS = async () => {

                                                        const sendOrderSMS = httpsCallable<SendOrderSMSRequest, BaseResult>(functions, "sendOrderSMS");


                                                        //generate a doc ID
                                                        const docID = doc(collection(db, "sms")).id;
                                                        setSMSDocID(docID);

                                                        //set type to submit because we want the user to create an entirely new KYC
                                                        await sendOrderSMS({
                                                            order: order!,
                                                            senderID: "KFHC-FUNDS",
                                                            smsDocID: docID,
                                                            token: await userData.getAzureADToken!(userData),
                                                        });
                                                    }
                                                    handleOrderSMS();
                                                }} />

                                            }
                                        </Grid.Col>
                                    </Grid>


                                </Grid.Col>
                            }

                            {((orderStatusCode === "need_signature" || orderStatusCode === "need_form" || (orderStatusCode === "manual") || orderStatusCode === "commitment") || ((customClaims.kfh_oper === true || customClaims.admin))) &&

                                <Grid.Col span={12}>
                                    {deleteButton}
                                </Grid.Col>


                            }

                        </Grid>



                    </Accordion.Panel>
                </Accordion.Item>

            </Accordion >
            <Modal
                size={"xl"}
                opened={changeRmOpen}
                onClose={() => setChangeRmOpen(false)}
                title="Change Order RM"
            >
                <ChangeRmModalContent
                    onClose={() => {
                        setChangeRmOpen(false);
                    }}
                    orderId={order.id ?? ""}
                    type='primary'
                />

            </Modal>
            <Modal
                size={"xl"}
                opened={changeReferredByRmOpen}
                onClose={() => setChangeReferredByRmOpen(false)}
                title="Change Order Referred by RM"
            >
                <ChangeRmModalContent
                    onClose={() => {
                        setChangeReferredByRmOpen(false);
                    }}
                    orderId={order.id ?? ""}
                    type='referred'
                />

            </Modal>


            {
                msg != null &&   //love my check if it's an error or not.  Please don't do this, it's just a proof of concept page.
                <Alert color={isError ? "red" : "green"}>
                    {msg}
                </Alert>
            }

        </ >

    );

}