import React, { useContext, useEffect, useState } from "react";

import "dayjs/locale/en-gb";

import {
  collection,
  orderBy,
  query,
  onSnapshot,
  where,
  Unsubscribe,
  QueryDocumentSnapshot,
  doc,
} from "firebase/firestore";

import {

  useIntl,
} from "react-intl";

import { useNavigate } from "react-router-dom";

import { ConfigContext, MyUserDataContext } from "../../contexts";
import { httpsCallable } from "firebase/functions";
import {
  Group,
  Modal,
  TextInput,
  Text,
  Button,
  Space,
  Paper,
  Title,
  Image,
  SimpleGrid,
  ThemeIcon,
  Grid,
  Center,
  Checkbox,
  Textarea,
  Stack,
  Radio,
  Alert,
  Timeline,
  Select,
  ActionIcon,
  Loader,

} from "@mantine/core";
import { useForm } from "@mantine/form";



import {
  IconBuildingBank,
  IconWallet,
  IconShoppingCart,
  IconUsers,
  IconGitPullRequestClosed,
  IconTrademark,
  IconPodium,
  IconPhone,
  IconSchool,
  IconArrowLeft,
  IconX,
  IconCheck,
} from "@tabler/icons-react";

// import { TagBadge } from '../components/tag_badge';
// import { CustomerHistory } from '../components/customer_history';
import { Action, ActionType, BaseResult, Customer, KYC, investedAmountOfWealth, UserDataType, approximateWealth, averageAnnualIncome, FileTag, DocType, employmentOptions, sourcesOfIncome, sourcesOfWealth, ItemInfoEditedObj, CustomClaims, SendAgreementSMSRequest, Agreement, AvailableAgreements, CreateAMSAccountRequest, CreateAMSPortfolioRequest } from "../../types";
import {
  IconThumbUp,
  IconThumbDown,
  IconGitCommit,
  IconMessageDots,
  IconEye,
  IconAlertCircle,
  IconStar,
  IconSend,
  IconBookUpload,
  IconEdit,
} from "@tabler/icons-react";
import { FirebaseError } from "firebase/app";

import { showNotification } from "@mantine/notifications";

import { FilesList } from "../../components/files";
import GeneratePDFModal from "../../components/generate_pdf_modal";
import SenderIDSelect from "../../components/SenderIDSelect";
import CustomerKycLinks from "./customer_kyc_links";
import { getAddressDetailsEnglish } from "../../helpers/addressHelpers";
import areAllFolderFilesTagged from "../../helpers/areAllFolderFilesTagged";
import { CustomInfoCard } from "./CustomInfoCard";
import { map } from "underscore";

import FileTagPicker from "../../components/file_tag_picker";
import { DocTypes } from "../../components/file_card";
import EditableInfoCard from "./EditableInfoCard";
import { useFirestore, useFunctions } from "../../helpers/firebaseContext";
import useFirestoreCollection from "../../helpers/useFirestoreCollection";
import { modals } from "@mantine/modals";



export interface CustomerKYCProps {
  customerData: Customer;
  showButton: boolean;
  showKycVersion: boolean;
  showActiveLinks: boolean;
  showGeneratePDF: boolean;
  disableUpload?: boolean
  metaTypes: DocType[];
  kycID?: string
  setMetaTypes: React.Dispatch<React.SetStateAction<DocType[]>>;
}

export const parseDayFirst = (dateString: string) => {
  const parts = dateString.split("/");
  return new Date(
    parseInt(parts[2], 10),
    parseInt(parts[1], 10) - 1,
    parseInt(parts[0], 10),
  );
};


export interface ObjectArrayType { label: string; value: string };


//create CustomerInfo component
export const CustomerKYC = ({ customerData, showButton, showActiveLinks, showKycVersion, showGeneratePDF, setMetaTypes, metaTypes, disableUpload = false, kycID }: CustomerKYCProps) => {
  // const { id } = useParams();
  // const { state } = useLocation();


  const db = useFirestore();
  const intl = useIntl();

  const translateArrayObj = (array: string[]): ObjectArrayType[] => {
    return array.map((keyValue) => {
      return {
        value: keyValue,
        label: (intl.formatMessage({ id: keyValue })) as string
      }
    }
    )
  }
  const userData: UserDataType = useContext(MyUserDataContext);
  let userClaims = userData.firebaseTokenResult?.claims.customClaims as CustomClaims || {};

  const customDocTypes: Record<string, DocType> = {
    ...DocTypes,
  };
  delete customDocTypes[FileTag.PaymentSlip];




  //make pending state
  const [pending, setPending] = useState<boolean>(false);
  const [pendingCreateAMS, setPendingCreateAMS] = useState<boolean>(false);
  const [confirming, setConfirming] = useState<boolean>(false);
  const [links, setLinks] = useState([] as any[]);
  const [kycLinks, setKYCLinks] = useState([] as any[]);
  const [generatePDFOpen, setGeneratePDFOpen] = useState<boolean>(false);

  const [msg, setMsg] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [sendSMS, setSendSMS] = useState<boolean>(false);
  const [usePaci, setUsePaci] = useState<boolean>(true);
  // const [overrideCivilID, setOverrideCivilID] = useState<boolean>(false);
  // const [overridePassport, setOverridePassport] = useState<boolean>(false);

  const [KYCDoc, setKYCDoc] = useState<QueryDocumentSnapshot<KYC> | undefined>(undefined);
  const [updatedKYCData, setUpdatedKYCData] = useState<ItemInfoEditedObj<KYC>>({});
  const data = KYCDoc?.data();
  if (data) data.id = KYCDoc?.id;

  const [privateData, setPrivateData] = useState(null as any);

  //this looks absolutely terrible.  I much preferred the chaining method used previously.
  const kycCollection = collection(db, "customers", customerData.id as string, "kyc");
  const kycQuery = query(kycCollection, orderBy("created", "desc"));

  const functions = useFunctions();
  functions.region = "europe-west1";

  const kycAction = httpsCallable<Action, BaseResult>(functions, "kycAction");
  const riskOverride = httpsCallable(functions, "riskOverride");
  const riskOverrideKYC = httpsCallable(functions, "riskOverrideKYC");
  const createAMSAccount = httpsCallable<CreateAMSAccountRequest, BaseResult>(functions, "createAMSAccount");

  // const oneTimeOverrideKYC = httpsCallable(functions, "oneTimeOverrideKYC");
  const [allFilesTagged, setAllFilesTagged] = useState<boolean>(true);

  const [missingFiles, setMissingFiles] = useState<FileTag[]>([]);

  const navigate = useNavigate();

  const { loading: kycQueryLoading, snapshot: kycDocs } = useFirestoreCollection(kycQuery);


  useEffect(() => {

    if (kycDocs && !kycDocs.empty && kycDocs.docs[0]) {
      let kycDataDoc = kycDocs.docs[0] as QueryDocumentSnapshot<KYC>
      if (kycID) {
        kycDataDoc = kycDocs.docs.find((docObj) => docObj?.id === kycID) as QueryDocumentSnapshot<KYC>
      }
      setKYCDoc(kycDataDoc);
      let updatedDataObj: ItemInfoEditedObj<KYC> = {}
      if (kycDataDoc.data().updated_data && kycDataDoc.data().updated_data?.length) {
        const DataKeys = (map(kycDataDoc.data().updated_data!, (value) => (map(value?.updated_fields!, (innerValue) => Object.keys(innerValue)))));
        updatedDataObj = DataKeys?.flat(2).reduce((o, key) => ({ ...o, [key]: {} }), {})

        kycDataDoc!.data()?.updated_data!.forEach((objEntry) => {
          objEntry?.updated_fields?.forEach((updatedField: { [key: string]: (string | null)[] | number | string }) => {

            const objKey = Object.keys(updatedField)[0] as keyof KYC;
            updatedDataObj = {
              ...updatedDataObj, [objKey]: {
                ...updatedDataObj[objKey],
                currentValue: updatedField[objKey],
                updatedOn: objEntry?.updated_on!,
                updatedBy: objEntry?.updated_by!

              }
            }

          }
          )
          objEntry?.initial_value?.forEach((previousField: { [key: string]: (string | null)[] | number | string }) => {

            const objKey = Object.keys(previousField)[0] as keyof KYC;;
            updatedDataObj = {
              ...updatedDataObj, [objKey]: {
                ...updatedDataObj[objKey],
                previousValue: previousField[objKey],
                updatedOn: objEntry?.updated_on!,
                updatedBy: objEntry?.updated_by!,
                reason: objEntry?.reason ?? '',



              }
            }

          }
          )

        }
        )

        setUpdatedKYCData(updatedDataObj)
      }

    }
  }, [kycDocs])


  const [actionsAfterRejection, setActionsAfterRejection] = useState(
    [] as any
  );

  useEffect(() => {
    console.log("Running customer kyc effect!");

    const customerLinksCollection = collection(db, "kycupdatelinks");

    const kycLinksQuery = query(customerLinksCollection, where("customerID", "==", customerData?.id), orderBy("expires", "desc"));

    // console.log(db, "customers", id as string, "kyc", data.id, "private");


    let kycLinksUnsubscriber = onSnapshot(kycLinksQuery, (snapshot) => {
      if (snapshot.metadata.fromCache) {
        console.log("Cached data", snapshot.docs.length);
        // return;
      } else {
        console.log("Server data", snapshot.docs.length);
      }

      let rows = snapshot.docs.map((doc) => {
        const data = doc.data();
        data.ref = doc.ref;
        data.id = doc.id;
        data.confirming = false;
        //set expiry date and stuff if low/high risk
        return data;
      });
      setKYCLinks(rows)
    });

    //does nothing but we need it so it doesn't lose scope
    let privateDataUnsubscriber: Unsubscribe;


    if (KYCDoc && customerData?.id) {


      const privateDataRef = collection(
        KYCDoc!.ref,
        "private",
      );
      privateDataUnsubscriber = onSnapshot(privateDataRef, (snapshot) => {
        let docs = snapshot.docs;
        //convert it to a dictionary and set it to the state
        let pdata: any = {};
        docs.forEach((doc: any) => {
          pdata[doc.id] = doc.data();
        });

        setPrivateData(pdata);
        console.log("Private daa", pdata);

        if (
          pdata &&
          pdata.actions &&
          pdata.actions.approvals &&
          pdata.actions.approvals.length > 0
        ) {
          let approvals = pdata.actions.approvals;

          for (let i = pdata.actions.approvals.length - 1; i >= 0; i--) {
            if (pdata.actions.approvals[i].type === "needs_update") {
              approvals = pdata.actions.approvals.slice(i + 1);
              break;
            }
          }
          console.log("filled out actions!");
          setActionsAfterRejection(approvals);
        }
      });
    }

    //TODO: just scrape the directory

    console.log("We are returning cleanup function");

    return () => {
      privateDataUnsubscriber?.();  //this could be undefined, which is why we have a ?.
      kycLinksUnsubscriber();
      console.log("Cleanup..")
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [KYCDoc]);

  // console.log(data);



  const [rejectionOpened, setRejectionOpened] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState<any>(null);
  // const [manualEntryDialog, setManualEntryDialog] = useState<any>(null);
  const [confirmDialogError, setConfirmDialogError] = useState<any>(null);



  const form = useForm<Action>({
    initialValues: {
      comments: "",
      action: "approve",  //temporary.
      token: "",
      linkInfo: {
        smsMessage: "",
        updateType: "none",
        senderID: "KFHC-KYC",
      }
    },
    validate: {
      comments: (val) => (!val || val.length > 0 ? null : "Please enter why you are rejecting this request"),
    }
  });
  /*
    const manualEntryForm = useForm({
      initialValues: {
        birthDate: "",
        name_en: "",
        name_ar: "",
        civilID: "",
        civilIDExpiryDate: "",
        passportNumber: "",
        passportExpiryDate: "",
        houseID: "",
        gender: "",
        nationality: "",
      } as any,
      // validate: {
      // },
    });
  
  */

  /*
  useEffect(() => {

    // console.log("customer data", customerData);

    if (!customerData) return;

    if (customerData.oneTimeOverrideKYCData?.overrideCivilID) {

      setOverrideCivilID(true)
      manualEntryForm.setFieldValue("civilIDExpiryDate", customerData.oneTimeOverrideKYCData.civilIDExpiryDate?.toDate())
      manualEntryForm.setFieldValue("houseID", customerData.oneTimeOverrideKYCData.houseID)
      manualEntryForm.setFieldValue("gender", customerData.oneTimeOverrideKYCData.gender)
      manualEntryForm.setFieldValue("nationality", Object.keys(COUNTRY_CODES).find(key => COUNTRY_CODES[key] === customerData?.oneTimeOverrideKYCData?.nationality))
    }
    else {
      manualEntryForm.setFieldValue("civilIDExpiryDate", null)
      manualEntryForm.setFieldValue("houseID", "")
      manualEntryForm.setFieldValue("gender", "")
      manualEntryForm.setFieldValue("nationality", "")
      setOverrideCivilID(false)
    }

    if (customerData.oneTimeOverrideKYCData?.overridePassport) {

      setOverridePassport(true)
      manualEntryForm.setFieldValue("passportExpiryDate",
        customerData.oneTimeOverrideKYCData!.passportExpiryDate?.toDate(),
      )
      manualEntryForm.setFieldValue("passportNumber", customerData.oneTimeOverrideKYCData.passportNumber)
    }
    else {
      manualEntryForm.setFieldValue("passportExpiryDate", null)
      manualEntryForm.setFieldValue("passportNumber", "")
      setOverridePassport(false)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerData])
*/
  // const [manualEntryData, setManualEntryData] =useState<any>({
  //     birthDate: "",
  //     name_en: "",
  //     name_ar: "",
  //     civilID: "",
  //     civilIDExpiryDate: "",
  //     passportNumber: '',
  //     passportExpiryDate: '',
  //     houseID: "",
  //     gender: "",
  //     nationality: "",
  // } as any);

  // const civilIDExpiryDateRef = useRef<HTMLInputElement>(null);
  // const passportExpiryDateRef = useRef<HTMLInputElement>(null);

  // const manualModal = ();

  console.log("re-rendering!!");

  function formatNums(num: string, sep: string) {
    sep = sep || ",";
    return String(num).replace(/\.\d+|\d(?=(?:\d{3})+(?!\d))/g, function (a) {
      return a.length === 1 ? a + sep : a;
    });
  }

  const toKwd = (value?: string) => {
    if (!value) return undefined;
    return formatNums(value, ",") + " KD";
  };

  // const kycURL = process.env.NODE_ENV !== 'production' ? "http://localhost:64665" : (process.env.REACT_APP_ENV !== 'production') ? "https://kfh-capital-kyc-test.firebaseapp.com" : "https://kyc.kfhcapital.com.kw";

  //this is used for anyone who selects "other" as a choice in the KYC form.  It adds the text entered by the client as (text)
  const printArrayWithOthers = (key: keyof KYC): string => {
    let value: any = (data?.[key]);
    //is it an array?
    if (Array.isArray(value)) {

      //add translation to string values
      value = value.map((valueObj: any) => {
        // eslint-disable-next-line valid-typeof
        if ((typeof valueObj === 'string' || valueObj instanceof String || typeof valueObj === undefined) && valueObj !== "other") {
          return (intl.formatMessage({ id: valueObj })) as string
        }
        return valueObj
      });


      //does it have "other" in it?
      if (value.includes("other")) {
        //get the other value
        let otherValue = data?.[key + "_other_field" as keyof KYC];
        //if it's not empty, add it to the array
        if (otherValue) {
          value = value.map((val: any) => {
            return val === "other" ? `other (${otherValue})` : val;
          });
        }
      }
      return value.join(",");
    }

    if (typeof value === "string") {
      return (intl.formatMessage({ id: value })) as string;  //What is this?  It's not an array.  Just return it.
    }
    console.warn("Unknown type for key", key, value);
    return value;
  }

  const tagsAsString = (list: FileTag[]): string => {
    if (list === null || list === undefined || list.length === 0) {
      return "";

    }
    let msg = "Missing Files: ";
    msg = msg + list.map((tag) => DocTypes[tag].name).join(", ");

    return msg;
  }


  const renderGeneratePDFModal = React.useMemo(() => {
    return <GeneratePDFModal
      rows={[{
        ...customerData, //iban: customerData?.idNumber || customerData?.iban,  //TOOD: What is this?  Should probably be fetched from accounts
        kyc_id: KYCDoc?.id,
        // "address": {
        //   ...customerData?.address,
        //   "DetailsEnglish": customerData?.address?.values?.join(',') ?? "",
        // }  //TODO: There's no customerData.address? 
      }]}
      includeKYC={true}
      setPending={setPending}
      metaTypes={metaTypes}
      opened={generatePDFOpen}
      setOpened={setGeneratePDFOpen}
    />;
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      metaTypes,
      generatePDFOpen,
    ]);

  if (kycQueryLoading) {
    return <Loader />;
  }

  // return "sup";

  return (
    <>
      <MyUserDataContext.Consumer>
        {(userData: any) => {
          return (
            <>
              <Modal
                opened={rejectionOpened}
                onClose={() => {
                  setRejectionOpened(false);
                  setConfirmDialogError(null);
                }}
                title="Reject KYC"
              >
                <form
                  onSubmit={form.onSubmit(async (values) => {
                    console.log(values);

                    //get token, somehow.
                    setConfirming(true);
                    setConfirmDialogError(null);

                    try {
                      // console.log("Msal is!", userData.msal);

                      let token = await userData.getAzureADToken!(userData);
                      console.log("Token", token);

                      if (!token) {
                        setIsError(true);
                        console.log("No Microsoft Token");
                        setMsg("No Microsoft Token.  Try refreshing.");
                        return;
                      }

                      //wtf I can use nullables?
                      //ok so action will be reject if updateType is none
                      let action: ActionType =
                        values.linkInfo?.updateType !== "none" ?
                          "needs_update" :
                          "reject";

                      const res = await kycAction({
                        action: action,
                        kycID: KYCDoc?.id,
                        customerID: customerData?.id,
                        token: token,
                        // reasons: rejectionReasonSelections,
                        comments: values.comments,
                        sendSMS: sendSMS,
                        linkInfo: {
                          smsMessage: values.linkInfo?.smsMessage,
                          updateType: values.linkInfo?.updateType,
                          senderID: values.linkInfo?.senderID,
                          usePACI: usePaci,
                        },
                        missingFiles: missingFiles,
                      });
                      console.log(res);
                      // setIsError(true);
                      // setMsg("Rejected");
                      setRejectionOpened(false);
                    } catch (error: any) {
                      // setIsError(true);
                      console.log(error);
                      // setMsg(error.message);
                      setConfirmDialogError(error.message);
                    }

                    setConfirming(false);
                  })}
                >
                  {/* <MultiSelect
                    data={[
                      {
                        value: "missing_documents",
                        label: "Missing documents",
                      },
                    ]}
                    label="Rejection reasons"
                    placeholder="Choose reasons"
                    value={rejectionReasonSelections}
                    onChange={setRejectionReasonSelections}
                  /> */}

                  <Space h="sm" />
                  <Group ta="end">
                    <TextInput
                      id=""
                      placeholder="Comments"
                      style={{ flex: 1 }}
                      {...form.getInputProps("comments")}
                    />
                  </Group>
                  <Space h="xl" />
                  <Grid ta="center">

                    <Grid.Col span={4}>
                      <Checkbox
                        label="Send SMS"
                        checked={sendSMS}
                        onChange={(e) => setSendSMS(e.target.checked)}
                      />
                    </Grid.Col>
                    {sendSMS && <Grid.Col span={12}>
                      <SenderIDSelect
                        {...form.getInputProps("linkInfo.senderID")}
                        label="Sender Identity"
                      />
                    </Grid.Col>}
                    <Grid.Col span={12}>
                      <Textarea
                        disabled={!sendSMS}
                        placeholder="Type in SMS message"
                        label="Message"
                        {...form.getInputProps("linkInfo.smsMessage")}
                        minRows={2}
                        autosize
                      />
                    </Grid.Col>
                  </Grid>

                  {sendSMS && <Grid>
                    <Grid.Col span={4}>
                      <Checkbox
                        label="Use PACI"
                        checked={usePaci}
                        onChange={(e) => setUsePaci(e.target.checked)}
                      />
                    </Grid.Col>

                  </Grid>}

                  {/* </Group> */}
                  <Space h="sm" />
                  <Radio.Group
                    label="Add update link"
                    description="Allow client to update his KYC?"
                    // gap="sm"
                    {...form.getInputProps("linkInfo.updateType")}
                  >
                    <Radio disabled={!sendSMS} value="none" label="None" />
                    <Radio disabled={!sendSMS} value="update" label="Update" />
                    <Radio
                      disabled={!sendSMS}
                      value="upload_docs"
                      label="Upload docs"
                    />
                  </Radio.Group>
                  <Space h="xl" />
                  {form.values.linkInfo?.updateType === "upload_docs" && (
                    <FileTagPicker onFileTagSelect={(files) => {
                      console.log("missing files: ", files);
                      setMissingFiles(files);
                    }}
                      title="Missing Files"
                      initialValues={missingFiles}
                      customDocTypes={customDocTypes}
                    />)}
                  <Space h="xl" />

                  <Button color="red" type="submit" loading={confirming} disabled={(form.values.linkInfo?.updateType === "upload_docs" && missingFiles.length <= 0 && sendSMS) || (form.values.linkInfo?.smsMessage === "" && sendSMS)}>
                    Reject
                  </Button>
                  {confirmDialogError && (
                    <Alert
                      icon={<IconAlertCircle size={16} />}
                      title="Whoops!"
                      color="red"
                    >
                      {confirmDialogError}
                    </Alert>
                  )}
                </form>
              </Modal>
              <Modal
                opened={confirmDialog != null}
                onClose={() => {
                  setConfirmDialog(null);
                  setConfirmDialogError(null);
                }}
                title={<Title order={3}>{confirmDialog?.title}</Title>}
              >
                <form
                  onSubmit={form.onSubmit(async (values) => {
                    setConfirmDialogError(null);
                    setConfirming(true);
                    try {
                      await confirmDialog?.callback(values);
                      setConfirmDialog(null);
                    } catch (error: any) {
                      setConfirmDialogError(error.message);
                    }
                    setConfirming(false);
                  })}
                >
                  <Stack>
                    <Text fw={300}>{confirmDialog?.description}</Text>
                    {/* <Space h="xl"/> */}
                    {confirmDialog?.textField && (
                      <>
                        <Text>{confirmDialog?.textFieldDescription}</Text>
                        <TextInput
                          {...form.getInputProps(confirmDialog.textField)}
                        />
                      </>
                    )}
                    <Button
                      color={confirmDialog?.buttonColor}
                      type="submit"
                      loading={confirming}
                    >
                      {confirmDialog?.action}
                    </Button>
                    {confirmDialogError && (
                      <Alert
                        icon={<IconAlertCircle size={16} />}
                        title="Whoops!"
                        color="red"
                      >
                        {confirmDialogError}
                      </Alert>
                    )}
                  </Stack>
                </form>
              </Modal>
              {/* 
              <Modal
                opened={manualEntryDialog != null}
                onClose={() => {
                  setManualEntryDialog(null);
                  setConfirmDialogError(null);
                }}
              // title={<Title order={3}>{confirmDialog?.title}</Title>}
              >
                <form
                  onSubmit={manualEntryForm.onSubmit(async (entries) => {
                    // console.log(values);
                    // event.preventDefault()

                    setConfirmDialogError(null);
                    setConfirming(true);

                    let civilID = customerData.civilID;

                    //get birthdate from civil ID
                    let date = civilID.match(
                      /(\d{1})(\d{2})(\d{2})(\d{2})\d{5}/
                    )!;  //if it dies, it dies.


                    const n = 17 + Number(date[1]) + "" + date[2];

                    const dateObj = new Date(
                      Number(n),
                      Number(date[3]) - 1,
                      Number(date[4]),
                    );
                    console.log(dateObj);


                    let d = {
                      ...entries,
                      birthDate: dateObj,
                      name_en: customerData.name_en,
                      name_ar: customerData.name_ar,
                      civilID: civilID,
                    };

                    //validate!
                    if (overrideCivilID) {
                      if (
                        d.civilIDExpiryDate?.length === 0 ||
                        d.houseID?.length === 0 ||
                        d.gender?.length === 0 ||
                        d.nationality?.length === 0
                      ) {
                        await setConfirming(false);
                        setConfirmDialogError("Please fill out all fields!");
                        console.log("FAIL!");
                        return;
                      }
                      d.overrideCivilID = true
                    } else {
                      //remove it from the D
                      delete d.civilIDExpiryDate;
                      delete d.houseID;
                      delete d.birthDate;
                      delete d.name_en;
                      delete d.name_ar;
                      delete d.gender;
                      delete d.nationality;
                      delete d.civilID;
                    }

                    if (overridePassport) {
                      if (
                        d.passportNumber?.length === 0 ||
                        d.passportExpiryDate?.length === 0
                      ) {
                        setConfirming(false);
                        setConfirmDialogError("Please fill out all fields!");
                        console.log("FAIL!");
                        return;
                      }
                      d.overridePassport = true

                    } else {
                      //remove it from the D
                      delete d.passportNumber;
                      delete d.passportExpiryDate;

                    }

                    // if (d.civilIDExpiryDate) d.civilIDExpiryDate = parseDayFirst(d.civilIDExpiryDate);
                    // if (d.passportExpiryDate) d.passportExpiryDate = parseDayFirst(d.passportExpiryDate);
                    if (d.nationality)
                      d.nationality = COUNTRY_CODES[d.nationality];

                    console.log("d", d);

                    //submit to firebase

                    showNotification({
                      id: "overridingKYC",
                      loading: true,
                      message: `Overriding KYC for ${customerData.civilID}`,
                      // message: 'Data will be loaded in 3 seconds, you cannot close this yet',
                      autoClose: false,
                      // disallowClose: true,
                    });

                    oneTimeOverrideKYC({ kycData: d, customerID: customerData?.civilID })
                      .then((res) => {
                        console.log("res", res);
                        setConfirming(false);
                        setManualEntryDialog(null);

                        updateNotification({
                          id: "overridingKYC",
                          color: "teal",
                          // title: 'SMS Sent',
                          // message: 'SMS was sent to customer',
                          message: ` KYC Overriden ${customerData.civilID}`,
                          icon: <IconCheck size={16} />,
                          autoClose: 4000,
                        });
                      })
                      .catch((err) => {
                        setConfirming(false);

                        updateNotification({
                          id: "overridingKYC",
                          color: "red",
                          // title: 'SMS Sent',
                          title: `Couldn't override ${customerData.civilID}`,
                          message: `${err}`,
                          icon: <IconCheck size={16} />,
                          autoClose: 4000,
                        });

                        console.log("err", err);
                      });

                    // closeAllModals();
                  })}
                >
                  <Checkbox
                    label="Override Civil ID"
                    checked={overrideCivilID}
                    onChange={(e) => setOverrideCivilID(e.target.checked)}
                  />

                  <Select
                    label="Nationality"
                    placeholder="Pick one"
                    name="nationality"
                    searchable
                    nothingFoundMessage="No options"
                    withAsterisk
                    disabled={!overrideCivilID}
                    {...manualEntryForm.getInputProps("nationality")}
                    // onChange={(val) => manualE({...manualEntryData, nationality: val})}
                    data={Object.keys(COUNTRY_CODES).map((val) => {
                      return { value: val, label: val };
                    })}
                  />

                  <Radio.Group
                    name="gender"
                    label="Gender"
                    withAsterisk
                    {...manualEntryForm.getInputProps("gender")}
                  >
                    <Radio value="M" label="Male" disabled={!overrideCivilID} />
                    <Radio
                      value="F"
                      label="Female"
                      disabled={!overrideCivilID}
                    />
                  </Radio.Group>

                  <TextInput
                    label="House ID"
                    name="houseID"
                    withAsterisk
                    placeholder=""
                    disabled={!overrideCivilID}
                    // onChange={(event) => {console.log(manualEntryData, event.currentTarget.value); setManualEntryData({houseID: event.currentTarget.value})}}

                    {...manualEntryForm.getInputProps("houseID")}
                  />

                  <DateInput

                    locale="en-gb"

                    dateParser={parseDayFirst}
                    label="Civil ID Expiry"
                    name="civilIDExpiryDate"
                    placeholder="Pick date"
                    valueFormat="DD/MM/YYYY"
                    disabled={!overrideCivilID}
                    {...manualEntryForm.getInputProps("civilIDExpiryDate")}
                  />

                  <Space h="xl" />
                  <Checkbox
                    label="Override Passport"
                    checked={overridePassport}
                    onChange={(e) => setOverridePassport(e.target.checked)}
                  />

                  <TextInput
                    label="Passport Number"
                    name="passportNumber"
                    placeholder=""
                    disabled={!overridePassport}
                    {...manualEntryForm.getInputProps("passportNumber")}
                  />

                  <DateInput
                    locale="en-gb"
                    dateParser={parseDayFirst}

                    label="Passport Expiry"
                    name="passportExpiryDate"
                    placeholder="Pick date"
                    valueFormat="DD/MM/YYYY"
                    disabled={!overridePassport}
                    {...manualEntryForm.getInputProps("passportExpiryDate")}
                  />

                  <Space h="xl" />
                  <Button color="red" type="submit" loading={confirming}>
                    Submit
                  </Button>

                  {confirmDialogError && (
                    <Alert
                      icon={<IconAlertCircle size={16} />}
                      title="Whoops!"
                      color="red"
                    >
                      {confirmDialogError}
                    </Alert>
                  )}
                </form>
              </Modal> */}
            </>
          );
        }}
      </MyUserDataContext.Consumer>

      <Space h="md" />

      {(showButton ?? false) && (<Group>
        <ActionIcon onClick={() => {
          //go back
          navigate("/customers");
        }}>
          <IconArrowLeft size="3rem" />
        </ActionIcon>
        {data && (
          <Title>
            {data!.name_en}
          </Title>
        )}
      </Group>)}
      <Group justify="space-between">
        <MyUserDataContext.Consumer>
          {(value: any) => (
            <Group mt={20}>
              {/* show Create AMS Account button only in case where showGeneratePDF is true  and customer does not have tradex id */}
              {showGeneratePDF && !customerData.tradexID &&

                <Button
                  color="pink"
                  loading={pendingCreateAMS}
                  disabled={customerData?.tags?.kycStatus?.code !== "valid" && false}
                  type="button"
                  name="create_account"
                  onClick={async () => {
                    //send API request to create AMS
                    setPendingCreateAMS(true);
                    try {
                      // console.log("Creating AMS account with data", customerData, data);
                      let res = await createAMSAccount({ customer: customerData, kyc: data });

                      console.log("Result from createAMS", res);
                    } catch (error: any) {
                      showNotification({
                        id: "error",
                        color: "red",
                        icon: <IconX />,
                        withBorder: true,
                        // loading: true,
                        message: `Error creating account: ${error.message}`,
                        // message: 'Data will be loaded in 3 seconds, you cannot close this yet',
                        // autoClose: true,
                        // disallowClose: true,
                      });

                    }
                    setPendingCreateAMS(false);

                  }}
                >
                  Create AMS account
                </Button>
              }
              {/* 
              <Button
                color="orange"
                disabled={customerData?.tags?.kycStatus?.code !== "valid"}
                type="button"
                name="create_account"
              >
                Create KFH Trade account
              </Button> */}



              {showGeneratePDF &&
                <Button
                  color="violet"
                  loading={pending}
                  type="button"
                  name="addRow"
                  onClick={() => setGeneratePDFOpen(true)}
                >
                  Generate KYC PDF
                </Button>
              }

            </Group>
          )}


        </MyUserDataContext.Consumer>

        <Stack>
          <Text c={"blue"} fw={"bold"}>CIF: {customerData?.crmID}</Text>

          {showKycVersion &&
            <>
              <Select
                searchable
                allowDeselect={false}
                label="KYC Version "
                data={kycDocs?.docs.map((kycObj) => {

                  return { ...kycObj, label: kycObj.data().created.toDate().toDateString(), value: kycObj.id }
                })}
                value={KYCDoc?.id}
                onChange={(chosenKycID) => {
                  const chosenKyc = kycDocs!.docs.find((kyc) => kyc.id === chosenKycID);

                  if (chosenKyc) {
                    setKYCDoc(chosenKyc as QueryDocumentSnapshot<KYC>);
                    // setData(chosenKyc.data() as KYC);
                    // setKycRef(chosenKyc.ref);
                  }

                }}

              >


              </Select>
              <Text>ID: {KYCDoc?.id}</Text>
            </>
          }
        </Stack>
      </Group>


      <Group justify="flex-end" >

      </Group>




      <MyUserDataContext.Consumer>
        {(userData: any) => {
          return (
            <>
              {kycLinks && kycLinks.length > 0 && (showActiveLinks ?? true) && (
                <Paper shadow="xs" p="md">
                  <Group>
                    <ThemeIcon variant="light">
                      <IconEye size={16} />
                    </ThemeIcon>
                    <Title order={5}>Active links</Title>
                  </Group>
                  <Space h="xs" />

                  <>
                    {kycLinks.filter((link => link.filledData?.accept_terms !== true)).map((link, idx) => {

                      //TODO: use this to have admin privileges and skip steps for KYC
                      let userID = `?user=${userData?.msal?.uniqueId}`;

                      return <CustomerKycLinks userID={userID} userData={userData} kycStatus={customerData.tags?.kycStatus?.code} link={link} idx={idx} key={idx} />

                    })}
                  </>


                </Paper>)
              }

            </>

          );
        }}
      </MyUserDataContext.Consumer>

      <Space h="xl" />

      {data && (
        <SimpleGrid cols={3}>
          <EditableInfoCard<KYC>
            updatedItemData={updatedKYCData}
            title="Client Information"
            color="blue"
            icon={<IconPhone size={16} />}
            itemDoc={KYCDoc}
            list={[
              {
                label: "Name (en)",
                value: data.name_en,
              },

              {
                label: "Name (ar)",
                value: data.name_ar,
              },
              {
                label: "Civil ID",
                value: data.civilID,
              },
              {
                label: "Civil ID Expiry",
                value: data.civilIDExpiryDate?.toDate().toLocaleDateString("en-gb")
              },
              {
                label: "Birth Date",
                editable: userClaims["cr"],
                value: data.birthDate?.toDate().toLocaleDateString("en-gb"),
                fieldName: "birthDate",
                componentType: "Timestamp"
              },
              {
                label: "Mobile",
                value: data.mobile,
              },
              {
                label: "Nationality",
                value: data!.nationality,
              },
              {
                label: "Gender",
                value: data!.gender,
              },
              {
                label: "PACI Sponsor",
                value: data.paciData?.PersonalData?.GovData?.SponsorName || undefined
              },
              {
                label: "PACI Mobile",
                value: data.paciData?.PersonalData?.MobileNumber || undefined
              },
              {
                label: "PACI Email",
                value: data.paciData?.PersonalData?.EmailAddress || undefined,
                noCapitalize: true,
              },
              {
                label: "Passport Name",
                value: customerData?.passportData?.fullName
              },
              {
                label: "Passport ID",
                value: customerData?.passportData?.id
              },
              {
                label: "Passport Expiry Date",
                value: customerData?.passportData?.expiryDate?.toDate().toLocaleDateString("en-gb")
              },
              {
                label: "Address (en)",
                value: data?.address?.DetailsEnglish,
              },

              {
                label: "Address (ar)",
                value: data?.address?.DetailsArabic,
              },
              {
                label: "Address",
                value: data?.address ? getAddressDetailsEnglish(data?.address) : null,
              },
              // ...Object.keys(data?.address).map((key) => {return {label: key, value: data?.address[key]}} )
            ]}
          />

          <EditableInfoCard<KYC>
            updatedItemData={updatedKYCData}
            title="Income Information"
            color="red"
            icon={<IconWallet size={16} />}
            itemDoc={KYCDoc}
            list={[
              {
                label: "Sources of Income",
                editable: userClaims["cr"],
                value: printArrayWithOthers("sourcesOfIncome"),
                dataOptions: translateArrayObj(sourcesOfIncome),
                fieldName: "sourcesOfIncome",
                componentType: "MultiSelect"


              },
              {
                label: "Sources of Wealth",
                editable: userClaims["cr"],
                value: printArrayWithOthers("sourcesOfWealth"),
                dataOptions: translateArrayObj(sourcesOfWealth),
                fieldName: "sourcesOfWealth",
                componentType: "MultiSelect",

              },
              {
                label: "Average annual income (KD)",
                editable: userClaims["cr"],
                value: toKwd(data.averageAnnualIncome),
                dataOptions: translateArrayObj(averageAnnualIncome),
                fieldName: "averageAnnualIncome",
                componentType: "Select",

              },
              {
                label: "Approximate wealth (KD)",
                editable: userClaims["cr"],
                value: toKwd(data.approximateWealth),
                dataOptions: translateArrayObj(approximateWealth),
                fieldName: "approximateWealth",
                componentType: "Select",

              },
            ]}
          />
          {data!.photo && (
            <Paper shadow="xs" p="md">
              <Group>
                <ThemeIcon color="green" variant="light">
                  <IconSchool size={16} />
                </ThemeIcon>
                <Title order={5}>Photo</Title>
              </Group>
              <Space h="xs" />
              <Center>
                {data && (
                  <Image
                    w="auto"
                    fit="contain"
                    h={200}
                    radius="lg"

                    src={`data:image/png;base64,${data!.photo}`}
                  />
                )}
              </Center>
            </Paper>
          )}
          <EditableInfoCard<KYC>
            title="Investment Information"
            color="green"
            itemDoc={KYCDoc}
            updatedItemData={updatedKYCData}
            icon={<IconShoppingCart size={16} />}
            list={[
              {
                label: "Investment experience",
                value: printArrayWithOthers("investmentExperience"),
              },
              {
                label: "Investment awareness",
                value: data!.investmentAwareness,
              },
              {
                label: "Investment objectives & strategy",
                value: printArrayWithOthers("investmentObjectives"),
              },
              {
                label: "Appetite of risk",
                value: data!.riskAppetite,
              },
              {
                label: "Requested investment asset class",
                value: printArrayWithOthers("requestedAssetClass"),
              },

              {
                label: "Invested amount",
                value:
                  <>
                    {data.investedAmountOfWealth} %
                  </>
                ,
                fieldName: "investedAmountOfWealth",
                editable: userClaims["cr"],
                componentType: "Select",
                dataOptions: translateArrayObj(investedAmountOfWealth),
                rightSection: <>%</>
              },
              {
                label: "Expected activity",
                value: data!.expectedVolume,
              },
            ]} />


          <EditableInfoCard<KYC>
            updatedItemData={updatedKYCData}

            title="Employment Information"
            color="blue"
            itemDoc={KYCDoc}
            icon={<IconBuildingBank size={16} />}
            list={[
              {
                label: "Profession",
                editable: userClaims["cr"],
                value:
                  printArrayWithOthers("employment"),
                fieldName: "employment",
                componentType: "MultiSelect",
                dataOptions: translateArrayObj(employmentOptions)

              },
              {
                label: "Employer",
                value: data.employment_employer,
                fieldName: "employment_employer",
                componentType: "TextInput",
                editable: userClaims["cr"],


              },
              {
                label: "Job title",
                value: data.employment_jobTitle,
                fieldName: "employment_jobTitle",
                componentType: "TextInput",
                editable: userClaims["cr"],


              },
              {
                label: "Employer phone",
                value: data.employment_employerPhone,
                fieldName: "employment_employerPhone",
                componentType: "TextInput",
                editable: userClaims["cr"],


              },
              {
                label: "Years of experience",
                value: data.employment_yearsOfExperience,
                fieldName: "employment_yearsOfExperience",
                componentType: "NumberInput",
                editable: userClaims["cr"],


              },
            ]}
          />
          <CustomInfoCard
            title="Additional Information"
            color="violet"
            icon={<IconGitPullRequestClosed size={16} />}
            list={[
              {
                label: "Trade through other brokers",
                value: data!.tradeThroughOtherBroker,
              },
              {
                label: "Broker name",
                value: data!.tradeThroughOtherBroker_name,
              },
              {
                label: "Trade in other markets",
                value: data!.brokerForOtherMarkets,
              },
              {
                label: "Market",
                value: data!.brokerForOtherMarkets_name,
              },
              {
                label: "Rely on financial consultations",
                value: data!.relyOnFinancialConsultations,
              },
            ]}
          />

          <CustomInfoCard
            title="Declaration Information"
            color="orange"
            icon={<IconTrademark size={16} />}
            list={[
              {
                label: "Board member",
                value: data!.memberOfBoard,
              },
              ...(data!.declarationInformation || []).map((item) => ({
                label: item.position,
                value: item.entity + ", " + item.market,
              })),
            ]}
          />

          <CustomInfoCard
            title="Real beneficiaries"
            color="yellow"
            icon={<IconUsers size={16} />}
            list={[
              {
                label: "Trade on behalf of others",
                value: data!.tradeForBeneficiaries,
              },
              ...(data!.beneficiaryInformation || []).map((item) => ({
                label: item.relation,
                value: item.name,
              })),
            ]}
          />

          <CustomInfoCard
            title="Political position information"
            color="teal"
            icon={<IconPodium size={16} />}
            list={[
              {
                label: "PEP",
                value:
                  data!.politicalPosition && data!.politicalPosition.length > 0
                    ? (data!.politicalPosition || [])?.join(", ")
                    : false,
              },
              {
                label: "Relative of PEP",
                value:
                  data!.relativePoliticalPosition &&
                    data!.relativePoliticalPosition.length > 0
                    ? (data!.relativePoliticalPosition || [])?.join(", ")
                    : false,
              },

              ...(data!.relativePoliticalPosition &&
                data!.relativePoliticalPosition.length > 0
                ? data!.relativePoliticalPositionInformation || []
                : []
              ).map((item: any) => ({
                label: item.relation,
                value: item.name,
              })),
            ]}
          />

          <CustomInfoCard
            title="Client classification"
            color="cyan"
            icon={<IconSchool size={16} />}
            list={[
              ...(data!.clientNature || ["Normal Client"]).map((item: any) => ({
                label: item,
                value: true,
              })),
            ]}
          />

          <Paper shadow="xs" p="md">
            <Group>
              <ThemeIcon color="yellow" variant="light">
                <IconSchool size={16} />
              </ThemeIcon>
              <Title order={5}>Risk level</Title>
            </Group>
            <Space h="xs" />
            <Center>
              <Stack ta="center" gap="xs">
                <Title>
                  <Text
                    tt="capitalize"
                    c={
                      privateData?.state?.risk_code === "high" ? "red" : "green"
                    }
                  >
                    {privateData?.state?.risk_code}
                  </Text>
                </Title>
                <Text tt="capitalize" c="dimmed">
                  {privateData?.state?.risk}
                </Text>
                {privateData?.state?.risk_code === "high" && !customerData?.highRiskOverride && (
                  <Button
                    color="red"
                    onClick={() => {
                      setConfirmDialog({
                        title: "Confirm",
                        action: "Override low risk",
                        description: `Change ${data?.name_en} to low risk?`,
                        buttonColor: "red",
                        textFieldDescription: "Reason",
                        textField: "reason",
                        callback: async (values: any) => {
                          await riskOverride({
                            customerID: customerData.id,
                            action: "add",
                            reason: values.reason,
                            targetRisk: "low",
                          });
                        },
                      });
                    }}
                  >
                    Risk override
                  </Button>
                )}
                {privateData?.state?.risk_code === "high" && !KYCDoc?.data().highriskOverrideReason && (
                  <Button
                    color="red"
                    onClick={() => {
                      setConfirmDialog({
                        title: "Confirm",
                        action: "Override high risk reason ",
                        description: `Change ${data?.name_en}  risk reason ?`,
                        buttonColor: "red",
                        textFieldDescription: "Reason",
                        textField: "reason",
                        callback: async (values: any) => {
                          await riskOverrideKYC({
                            customerID: customerData.id,
                            kycID: KYCDoc?.id,
                            action: "update",
                            reason: values.reason,
                            targetRisk: "high",
                          });
                        },
                      });
                    }}
                  >
                    Risk Reason override
                  </Button>
                )}
                {privateData?.state?.risk_code === "high" && KYCDoc?.data().highriskOverrideReason && (
                  <Button
                    color="red"
                    onClick={() => {
                      setConfirmDialog({
                        title: "Confirm",
                        action: "Override high risk reason ",
                        description: `Change ${data?.name_en}  risk reason ?`,
                        buttonColor: "red",
                        textFieldDescription: "Reason",
                        textField: "reason",
                        callback: async (values: any) => {
                          await riskOverrideKYC({
                            customerID: customerData.id,
                            kycID: KYCDoc?.id,
                            action: "remove",
                            reason: values.reason,
                            targetRisk: "high",
                          });
                        },
                      });
                    }}
                  >
                    Remove Risk Reason override
                  </Button>
                )}
                {customerData?.lowRiskOverride && (
                  <Button
                    color="red"
                    onClick={() => {
                      setConfirmDialog({
                        title: "Confirm",
                        action: "Remove override",
                        description: `Remove ${data?.name_en}'s low risk override?'`,
                        buttonColor: "red",
                        callback: async () => {
                          await riskOverride({
                            customerID: customerData.id,
                            action: "remove",
                            targetRisk: "low",
                          });
                        },
                      });
                    }}
                  >
                    Remove override
                  </Button>
                )}
                {privateData?.state?.risk_code === "low" && !customerData?.lowRiskOverride && (
                  <Button
                    color="red"
                    onClick={() => {
                      setConfirmDialog({
                        title: "Confirm",
                        action: "Override high risk",
                        description: `Change ${data?.name_en} to high risk?`,
                        buttonColor: "red",
                        textFieldDescription: "Reason",
                        textField: "reason",
                        callback: async (values: any) => {
                          await riskOverride({
                            customerID: customerData?.id,
                            action: "add",
                            reason: values.reason,
                            targetRisk: "high",
                          });
                        },
                      });
                    }}
                  >
                    Risk override
                  </Button>
                )}
                {customerData?.highRiskOverride && (
                  <Button
                    color="red"
                    onClick={() => {
                      setConfirmDialog({
                        title: "Confirm",
                        action: "Remove override",
                        description: `Remove ${data?.name_en}'s high risk override?'`,
                        buttonColor: "red",
                        callback: async () => {
                          await riskOverride({
                            customerID: customerData?.id,
                            action: "remove",
                            targetRisk: "high",
                          });
                        },
                      });
                    }}
                  >
                    Remove override
                  </Button>
                )}
              </Stack>
            </Center>
          </Paper>
          <Paper shadow="xs" p="md">
            <Group>
              <ThemeIcon color="green" variant="light">
                <IconSchool size={16} />
              </ThemeIcon>
              <Title order={5}>Signature</Title>
            </Group>
            <Space h="xs" />
            <Center>
              {data && (
                <Image
                  fit="contain"
                  // w="auto"
                  // h={150}


                  src={`data:image/png;base64,${data.signature}`}
                />
              )}
            </Center>
          </Paper>

        </SimpleGrid >
      )}


      {/* Show photos from firebase storage */}
      <Space h="xl" />

      <Stack ta="start" gap="xl">
        {customerData !== undefined && (
          <>
            <Title order={3}>Files</Title>
            <FilesList
              setMetaTypes={setMetaTypes}
              path={`kyc/${customerData.id}/`}
              setLinks={setLinks}
              links={links}
              onChange={() => {
                if (data) {
                  areAllFolderFilesTagged(`kyc/${customerData.id}/`).then((res) => {
                    setAllFilesTagged(res);
                  }).catch((err) => {
                    setAllFilesTagged(false);
                  });

                };

              }}
              customDocTypes={customDocTypes}
              showUploader={!disableUpload && customerData.tags?.kycStatus?.code !== "rejected"}
            />

          </>
        )}

        {/* show actions taken on this KYC */}

        {(showButton ?? true) && (
          <>
            <Title order={3}>Actions</Title>

            {/* <Container size="xl"> */}
            <Timeline
              active={100}
              bulletSize={24}
              lineWidth={2}
              reverseActive
            >
              {
                privateData?.actions?.approvals
                  .map((action: any, index: number) => {
                    // console.log(action.id, index);
                    let verb = "Unknown";
                    let color = "gray";
                    let icon = <IconMessageDots size={12} />;

                    if (action.type === "reject") {
                      verb = "Rejected";
                      color = "red";
                      icon = <IconThumbDown size={12} />;
                    } else if (action.type === "needs_update") {
                      verb = "Needs update";
                      color = "yellow";
                      icon = <IconGitCommit size={12} />;
                    } else if (action.type === "review") {
                      verb = "Reviewed";
                      color = "cyan";
                      icon = <IconEye size={12} />;
                    } else if (action.type === "approve") {
                      verb = "Approved";
                      color = "green";
                      icon = <IconThumbUp size={12} />;
                      if (action.level === "executive") {
                        verb = "Approved";
                        color = "grape";
                        icon = <IconStar size={12} />;
                      }
                    } else if (action.type === "submit") {
                      verb = "Submitted";
                      color = "blue";
                      icon = <IconSend size={12} />;
                    } else if (action.type === "update") {
                      verb = "Updated";
                      color = "blue";
                      icon = <IconEdit size={12} />;
                    } else if (action.type === "upload_docs") {
                      verb = "Uploaded documents";
                      color = "blue";
                      icon = <IconBookUpload size={12} />;
                    }

                    return (
                      <Timeline.Item
                        bullet={icon}
                        title={verb}
                        color={color}
                        key={index}
                      >
                        <Text
                          c="dimmed"
                          size="sm"
                        >{`${verb} by ${action.name}`}</Text>
                        {action.comments && (
                          <Text c="violet" size="sm">
                            {action.comments}
                          </Text>
                        )}
                        {action.missingFiles && (
                          <>
                            <Text c="violet" size="sm">
                              {tagsAsString(action.missingFiles)}
                            </Text>

                          </>
                        )}
                        <Text size="xs" mt={4}>
                          {new Date(
                            action.date.seconds * 1000
                          ).toLocaleString("en-GB", { hour12: true })}
                        </Text>
                      </Timeline.Item>
                    );
                  })
                  .reverse() /* reverse so the latest action is at the top */
              }
            </Timeline>
            {/* </Container> */}
          </>
        )}
        <MyUserDataContext.Consumer>
          {(userData: UserDataType) => (
            <ConfigContext.Consumer>
              {(config: any) => {
                //TODO: I think I need to refactor this into something more general
                //      since I'll most likely use it somewhere else.
                if (!data) return null;
                if (!config || !config.approvers) return null;
                if (
                  !userData ||
                  !userData.msal ||
                  !(userData.msal.idTokenClaims as any).groups
                )
                  return null;

                let groups = (userData.msal.idTokenClaims as any).groups;

                //loop through all groups and see if they intersect.
                let approvers = config.approvers.kyc_normal;
                let execApprovers = config.approvers.kyc_executive;

                let isApprover = groups.some((group: string) =>
                  approvers.includes(group)
                );
                let isExecApprover = groups.some((group: string) =>
                  execApprovers.includes(group)
                );
                if (!isApprover && !isExecApprover) return null;

                // if (isApprover && data.approvedBy) return null;
                // if (isExecApprover && data.approvedByExec) return null;

                //loop backwards through actionsData and get the last actions that were after needs_update

                //change buttons based on state
                let state = customerData.tags?.kycStatus?.code;
                console.log("Buttons customer state: ", state);
                if (KYCDoc && KYCDoc.exists() && (KYCDoc.data() as KYC).state?.status_code) {
                  state = (KYCDoc.data() as KYC).state?.status_code;
                  console.log("Buttons KYC ID: ", KYCDoc.id);
                  console.log("Buttons KYC state: ", state);
                }
                let actionToTakeLabel: string | null = null;
                let actionToTake: ActionType | null = null;
                let actionColor = "";

                if (state === "review" || state === "client_update") {
                  actionToTakeLabel = "Review";
                  actionToTake = "review";
                  actionColor = "cyan";
                  if (data) {
                    areAllFolderFilesTagged(`kyc/${customerData.id}/`).then((res) => {
                      setAllFilesTagged(res);
                    }).catch((err) => {
                      setAllFilesTagged(false);
                    });
                  };
                } else if (state === "approval" || state === "exec_approval") {
                  actionToTakeLabel = "Approve";
                  actionToTake = "approve";
                  actionColor = "green";
                }

                //make sure reviewer is not the same as approver

                let approveButtonDisabled = false;
                if (actionToTake === "approve") {
                  //loop through actionsAfterRejection
                  for (let i = 0; i < actionsAfterRejection.length; i++) {
                    if (actionsAfterRejection[i].id === userData.user?.uid) {
                      // approveButtonDisabled = true;
                      break;
                    }
                  }
                }


                if (showButton === false) {
                  return null;

                }
                return (
                  <Group>
                    {actionToTake && (
                      <Button
                        disabled={approveButtonDisabled || (state === "review" && !allFilesTagged)}
                        color={actionColor}
                        type="button"
                        name="approve"
                        onClick={async () => {
                          setConfirmDialog({
                            title: "Confirm",
                            action: actionToTakeLabel,
                            description: `${actionToTakeLabel} ${data?.name_en}'s KYC?`,
                            buttonColor: actionColor,
                            callback: async () => {
                              //The action needs Microzift's accessToken so I can verify groups from the server side.
                              let token = await userData.getAzureADToken!(
                                userData
                              );

                              if (!token) {
                                throw new FirebaseError(
                                  "no_microsoft_token",
                                  "No Microsoft token.  Try refreshing"
                                );
                              }

                              const res: any = await kycAction({
                                action: actionToTake!,
                                kycID: KYCDoc?.id,
                                customerID: customerData?.id,
                                token: token,
                              });
                              console.log(res);
                            },
                          });
                        }}
                      >
                        {actionToTakeLabel}
                      </Button>
                    )}

                    {(state !== "rejected" && (isApprover || isExecApprover)) && <Button
                      color="red"
                      type="button"
                      name="reject"
                      onClick={async () => {
                        setRejectionOpened(true);
                      }}
                    >
                      {isApprover
                        ? "Reject"
                        : isExecApprover
                          ? "Executive Rejection"
                          : "This button shouldn't exist wtf"}
                    </Button>}

                  </Group>
                );
              }}
            </ConfigContext.Consumer>
          )}
        </MyUserDataContext.Consumer>
      </Stack>
      <Space h="xl" />



      {renderGeneratePDFModal}

      {/* <Row>
                <Col md="auto"> */}
      {
        msg != null && ( //love my check if it's an error or not.  Please don't do this, it's just a proof of concept page.
          <Alert color={isError ? "red" : "green"}>{msg}</Alert>
        )
      }
      {/* </Col>
            </Row> */}
    </>
  );
};
