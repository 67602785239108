

import { Badge, Button, Checkbox, Grid, Group, Space, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { HitsPerPage, InstantSearch, Pagination, SearchBox, useHits, InstantSearchProps, useInstantSearch, Configure, useSortBy, } from 'react-instantsearch';

import { TagBadge } from './tag_badge';
import TypesenseInstantSearchAdapter, { SearchClient } from "typesense-instantsearch-adapter";
import { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { MyUserDataContext } from '../contexts';
import DataTable, { TableColumn } from 'react-data-table-component';

import { useSessionStorage } from '@mantine/hooks';
import { KYCStatusCode } from '../types';
import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter';
import { HighlightedText } from '../helpers/common';
import { IconBuilding, IconQuestionMark, IconShieldLock, IconUser } from '@tabler/icons-react';


//typesense

interface InitialState {
    query?: string;
    page?: number;
}

// Define the interface for the ref
export interface RefreshButtonRef {
    refresh: () => void;
}


export const RefreshButton = forwardRef<RefreshButtonRef>((props, ref) => {
    const { refresh } = useInstantSearch();

    useImperativeHandle(ref, () => ({
        refresh
    }));

    return (
        <Button onClick={() => refresh()}>
            Refresh
        </Button>
    );
});




const TableDisplay = ({ indexName, setSelectedRows, selectedRows, renderTags, ActionsComponent, navigateDetails, queryValue, onTotalCustomersUpdate, ...props }: any) => {
    const { items, results } = useHits();

    onTotalCustomersUpdate(results?.nbHits);

    const { refine, } = useSortBy(
        {
            items: [
                { label: 'Created (asc)', value: `${indexName}/sort/created:asc` },
                { label: 'Created (desc)', value: `${indexName}/sort/created:desc` },
            ]


        }
    );

    const handleChange = ({ selectedRows }: any) => {
        // You can set state or dispatch with something like Redux so we can use the retrieved data
        if (setSelectedRows) {
            setSelectedRows(selectedRows);
        }
    };

    const columns: TableColumn<any>[] = [
        {
            name: "Type",
            cell(row,) {

                if (row.customerType === "individual") {
                    return <Tooltip label="Individual" transitionProps={{ transition: 'scale', duration: 500 }}>
                        <ThemeIcon variant="white" color="blue">
                            <IconUser size={16} />
                        </ThemeIcon>
                    </Tooltip>
                } else if (row.customerType === "company") {
                    return <Tooltip label="Company" transitionProps={{ transition: 'scale', duration: 500 }}>
                        <ThemeIcon variant="white" color="blue">
                            <IconBuilding size={16} />
                        </ThemeIcon>
                    </Tooltip>
                } else if (row.customerType === "trust") {
                    return <Tooltip label="Trust" transitionProps={{ transition: 'scale', duration: 500 }}>
                        <ThemeIcon variant="white" color="blue">
                            <IconShieldLock size={16} />
                        </ThemeIcon>
                    </Tooltip>
                } else if (row.customerType === "other") {
                    return <Tooltip label="Other" transitionProps={{ transition: 'scale', duration: 500 }}>
                        <ThemeIcon variant="white" color="blue">
                            <IconQuestionMark size={16} />
                        </ThemeIcon>
                    </Tooltip>
                } else {
                    if (row.civilID) {
                        return <Tooltip label="Individual" transitionProps={{ transition: 'scale', duration: 500 }}>
                            <ThemeIcon variant="white" color="blue">
                                <IconUser size={16} />
                            </ThemeIcon>
                        </Tooltip>
                    }
                    if (row.commercial_register) {
                        return <Tooltip label="Company" transitionProps={{ transition: 'scale', duration: 500 }}>
                            <ThemeIcon variant="white" color="blue">
                                <IconBuilding size={16} />
                            </ThemeIcon>
                        </Tooltip>
                    }

                }

            },
            // sortable: true,
            width: "90px",
        },
        {
            name: 'Name',
            id: "name_en",
            sortable: true,
            selector: (row: any) => row.name_en,
            grow: 2,
            cell(row,) {


                const displayedLabel = `${row.name_en}`


                // const regex = new RegExp(`(${queryValue})`, 'gi'); // Case insensitive match
                // const fuzzyRegex = new RegExp(`(${queryValue?.split('').join('.*?')})`, 'gi'); // Matches characters in any order with any characters in between

                // const parts: string[] = displayedLabel?.split(fuzzyRegex) || [];
                return <HighlightedText displayedLabel={displayedLabel} queryValue={queryValue || ""} />
                // return <>
                //     {parts.map((part, index) =>
                //         part.toLowerCase() === queryValue?.toLowerCase() ? (
                //             <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> // highlight style
                //         ) : (
                //             part
                //         )
                //     )}

                // </>
                // const displayedLabel = `${row.name_en}`

                // // console.log("row HGIHLIGHT ======>>", row._highlightResult);

                // const displayedHighlightedLabel = ` ${row._highlightResult?.name_en?.value}`

                // return <>
                //     {row._highlightResult && displayedHighlightedLabel ? (
                //         <span dangerouslySetInnerHTML={{ __html: displayedHighlightedLabel }} />
                //     ) : (
                //         <Text>{displayedLabel}</Text>
                //     )}
                // </>


                // <Highlight attribute={"name_en"} hit={row} />
            },
            // grow: true,
        },
        {
            name: 'Mobile',
            id: "mobile",
            selector: (row: any) => row.mobile,
            sortable: true,
            grow: 0.5,
            cell(row,) {

                const displayedLabel = `${row.mobile}`


                // const regex = new RegExp(`(${queryValue})`, 'gi'); // Case insensitive match
                // const fuzzyRegex = new RegExp(`(${queryValue?.split('').join('.*?')})`, 'gi'); // Matches characters in any order with any characters in between

                // const parts: string[] = displayedLabel?.split(fuzzyRegex) || [];
                return <HighlightedText displayedLabel={displayedLabel} queryValue={queryValue || ""} />

                // return <>
                //     {parts.map((part, index) =>
                //         part.toLowerCase() === queryValue?.toLowerCase() ? (
                //             <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> // highlight style
                //         ) : (
                //             part
                //         )
                //     )}

                // </>
                // return <Highlight attribute={"mobile"} hit={row} />

            },
        },
        {
            name: 'Civil ID/Commercial ID',
            id: "civilID",
            selector: (row: any) => row.civilID,
            sortable: true,
            // grow: true,
            grow: 1,
            cell(row,) {

                let displayedLabel: string;
                if (row.customerType === "individual") {
                    displayedLabel = `${row.civilID ?? '-'}`
                } else if (row.customerType === "company") {
                    displayedLabel = `${row.commercial_register ?? '-'}`
                } else {
                    displayedLabel = `${row.civilID ?? row.commercial_register ?? '-'}`
                }


                // console.log("row HGIHLIGHT ======>>", row._highlightResult);

                // const displayedHighlightedLabel = ` ${row._highlightResult?.civilID?.value}`
                // const regex = new RegExp(`(${queryValue})`, 'gi'); // Case insensitive match
                // const fuzzyRegex = new RegExp(`(${queryValue?.split('').join('.*?')})`, 'gi'); // Matches characters in any order with any characters in between

                // const parts: string[] = displayedLabel?.split(fuzzyRegex) || [];
                return <HighlightedText displayedLabel={displayedLabel} queryValue={queryValue || ""} />

                // return <>
                //     {parts.map((part, index) =>
                //         part.toLowerCase() === queryValue?.toLowerCase() ? (
                //             <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> // highlight style
                //         ) : (
                //             part
                //         )
                //     )}

                // </>
                // return <Highlight attribute={"civilID"} hit={row} />

            },
        },
        {
            name: 'Segment',
            grow: 1,
            // Start of Selection
            cell: (row: any) => {

                switch (row.segment) {
                    case "Private Banking":
                        return <Badge color="#c5a548" size="sm">{row.segment}</Badge>
                    case "Individual Finance":
                        return <Badge color="orange" size="sm">{row.segment}</Badge>
                    case "Ruwad":
                        return <Badge color="royalblue" size="sm">{row.segment}</Badge>
                    case "Tamayoz":
                        return <Badge color="maroon" size="sm">{row.segment}</Badge>
                    case "KFH Capital":
                        return <Badge color="forestgreen" size="sm">{row.segment}</Badge>

                    default:
                        return <span></span>

                }
            }
        },
        {
            name: 'Tags',
            // Start of Selection
            cell: (row: any) => {

                return <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                    {Object.keys(row.tags).sort().map((tagKey, i) => {
                        let tag = row.tags[tagKey];
                        return <TagBadge tag={tag} key={`${tagKey}-${i}`} />
                    })}
                </div>
            }
            ,
            // grow: false,
            // grow: 0,
            wrap: true,
        },

        {
            id: "lastUnsubmittedKYCMessage.count",
            name: "Actions",
            cell: (row: any) => <ActionsComponent customerObj={row} />
            ,

            grow: 0,
            sortable: true

        }



    ];


    return <>
        <DataTable
            sortServer
            onSort={(column, sortDirection) => {
                if (column.id) {
                    let sortByValue = `${indexName}/sort/${column.id}`
                    if (sortDirection === "asc") {
                        sortByValue += `:asc`
                    }
                    else {
                        sortByValue += `:desc`
                    }

                    refine(sortByValue)
                }
            }}
            columns={columns}

            selectableRows
            onSelectedRowsChange={handleChange}
            data={items.map((hitObj) => {
                return { ...hitObj, customerID: hitObj.id }
            })}
        />
    </>

}

interface CustomerInstantSearchProps {
    indexName: string,
    setSelectedRows?: any
    renderTags?: boolean
    ActionsComponent?: any
    navigateDetails?: any
    selectable?: boolean
    selectedRows?: any[]
    topElements?: JSX.Element,
    showTopElements?: boolean
}
const CustomerInstantSearch = ({ indexName, selectable, setSelectedRows, selectedRows = [], navigateDetails, renderTags, ActionsComponent, topElements }: CustomerInstantSearchProps) => {
    const [orderStatusFilter, setCustomerKYCStatusFilter] = useState<string | null>("")
    const [totalCustomers, setTotalCustomers] = useState<number | undefined>()

    // Callback function to update total customers
    const handleTotalCustomersUpdate = (count: number) => {
        setTotalCustomers(count);
    };

    const userData = useContext(MyUserDataContext);
    let customClaims = userData.firebaseTokenResult?.claims.customClaims as any;

    const handleRowsToShow = () => {


        const kycStatuses: string[] = []


        if (customClaims["cr"] === true) {
            kycStatuses.push(...[KYCStatusCode.review, KYCStatusCode.approval, KYCStatusCode.exec_approval])

        }


        if (kycStatuses.length) {
            if (kycStatuses.length === 1) {
                setCustomerKYCStatusFilter(`tags.kycStatus.code:${kycStatuses[0]}`)

            }
            else {

                setCustomerKYCStatusFilter(`tags.kycStatus.code:[${kycStatuses.join()}]`)
            }
        }
        else {
            setCustomerKYCStatusFilter(null)
        }

    }
    const typesenseInstantsearchAdapter: TypesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
            apiKey: userData.customerCollectionSearchKey!, // Be sure to use a Search API Key
            nodes: [
                {
                    host: process.env.REACT_APP_TYPESENSE_SEARCH_HOST!,
                    port: 443,
                    protocol: 'https'
                },
            ],

        },

        additionalSearchParameters: {

            query_by: "segment,name_en,name_ar,mobile,civilID,commercial_register,tags,crmID",
            // prioritize_exact_match: true,
            // enable_highlight_v1: true,
            // text_match_type: "max_score",
        }
        ,


    })

    let typesenseSearchClient: SearchClient = typesenseInstantsearchAdapter.searchClient as SearchClient;

    if (!indexName) {
        indexName = process.env.REACT_APP_TYPESENSE_CUSTOMERS_COLLECTION!;
    }


    //save to local session
    const [initialUIStateForIndex, setInitialUIStateForIndex] = useSessionStorage<InitialState>({
        key: 'initialUIStateFor' + indexName,
        getInitialValueInEffect: false,
    });


    const onStateChange: InstantSearchProps['onStateChange'] = ({
        uiState,
        setUiState,
    }) => {
        // Custom logic
        // setUiState(uiState);
        // console.log("setting ui state", uiState);
        setInitialUIStateForIndex(uiState[indexName]);

    };

    // console.log("session for ", indexName, initialUIStateForIndex);

    // if (!initialUIStateForIndex)  {
    //     return <div></div>;
    // }
    return (
        <InstantSearch

            searchClient={typesenseSearchClient}
            indexName={indexName}
            initialUiState={{
                [indexName]: initialUIStateForIndex
            }}
            onStateChange={onStateChange}

        >
            <Configure

                filters={orderStatusFilter ? `${orderStatusFilter}` : ""}
                exactOnSingleWordQuery='attribute'
            // query={"q=*&filter_by=lastUnsubmittedKYCMessage.count:>=4"}

            />




            <Grid >
                <Grid.Col span={7}>
                    {topElements}
                </Grid.Col>
                <Grid.Col span={4}>
                    <Group justify="space-between">
                        <Checkbox

                            label="Show all customers"
                            defaultChecked={true}
                            onChange={(event) => {
                                if (!event.currentTarget.checked) {
                                    handleRowsToShow()
                                }
                                else {
                                    setCustomerKYCStatusFilter(null)
                                }
                            }

                            }
                        />
                        <RefreshButton />
                    </Group>
                </Grid.Col>
                <Grid.Col span={12}>

                    <SearchBox

                        style={{
                            display: "flex",
                            justifyContent: "flex-end",

                            width: "100%",
                        }}
                        autoFocus
                        placeholder='Search...'


                    />
                </Grid.Col>

            </Grid>
            {/* <Text>Index: {process.env.REACT_APP_TYPESENSE_CUSTOMERS_COLLECTION}</Text> */}


            <TableDisplay
                indexName={indexName}
                queryValue={initialUIStateForIndex?.query || ""}
                selectedRows={selectedRows}
                setSelectedRows={selectable ? setSelectedRows : undefined}
                navigateDetails={navigateDetails}
                ActionsComponent={ActionsComponent}
                renderTags={renderTags}
                onTotalCustomersUpdate={handleTotalCustomersUpdate}

            />

            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",

                    width: "100%",
                }}
            >
                <HitsPerPage
                    items={[
                        { label: '250', value: 250, },
                        { label: '100', value: 100 },
                        { label: '50', value: 50, default: true },
                    ]}
                />
            </div>
            <Space h="xl" />
            <Pagination
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",


                }}
            />
            {totalCustomers && <Text mt={10} ta={'end'} style={{
                'color': '#23263b',
                'font-size': '0.875rem',
                'fontWeight': '400'
            }}>Total Number of Customers: {totalCustomers}</Text>}

        </InstantSearch >



    )
}

export default CustomerInstantSearch