

import { Checkbox, Group, Space, ThemeIcon, } from '@mantine/core';
import { HitsPerPage, InstantSearch, Pagination, SearchBox, useHits, InstantSearchProps, Configure, useSortBy } from 'react-instantsearch';

import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { useContext, useState } from 'react';
import { MyUserDataContext } from '../../contexts';
import DataTable, { TableColumn } from 'react-data-table-component';

import { useSessionStorage } from '@mantine/hooks';

import { Timestamp } from 'firebase/firestore';

import { Service, ServiceStatusCode } from '../../types';
import { TagBadge } from '../../components/tag_badge';
import { IconBriefcase, IconBusinessplan, IconMoneybag, IconReportMoney } from '@tabler/icons-react';

//typesense

interface InitialState {
    query?: string;
    page?: number;
}

const TableDisplay = ({ setSelectedRows, selectedRows, renderTags, ActionsComponent, navigateDetails, indexName, ...props }: any) => {
    const { items } = useHits();
    const { refine } = useSortBy(
        {
            items: [
                { label: 'Created (asc)', value: `${indexName}/sort/created:asc` },
                { label: 'Created (desc)', value: `${indexName}/sort/created:desc` },
            ]


        }
    );

    const handleChange = ({ selectedRows }: any) => {

        // You can set state or dispatch with something like Redux so we can use the retrieved data
        if (setSelectedRows) {
            setSelectedRows(selectedRows);
        }
    };


    const columns: TableColumn<Service>[] = [

        {
            // name: "Type",
            id: "type",
            cell: (row) => {



                let icons = [];

                if (row.serviceType === "kfhbrokerage") icons.push(
                    <ThemeIcon variant="white" color="green">
                        {/* <IconReceipt size={16} />
                         */}

                        <IconBusinessplan size={16} />
                    </ThemeIcon>
                )
                else if (row.serviceType === "kfhtrade") icons.push(
                    <ThemeIcon variant="white" color="orange">
                        <IconReportMoney size={16} />


                    </ThemeIcon>
                )
                else if (row.serviceType === "portfolio") icons.push(
                    <ThemeIcon variant="white" color="grape">
                        <IconBriefcase size={16} />
                    </ThemeIcon>
                )

                if (row.tags?.paid) icons.push(
                    <ThemeIcon variant="white" color="yellow"
                    >
                        <IconMoneybag size={16} />
                    </ThemeIcon>
                )

                return (<>{icons}</>)



            },
            width: "90px",

            // cell(row, rowIndex, column, id) {
            //     return (<div>

            //         <TagBadge tag={{ code: row.type, title: row.type, badgeColor: "green" }} />

            //     </div>)
            // },
            // width: "90px",

            // grow: 1
        },
        {
            name: "Name",
            id: "customerObj.name_en",
            selector: (row) => {

                return row.customerObj?.name_en ?? ""
            },
            sortable: true,
        },
        {
            name: "Mobile",
            selector: (row) => row.customerObj?.mobile ?? "",

            // sortable: true,
            grow: 1,


        },
        {
            name: "Civil ID",
            id: "customerObj.civilID",
            selector: (row) => row.customerObj?.civilID ?? "",
            sortable: true,
            grow: 1,

        },


        {
            name: "Date",
            id: "created",
            selector: (row) => {
                let created = row.created;

                // console.log(created);
                // console.log("Created: ", row.created);
                if (!(created instanceof Timestamp)) {
                    created = new Timestamp(created as any, 0);
                    row.created = created;
                }
                return `${created.toDate().toLocaleString("en-GB", { hour12: true }) ?? row.created}`
            },
            sortable: true,

        },
        {
            name: "Tags",
            // wrap: true,
            // width: "150px",
            cell: (row: Service) => {
                if (!row.tags) return;

                // return <TagBadge tag={row.tags["serviceStatus"]} />
                return <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                    <TagBadge m={3} tag={row.tags["serviceStatus"]} key="serviceStatus" />
                    {row.tags["kycStatus"] && <TagBadge m={3} tag={row.tags["kycStatus"]} key="kycStatus" />}
                </div>

            }
        },

        {
            name: "",
            selector: (row: any) => <>

                {<ActionsComponent serviceObj={row} />}
            </> as any,

            grow: 0,

        }


    ];



    return <DataTable
        columns={columns}
        selectableRows
        onSelectedRowsChange={handleChange}
        sortServer
        onSort={(column, sortDirection) => {
            if (column.id) {
                let sortByValue = `${indexName}/sort/${column.id}`
                if (sortDirection === "asc") {
                    sortByValue += `:asc`
                }
                else {
                    sortByValue += `:desc`
                }

                refine(sortByValue)
            }
        }}
        data={items.map((hitObj: any) => {

            return {
                ...hitObj,
                customerID: hitObj?.customerObj?.civilID,
            }
        })}
    />
}

interface ServicesInstantSearchProps {
    indexName: string,
    setSelectedRows?: any
    renderTags?: boolean
    ActionsComponent?: any
    navigateDetails?: any
    selectable?: boolean
    selectedRows?: any[]
    topElements?: JSX.Element
    additionalChildren?: JSX.Element
}
const ServicesInstantSearch = ({ indexName, selectable, setSelectedRows, selectedRows = [], navigateDetails, renderTags, ActionsComponent, topElements, additionalChildren }: ServicesInstantSearchProps) => {

    const [serviceStatusFilter, setServiceStatusFilter] = useState<string | null>("")
    const userData = useContext(MyUserDataContext);
    let customClaims = userData.firebaseTokenResult?.claims.customClaims as any;

    const handleRowsToShow = () => {

        const serviceStatuses: string[] = []

        if (customClaims["cr"] === true) {
            serviceStatuses.push(...[ServiceStatusCode.authorized_signatory_approval, ServiceStatusCode.waiting_for_kyc, ServiceStatusCode.waiting_for_execution])
        }

        if (serviceStatuses.length) {
            setServiceStatusFilter(`tags.serviceStatus.code:[${serviceStatuses.join()}]`)
        }
        else {
            setServiceStatusFilter(null)
        }
    }

    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
            apiKey: userData.customSearchKey!, // Be sure to use a Search API Key
            nodes: [
                {
                    host: process.env.REACT_APP_TYPESENSE_SEARCH_HOST!,
                    port: 443,
                    protocol: 'https'
                },
            ],
        },
        additionalSearchParameters: {

            query_by: "customerObj"
        }

    })
    const typesenseSearchClient = typesenseInstantsearchAdapter.searchClient;

    if (!indexName) {
        indexName = process.env.REACT_APP_TYPESENSE_SERVICES_COLLECTION!;
    }

    //save to local session
    const [initialUIStateForIndex, setInitialUIStateForIndex] = useSessionStorage<InitialState>({
        key: 'initialUIStateFor' + indexName,
        getInitialValueInEffect: false,
    });


    const onStateChange: InstantSearchProps['onStateChange'] = ({
        uiState,
        setUiState,
    }) => {
        // Custom logic
        // setUiState(uiState);
        // console.log("setting ui state", uiState);
        setInitialUIStateForIndex(uiState[indexName]);
    };

    // console.log("session for ", indexName, initialUIStateForIndex);

    // if (!initialUIStateForIndex)  {
    //     return <div></div>;
    // }
    return (
        <InstantSearch

            searchClient={typesenseSearchClient}
            indexName={indexName}
            initialUiState={{
                [indexName]: initialUIStateForIndex
            }}
            onStateChange={onStateChange}


        >




            <Configure

                filters={serviceStatusFilter ? `${serviceStatusFilter}` : ""}

            />

            <Group justify="space-between" >

                {topElements}


                <SearchBox
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",

                        width: "80%",
                    }}
                    autoFocus placeholder='Search...'
                />

                <Checkbox
                    label="Show all services"
                    defaultChecked={true}
                    onChange={(event) => {
                        if (!event.currentTarget.checked) {
                            handleRowsToShow();
                        }
                        else {
                            setServiceStatusFilter(null);
                        }
                    }

                    }
                />




                {additionalChildren}

            </Group>

            <TableDisplay
                selectedRows={selectedRows}
                setSelectedRows={selectable ? setSelectedRows : undefined}
                navigateDetails={navigateDetails}
                ActionsComponent={ActionsComponent}
                renderTags={renderTags}
                indexName={indexName}
            />

            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",

                    width: "100%",
                }}
            >

                <HitsPerPage
                    // style={{
                    //     display: "flex",
                    //     justifyContent: "flex-end",
                    //     background: "red",
                    //     width: "20%",
                    // }}
                    items={[
                        { label: '250', value: 250, },
                        { label: '100', value: 100 },
                        { label: '50', value: 50, default: true },
                    ]}
                />
            </div>
            <Space h="xl" />
            <Pagination
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",


                }}
            />

        </InstantSearch >



    )
}

export default ServicesInstantSearch