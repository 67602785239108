import { Combobox, Text, TextInput, TextInputProps, useCombobox } from "@mantine/core";

import { Key, Ref, forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from "react"

import {
    InstantSearch, useHits, useInstantSearch, useSearchBox
} from "react-instantsearch";
import TypesenseInstantsearchAdapter from "typesense-instantsearch-adapter";
import { MyUserDataContext } from "../contexts";
import { CustomAutocomplete, Customer } from "../types";
import { createRenderingInfoPrinter } from "../utils";


const debug = createRenderingInfoPrinter("SearchBoxComponent");


interface CustomerSelectComponentProps {
    setCustomerData?: (item: (Customer & CustomAutocomplete)[]) => void;
    defaultValue?: string
    textProps?: TextInputProps,
    submitType?: "object" | "string"
    handleSubmit?: (item: (Customer & CustomAutocomplete) | string) => void,
    customerData?: (Customer & CustomAutocomplete)[]

}
export interface AutocompleteComponentHandle {
    submitItem: (item: any) => void;
}


const SearchBoxComponent =
    forwardRef<AutocompleteComponentHandle, CustomerSelectComponentProps>(
        ({ handleSubmit, customerData, setCustomerData, textProps, defaultValue, submitType, ...props }: any, ref: Ref<AutocompleteComponentHandle>) => {

            debug();
            const combobox = useCombobox({});

            const memoizedSearch = useCallback((query: any, search: any) => {
                // console.log("Searching!");
                search(query);

            }, []);

            const { refine } = useSearchBox({
                queryHook: memoizedSearch,
            });

            const [inputValue, setInputValue] = useState<string>(defaultValue || "");

            //I hate this, but useInstantSearch and useHits apparently change when the component is reloaded, thus triggering setCustomers in a loop.
            const [searchChanged, setSearchChanged] = useState<boolean>(false);
            const { status, } = useInstantSearch()
            const { items } = useHits();

            useEffect(() => {
                setSearchChanged(true);
                refine(inputValue);
                // eslint-disable-next-line 
            }, [inputValue])

            useEffect(() => {
                console.log("Status is", status, "items is", items.length);
                if (status === "idle" && searchChanged) {
                    if (setCustomerData) {
                        setCustomerData(items)
                    }
                    setSearchChanged(false);
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [status, searchChanged]);

            useEffect(() => {
                if (customerData?.length === 1) {
                    combobox.selectFirstOption();
                    combobox.clickSelectedOption();


                }
                else {
                    // more than one 
                    // do stuff
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [customerData])

            // const inputRef = useRef<HTMLInputElement>(null);




            useImperativeHandle(ref, () => ({

                submitItem(item: string) {

                    console.log("Submitting!", item);

                    setInputValue(item)
                    // refine(item);
                    if (handleSubmit) {


                        if ((submitType) === "object") {
                            const foundCustomer = items.find((hitObj) => hitObj.id === item)

                            handleSubmit(foundCustomer)
                            console.log("handleSubmit  !", foundCustomer);

                        }
                        else {
                            console.log("handleSubmit String !", item);

                            handleSubmit(item)
                        }
                    }

                }
            }));

            return <Combobox
                store={combobox}
                onOptionSubmit={(val) => {
                    setInputValue(val)
                    console.log("handleSubmit Combobox !", val);

                    combobox.closeDropdown();
                    if (handleSubmit) {


                        if ((submitType) === "object") {
                            const foundCustomer = items.find((hitObj) => hitObj.id === val)

                            handleSubmit(foundCustomer)
                        }
                        else {

                            handleSubmit(val)
                        }
                    }

                }}
            >
                <Combobox.Target>
                    <TextInput
                        required
                        label=" Civil ID or Commercial Registry"
                        {...textProps}

                        // ref={inputRef}
                        value={inputValue}
                        rightSection={<Combobox.Chevron />}

                        onChange={(event) => {

                            setInputValue(event.currentTarget.value)

                        }}
                        onKeyDown={(e) => {
                            // console.log("ON KEY DOWN!!!", e.key);
                            //was it a backspace?
                            if (e.key === "Backspace") {
                                console.log("backspace");
                                setInputValue("")
                                handleSubmit()
                            }
                        }}
                        onClick={() => combobox.openDropdown()}
                        onFocus={() => combobox.openDropdown()}
                        onBlur={() => combobox.closeDropdown()}

                    />
                </Combobox.Target>

                <Combobox.Dropdown>

                    <Combobox.Options
                        mah={200} style={{ overflowY: 'auto' }}
                    >{
                            items.length > 0 ? items.map((item: any, i: Key) => {


                                const displayedLabel = `${(item?.civilID || item?.commercial_register || item?.id)} - ${item.name_ar}  - ${item.name_en}`

                                // console.log("ITEM HGIHLIGHT ======>>", item._highlightResult);

                                const displayedHighlightedLabel = `${(item?._highlightResult?.civilID || item?._highlightResult?.commercial_register || item?._highlightResult?.id)?.value} - ${item._highlightResult?.name_ar?.value}  - ${item._highlightResult?.name_en?.value}`

                                // const regex = new RegExp(`(${inputValue})`, 'gi'); // Case insensitive match
                                // const parts: string[] = displayedLabel?.split(regex) || [];

                                return <Combobox.Option value={item.id} key={i}>
                                    {/* {parts.map((part, index) =>
                                        part.toLowerCase() === inputValue.toLowerCase() ? (
                                            <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> // highlight style
                                        ) : (
                                            part
                                        )
                                    )} */}
                                    {item._highlightResult && displayedHighlightedLabel ? (
                                        <span dangerouslySetInnerHTML={{ __html: displayedHighlightedLabel }} />
                                    ) : (
                                        <Text>{displayedLabel}</Text>
                                    )}
                                    {/* <Text>{displayedLabel}</Text> */}
                                </Combobox.Option>
                            }
                            ) : <Combobox.Empty>Nothing found</Combobox.Empty>
                        }</Combobox.Options>
                </Combobox.Dropdown>
            </Combobox>
        });

export const CustomerSelectComponent = forwardRef<AutocompleteComponentHandle, CustomerSelectComponentProps>(
    ({ textProps, defaultValue, handleSubmit, customerData, setCustomerData, submitType = "string", ...props }: CustomerSelectComponentProps, ref: Ref<AutocompleteComponentHandle>) => {

        const userData = useContext(MyUserDataContext);

        const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
            server: {
                apiKey: userData.customerCollectionSearchKey!, // Be sure to use a Search API Key
                nodes: [
                    {
                        host: process.env.REACT_APP_TYPESENSE_SEARCH_HOST!,
                        port: 443,
                        protocol: 'https'
                    },
                ],
            },
            additionalSearchParameters: {
                query_by: "name_en,name_ar,mobile,crmID,civilID,commercial_register"
            }

        })
        const typesenseSearchClient = typesenseInstantsearchAdapter.searchClient;




        return (
            <InstantSearch indexName={process.env.REACT_APP_TYPESENSE_CUSTOMERS_COLLECTION as string} searchClient={typesenseSearchClient}>
                <SearchBoxComponent submitType={submitType} ref={ref} textProps={textProps} defaultValue={defaultValue} handleSubmit={handleSubmit} customerData={customerData} setCustomerData={setCustomerData} />

                {/* <Stats /> */}
            </InstantSearch>
        )
    });

