import { Button, Grid, Loader, Select, Text, Title } from '@mantine/core'
import { useForm } from '@mantine/form';
import { useContext, useEffect, useState } from 'react';
import { BaseResult, TwilioContentType, } from '../../types';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from '../../helpers/firebaseContext';
import { RmsSegmentSelectComponent } from '../../components/rms_segment_select_component';
import { refreshWhatsappTemplates } from '../../components/send_sms_modal';
import { AppConfigContext } from '../../contexts';

const SendRmsWhatsappMessageForm = ({ handleSubmit }: {
    handleSubmit: () => void
}) => {


    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
    const [loadingWhatsappTemplates, setLoadingWhatsappTemplates] = useState<boolean>(false);

    const [errMsg, setErrMsg] = useState<string>();
    const functions = useFunctions();
    const context = useContext(AppConfigContext);

    const { apiUrl } = context;

    const [whatsappTemplates, setWhatsappTemplates] = useState<TwilioContentType[]>([]);


    useEffect(() => {
        refreshWhatsappTemplates(true, form, apiUrl!, setWhatsappTemplates, false, setLoadingWhatsappTemplates,
            // (chosenFund && config?.factsheetTemplates) ? config.factsheetTemplates[chosenFund?.type].whatsappFactsheetTemplate : undefined
        )



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const form = useForm<
        {
            queryString: string,
            collectionName: "rms",
            whatsappTemplateID?: string,
            segmentsRecipients?: string[]
        }
    >({
        initialValues: {
            queryString: "",
            collectionName: "rms",


        },

    });







    return (
        <Grid justify='center' w="100%" h="100%">
            <Grid.Col span={{ xs: 12 }}>


            </Grid.Col>
            {/* <LoadingOverlay visible={visible} loaderProps={{ children: 'Loading...' }} /> */}

            <Grid.Col span={{ lg: 8 }}>

                <form
                    onSubmit={form.onSubmit(async (values) => {
                        setErrMsg(undefined)
                        setLoadingSubmit(true)
                        try {


                            {

                                const sendGenericWhatsappMessage = httpsCallable<{
                                    queryString: string,
                                    collectionName: "rms",
                                    whatsappTemplateID?: string
                                }, BaseResult>(functions, "sendGenericWhatsappMessage");
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                const response = await sendGenericWhatsappMessage(
                                    {
                                        ...values,
                                        queryString: `segment:[${values?.segmentsRecipients?.join(",")}]`

                                    })
                            }
                            setLoadingSubmit(false);
                            handleSubmit();
                            return;



                        }
                        catch (e: any) {
                            if (e?.message) {
                                setErrMsg(e?.message)
                            }
                            else {
                                setErrMsg("an unexpcted error has occurred ")
                            }
                            setLoadingSubmit(false);

                        }

                    })}
                >
                    <Grid>

                        <Grid.Col span={{ xs: 12 }} >
                            <RmsSegmentSelectComponent

                                textProps={{
                                    label: "Segments",
                                    required: false
                                }}
                                // fundsList={funds}
                                segmentsList={form.values.segmentsRecipients || []}

                                onChange={(value) => {
                                    form.setFieldValue("segmentsRecipients", value)
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={{ xs: 12 }} >
                            {loadingWhatsappTemplates ?
                                <Loader size={30} />
                                : <>
                                    <Select
                                        label="Select whatsapp  template"
                                        // value={selectedWhatsappTemplate?.value}
                                        value={form.values.whatsappTemplateID}

                                        onChange={(value, option: any) => {
                                            // setSelectedWhatsappTemplate(option)
                                            if (value)
                                                form.setFieldValue("whatsappTemplateID", value)
                                        }}
                                        data={whatsappTemplates?.map((template: TwilioContentType) => {
                                            return {
                                                ...template,
                                                value: template.sid,
                                                label: template?.friendly_name
                                            }
                                        })}
                                    />

                                </>
                            }
                        </Grid.Col>
                        <Grid.Col span={{ xs: 12 }} >
                            <Text c={"red"}>
                                {errMsg}
                            </Text>
                        </Grid.Col>

                        <Grid.Col span={{ xs: 3 }} >
                            <Button
                                loading={loadingSubmit}
                                color='green'
                                type='submit'
                                fullWidth

                            >
                                {"Send"}
                            </Button>
                        </Grid.Col>
                    </Grid>
                </form>
            </Grid.Col>

        </Grid >
    )
}

export default SendRmsWhatsappMessageForm